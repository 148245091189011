import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { DialsCategories } from './dialsCategories.store'
import { DialsPrograms } from './dialsPrograms.store'
import { DialsProgramVersions } from './dialsProgramVersions.store'
import { DialsLicenseTypes } from './dialsLicenseTypes.store'
import { DialsServices } from './dialsServices.store'
import { DialsModules } from './dialsModules.store'
import { DialsPriceListsModules } from './dialsPriceListsModules.store'
import { DialsPriceListsServices } from './dialsPriceListsServices.store'

import { DialsSupportTypes } from './dialsSupportTypes.store'
import { DialsSupportThemes } from './dialsSupportThemes.store'

import { DialsMeetingsCategories } from './dialsMeetingsCategories.store'
import { DialsUserGroups } from './dialsUserGroups.store'

import { DialsFreeText } from './dialsFreeText.store'

import { DialsOperationsPreferences } from './dialsOperationsPreferences.store'

import { DialsDeliveryCzechPostServices } from './dialsDeliveryCzechPostServices.store'
import { DialsDeliveryCzechPostServicesAll } from './dialsDeliveryCzechPostServicesAll.store'
import { DialsDeliveryCzechPostProducts } from './dialsDeliveryCzechPostProducts.store'
import { DialsDeliveryCzechPostProductsAll } from './dialsDeliveryCzechPostProductsAll.store'
import { DialsDeliveryCzechPostTemplates } from './dialsDeliveryCzechPostTemplates.store'

import { DialsSuppliers } from './dialsSuppliers.store'

import { Helpers } from './helpers.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootDialsStore = types
  .model({
    dialsCategories: types.array(DialsCategories),
    dialsPrograms: types.array(DialsPrograms),
    dialsProgramVersions: types.array(DialsProgramVersions),
    dialsLicenseTypes: types.array(DialsLicenseTypes),
    dialsServices: types.array(DialsServices),
    dialsModules: types.array(DialsModules),
    dialsPriceListsModules: types.array(DialsPriceListsModules),
    dialsPriceListsServices: types.array(DialsPriceListsServices),
    dialsSupportTypes: types.array(DialsSupportTypes),
    dialsSupportThemes: types.array(DialsSupportThemes),
    dialsFreeText: types.array(DialsFreeText),
    dialsOperationsPreferences: types.array(DialsOperationsPreferences),

    dialsDeliveryCzechPostServices: types.array(DialsDeliveryCzechPostServices),
    dialsDeliveryCzechPostServicesAll: types.array(DialsDeliveryCzechPostServicesAll),
    dialsDeliveryCzechPostProducts: types.array(DialsDeliveryCzechPostProducts),
    dialsDeliveryCzechPostProductsAll: types.array(DialsDeliveryCzechPostProductsAll),
    dialsDeliveryCzechPostTemplates: types.array(DialsDeliveryCzechPostTemplates),
    dialsDeliveryCzechPostTemplatesAll: types.array(DialsDeliveryCzechPostTemplates),

    dialsMeetingsCategories: types.array(DialsMeetingsCategories),
    dialsMeetingsCategoriesAll: types.array(DialsMeetingsCategories),

    dialsUserGroups: types.array(DialsUserGroups),
    dialsUserGroupsAll: types.array(DialsUserGroups),

    dialsSuppliers: types.array(DialsSuppliers),

    helpers: types.array(Helpers),

    dialsRoles: types.optional(types.array(types.string), []),
    
  })
  .views(self => ({
    getDialsDeliveryCzechPostServiceById(id: string) {
      return self.dialsDeliveryCzechPostServices.find(item => item.id === id)
    },
    getDialsDeliveryCzechPostProductById(id: string) {
      return self.dialsDeliveryCzechPostProducts.find(item => item.id === id)
    },
    getDialsDeliveryCzechPostTemplateById(id: string) {
      return self.dialsDeliveryCzechPostTemplates.find(item => item.id === id)
    },
    getDialsDeliveryCzechPostTemplateAllById(id: string) {
      return self.dialsDeliveryCzechPostTemplatesAll.find(item => item.id === id)
    },
    getDialsMeetingsCategoryById(id: string) {
      return self.dialsMeetingsCategories.find(item => item.id === id)
    },
    getDialsMeetingsCategoryAllById(id: string) {
      return self.dialsMeetingsCategoriesAll.find(item => item.id === id)
    },
    getDialsUserGroupById(id: string) {
      return self.dialsUserGroups.find(item => item.id === id)
    },
    getDialsUserGroupAllById(id: string) {
      return self.dialsUserGroupsAll.find(item => item.id === id)
    },
    getDialsPriceListsModulesToDate(toDate: string, programId: string) {
      let nearbyDate:string = self.dialsPriceListsModules.sort((a, b) => new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()).find(item => new Date(item.validFrom).getTime() <= new Date(toDate).getTime() && item.module.programId === programId)?.validFrom ?? ''
      return self.dialsPriceListsModules.filter(item => item.validFrom === nearbyDate && item.module.programId === programId)      
    },
    getDialsPriceListsServicesToDate(toDate: string) {
      let nearbyDate:string = self.dialsPriceListsServices.sort((a, b) => new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()).find(item => new Date(item.validFrom).getTime() <= new Date(toDate).getTime() )?.validFrom ?? ''
      return self.dialsPriceListsServices.filter(item => item.validFrom === nearbyDate)      
    },
    getDialsSupplierById(id: string) {
      return self.dialsSuppliers.find(item => item.id === id)
    },
  }))
  .actions(self => ({
    fetchDialsSuppliers: flow(function* fetchProjects() {
      try {

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_SUPPLIERS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

            localStorage.setItem('lastDialsSuppliersTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsSuppliersTotalCount') === '0') {
          self.dialsSuppliers.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsSuppliers,cl) 
        } 
        
    } catch (error) {
      console.error('Nepodařilo se načíst seznam dodavatelů. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchDialsRoles: flow(function* fetchProjects() {
      try {

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ACCOUNT')+'roles',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

            localStorage.setItem('lastDialsRolesTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsRolesTotalCount') === '0') {
          self.dialsRoles.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsRoles,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam rolí. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsUserGroups: flow(function* fetchProjects(pageNumber:number) {
      try {

        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('dialsUserGroupsSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsUserGroupsOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsUserGroupsOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_USER_GROUPS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDialsUserGroupsTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsUserGroupsTotalCount') === '0') {
          self.dialsUserGroups.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsUserGroups,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst skupiny uživatelů. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsUserGroupsAll: flow(function* fetchProjects() {
      try {
        //self.dialsMeetingsCategoriesProducts.clear()

        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('dialsMeetingsCategoriesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsUserGroupsOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsUserGroupsOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_USER_GROUPS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDialsUserGroupsTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsUserGroupsTotalCount') === '0') {
          self.dialsUserGroupsAll.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsUserGroupsAll,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst skupiny uživatelů. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsUserGroupsAllUsers: flow(function* fetchProjects() {
      try {
       yield sleep(1)

       
      
       const accessToken = yield getToken()

       

        self.dialsUserGroupsAll.map(uga => {
          let userIds:any = []
          axios.get(getUrl('URL_ACCOUNT')+'user-group/'+uga.id+'/users',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              
            }  }).then(response => {
              console.log(response.data.items)
               
              response.data.items.map((item: {id:string}) => {
                userIds.push(item.id)
                return null
              })  
              //uga.setUsers(response.data.items)
              console.log(userIds)
              uga.setUsers(userIds)

          }).catch(error => {
            errorEvaluation(error)
          });
          
          return null
        })


          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchDialsMeetingsCategories: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsMeetingsCategoriesProducts.clear()

        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('dialsMeetingsCategoriesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_MEETINGS_CATEGORIES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDialsMeetingsCategoriesTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsMeetingsCategoriesTotalCount') === '0') {
          self.dialsMeetingsCategories.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsMeetingsCategories,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst kategorie porad. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),



    fetchDialsMeetingsCategoriesAll: flow(function* fetchProjects() {
      try {
        //self.dialsMeetingsCategoriesProducts.clear()

        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('dialsMeetingsCategoriesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsMeetingsCategoriesOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_MEETINGS_CATEGORIES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDialsMeetingsCategoriesTotalCount',response.data.totalCount)

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsMeetingsCategoriesTotalCount') === '0') {
          self.dialsMeetingsCategoriesAll.clear()
        }
        else {
          //self.dialsMeetingsCategories.clear()
          applySnapshot(self.dialsMeetingsCategoriesAll,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst kategorie porad. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchDialsDeliveryCzechPostTemplates: flow(function* fetchProjects(pageNumber:number) {
      try {
        self.dialsDeliveryCzechPostTemplates.clear()
        
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('dialsDeliveryCzechPostTemplatesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            localStorage.setItem('lastDialsDeliveryCzechPostTemplatesTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsDeliveryCzechPostTemplatesTotalCount') === '0') {
          self.dialsDeliveryCzechPostTemplates.clear()
        }
        else {
          self.dialsDeliveryCzechPostTemplates.clear()
          applySnapshot(self.dialsDeliveryCzechPostTemplates,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby eské pošty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsDeliveryCzechPostTemplatesAll: flow(function* fetchProjects() {
      try {
        self.dialsDeliveryCzechPostTemplatesAll.clear()
        
        
        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('dialsDeliveryCzechPostTemplatesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('dialsDeliveryCzechPostTemplatesOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = 1
        const pagesize = 99999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            //localStorage.setItem('lastDialsDeliveryCzechPostTemplatesTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.dialsDeliveryCzechPostTemplatesAll.clear()
          applySnapshot(self.dialsDeliveryCzechPostTemplatesAll,cl) 
        
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst šablony české pošty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchDialsDeliveryCzechPostProducts: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('dialsDeliveryCzechPostProductsSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'Prefix') order = 'Prefix~asc'
        if (localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'PrefixDesc') order = 'Prefix~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            localStorage.setItem('lastDialsDeliveryCzechPostProductsTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsDeliveryCzechPostProductsTotalCount') === '0') {
          self.dialsDeliveryCzechPostProducts.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsDeliveryCzechPostProducts,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby eské pošty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsDeliveryCzechPostProductsAll: flow(function* fetchProjects() {
      try {
        self.dialsDeliveryCzechPostProductsAll.clear()
        
        
        let filter:string = ''
        let order:string = ''

        if (localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'Prefix') order = 'Prefix~asc'
        if (localStorage.getItem('dialsDeliveryCzechPostProductsOrderBy') === 'PrefixDesc') order = 'Prefix~desc'

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.dialsDeliveryCzechPostProductsAll.clear()
          applySnapshot(self.dialsDeliveryCzechPostProductsAll,cl) 

        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby eské pošty. Může dojít k odhlášení!', error)

    } 
    }),

    fetchDialsDeliveryCzechPostServices: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostServices.clear()
        
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('dialsDeliveryCzechPostServicesSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('dialsDeliveryCzechPostServicesOrderBy') === 'Code') order = 'Code~asc'
        if (localStorage.getItem('dialsDeliveryCzechPostServicesOrderBy') === 'CodeDesc') order = 'Code~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_SERVICES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            localStorage.setItem('lastDialsDeliveryCzechPostServicesTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsDeliveryCzechPostServicesTotalCount') === '0') {
          self.dialsDeliveryCzechPostServices.clear()
        }
        else {
          //self.dialsDeliveryCzechPostServices.clear()
          applySnapshot(self.dialsDeliveryCzechPostServices,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby eské pošty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDialsDeliveryCzechPostServicesAll: flow(function* fetchProjects() {
      try {
        self.dialsDeliveryCzechPostServicesAll.clear()
        
        
        let filter:string = ''
        let order:string = ''

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_DELIVERY_CZECH_POST_SERVICES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.dialsDeliveryCzechPostServicesAll.clear()
          applySnapshot(self.dialsDeliveryCzechPostServicesAll,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby eské pošty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchDialsCategories: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_CATEGORIES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsCategories,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst kategorie!', error)
    } 
    }),

    deleteDialsCategories: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsCategories.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsCategories', error)
      }
    }),    

    fetchDialsPrograms: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_PROGRAMS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPrograms,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst programy!', error)
    } 
    }),

    deleteDialsPrograms: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPrograms.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),

    fetchDialsProgramVersions: flow(function* fetchProjects(programId: string) {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

       const url = getUrl('URL_DIALS_PROGRAM_VERSIONS').concat("?filter=programId~eq~", programId)
        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsProgramVersions,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst verze programu!', error)
    } 
    }),

    fetchDialsProgramVersionsAll: flow(function* fetchProjects() {
      try {
        const accessToken = yield getToken()

        // Je podpora filtrovani a takze v zakladu jsou vraceny vsechny verze programu. Takze staci jen 
        // jedno volani bez parametru a nebo filter=programId~eq~duelId~and~programId~eq~stereoId
        const programVersions = yield axios.get(getUrl('URL_DIALS_PROGRAM_VERSIONS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        applySnapshot(self.dialsProgramVersions, programVersions) 
    } catch (error) {
      console.error('Nepodařilo se načíst verze programu!', error)
    } 
    }),

    deleteDialsProgramVersions: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsProgramVersions.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsProgramVersions', error)
      }
    }), 

    fetchDialsLicenseTypes: flow(function* fetchProjects(programId: string) {
      try {

      //  yield sleep(300)
      
        const accessToken = yield getToken()

        let url = getUrl('URL_DIALS_LICENSE_TYPES') + "?filter=programId~eq~" + programId
        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsLicenseTypes,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst typy licencí!', error)
    } 
    }),

    fetchDialsLicenseTypesAll: flow(function* fetchProjects() {
      try {
        const accessToken = yield getToken()

        self.dialsPrograms.map(program => ({
        }))

        const cl = yield axios.get(getUrl('URL_DIALS_LICENSE_TYPES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
            return response.data.items
          }).catch(error => {
            errorEvaluation(error)
          });

        applySnapshot(self.dialsLicenseTypes,cl) 
    } catch (error) {
      console.error('Nepodařilo se načíst typy licencí!', error)
    } 
    }),

    deleteDialsLicenseTypes: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsLicenseTypes.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsLicenseTypes', error)
      }
    }),    

    
    fetchDialsServices: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_SERVICES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsServices,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby!', error)
    } 
    }),

    deleteDialsServices: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsServices.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsServices', error)
      }
    }),

    fetchDialsModules: flow(function* fetchProjects(programId: string) {
      try {

      //  yield sleep(300)
        const accessToken = yield getToken()

        const url = getUrl('URL_DIALS_MODULES') + "?filter=programId~eq~" + programId
        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst moduly!', error)
    } 
    }),

    fetchDialsModulesAll: flow(function* fetchProjects() {
      try {

        // yield sleep(300)
      
        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_MODULES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        //console.log(cl)
        applySnapshot(self.dialsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst moduly!', error)
    } 
    }),

    deleteDialsModules: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsModules.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsModules', error)
      }
    }),

    fetchDialsPriceListsModules: flow(function* fetchProjects(programId: string) {
      try {

      //  yield sleep(300)
    
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_PRICE_LISTS_MODULES')+programId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPriceListsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    fetchDialsPriceListsModulesAll: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

       let url:string = getUrl('URL_DIALS_PRICE_LISTS_MODULES')

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) url = getUrl('URL_DIALS_PRICE_LISTS_MODULES_ALL')+'?programId='

        const cl1 = yield axios.get(url+self.dialsPrograms[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl2 = yield axios.get(url+self.dialsPrograms[1].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        let cl
        if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) {
          cl = cl1
        }
        else {
          cl = cl1.concat(cl2)
        }
        //const cl = cl1

        //console.log(cl)
        applySnapshot(self.dialsPriceListsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    deleteDialsPriceListsModules: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPriceListsModules.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),

    fetchDialsPriceListsServices: flow(function* fetchProjects() {
      try {

      //  yield sleep(300)
      
       const accessToken = yield getToken()

       let url:string = getUrl('URL_DIALS_PRICE_LISTS_SERVICES')

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) url = getUrl('URL_DIALS_PRICE_LISTS_SERVICES_ALL')


        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPriceListsServices,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    deleteDialsPriceListsServices: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPriceListsServices.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),


    fetchDialsSupportTypes: flow(function* fetchProjects() {
      try {

       //yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_SUPPORT_TYPES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {filter: ''}
        }).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsSupportTypes,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby!', error)
    } 
    }),

    deleteDialsSupportTypes: flow(function* fetchProjects() {
      try {
        yield sleep(1)
        self.dialsSupportTypes.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsSupportTypes', error)
      }
    }),

    fetchDialsSupportThemes: flow(function* fetchProjects() {
      try {

       //yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_SUPPORT_THEMES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {filter: ''}
        }).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsSupportThemes,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst témata!', error)
    } 
    }),

    deleteDialsSupportThemes: flow(function* fetchProjects() {
      try {
        yield sleep(1)
        self.dialsSupportThemes.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsSupportThemes', error)
      }
    }),

    fetchDialsFreeText: flow(function* fetchProjects() {
      try {

       //yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_FREETEXTS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {filter: ''}
        }).then(response => {
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsFreeText,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst volné texty!', error)
    } 
    }),

    fetchDialsOperationsPreferences: flow(function* fetchProjects() {
      try {

       //yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_OPERATIONS_PREFERENCES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {filter: ''}
        }).then(response => {
          
          if (Object.keys(response.data.items.filter((it: { value: string|null; }) => it.value === null)).length > 0)  { localStorage.setItem('checkOperationsPreferences', '1') }
              
          else { localStorage.setItem('checkOperationsPreferences', '0') }

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsOperationsPreferences,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst volné texty!', error)
    } 
    }),    

    deleteDialsFreeText: flow(function* fetchProjects() {
      try {
        yield sleep(1)
        self.dialsFreeText.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsFreeText', error)
      }
    }),

    addHelper: flow(function* fetchProjects(id:string) {
      try {
        yield sleep(1)
        let a = self.helpers.find(h => h.id === id)
        self.helpers.remove(a!)
        
        self.helpers.push({id: id, status: true})
        //self.dialsSupportThemes.clear()
                
      } catch (error) {
        console.error('Failed to add Helper', error)
      }
    }),

    removeHelper: flow(function* fetchProjects(id:string) {
      try {
        yield sleep(1)
        let a = self.helpers.find(h => h.id === id)
        self.helpers.remove(a!)
        //self.dialsSupportThemes.clear()
                
      } catch (error) {
        console.error('Failed to add Helper', error)
      }
    }),

  }))

export type RootDialsStoreType = Instance<typeof RootDialsStore>
export type DialsCategoriesStoreType = Instance<typeof DialsCategories>
export type DialsProgramsStoreType = Instance<typeof DialsPrograms>
export type DialsProgramVersionsStoreType = Instance<typeof DialsProgramVersions>
export type DialsLicenseTypesStoreType = Instance<typeof DialsLicenseTypes>
export type DialsServicesStoreType = Instance<typeof DialsServices>
export type DialsModulesStoreType = Instance<typeof DialsModules>
export type DialsPriceListsModulesStoreType = Instance<typeof DialsPriceListsModules>
export type DialsSupportTypesStoreType = Instance<typeof DialsSupportTypes>
export type DialsSupportThemesStoreType = Instance<typeof DialsSupportThemes>