export const vatTypes = [
  {
    'name': 'Základní',
    'value': 'Z',
    'vatRate': '21',
    'isActive': true
  },
  {
    'name': 'Snížená',
    'value': 'S',
    'vatRate': '10',
    'isActive': true
  },
  {
    'name': 'Druh snížená',
    'value': 'D',
    'vatRate': '5',
    'isActive': false
  },
  {
    'name': 'Nulová',
    'value': 'N',
    'vatRate': '0',
    'isActive': true
  },
  {
    'name': 'Neurčeno',
    'value': 'E',
    'vatRate': '0',
    'isActive': true
  }

]