import React, { useState, useRef }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'
//eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Card, CardDeck, Table, Nav, Tabs, Tab, Form } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { useInvoicesStore, useStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'

//eslint-disable-next-line
import { confirm } from "../../../Utils/Confirmation";

import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
import NumberFormat from 'react-number-format'
import {getConstantTranslate} from '../../../helpers/globalFunctions'
//eslint-disable-next-line
import InvoicesPeriodicTemplatesAddEmail from './InvoicesPeriodicTemplates.EmailAdressAdd.part'
import InvoicesPeriodicTemplatesAddText from './InvoicesPeriodicTemplates.TextAdd.part'
import InvoicesPeriodicTemplatesAddItem from './InvoicesPeriodicTemplates.ItemAdd.part'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import axios from 'axios';

//import ScrollToTopBtn from "../../../sharedComponents/scrollToTop/ScrollToTopBtn"


interface InvoicesPeriodicTemplatesAddUpdateProps { 
  templateId?:string
  editing?:boolean,
  //supportId?:string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const InvoicesPeriodicTemplatesAddUpdate: React.FC<InvoicesPeriodicTemplatesAddUpdateProps & RouteComponentProps> = (props:any) => {
  
  const modalRef = React.useRef<HTMLDivElement>(null);

  const formikRef = useRef<FormikProps<any>>(null);
  
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('Billing')! === '1')) ? true : false
  //eslint-disable-next-line
  const rightsEdit:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  || (localStorage.getItem('Billing')! === '1')) ? true : false


  // Nastavení stavů pro zobrazení 
  //eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  //eslint-disable-next-line
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const [showE, setShowE] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const [activeNav, setActiveNav] = useState('items')

  const [showForm, setShowForm] = useState('')
  const [showInfo, setShowInfo] = useState(false)

  //eslint-disable-next-line
  const [opening, setOpening] = useState(false)

  const [editSubscriber, setEditSubscriber] = useState(false)

  //eslint-disable-next-line
  const [insertNewItem, setInsertNewItem] = useState(false)

  const [centered,setCentered] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootInvoicesStore = useInvoicesStore()


  // Nastavení editivaného kontaktu
  const item = props.editing === true ? rootInvoicesStore.invoicesPeriodicTemplates.find(i => i.id === props.templateId) : rootInvoicesStore.invoicesPeriodicTemplatesNewEdit.find(i => i.id === 'NEW')
  //const item = rootInvoicesStore.invoicesPeriodicTemplatesNewEdit.find(i => i.id === props.editing === true ? props.templateId : 'NEW')
  //const optionsSuppliers = SelectOptionsSuppliers


  const [customers, setCustomers] = useState(rootStore.customerListAll.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  
  const [customerAllNames, setCustomerAllNames] = useState([""])
  //eslint-disable-next-line
  const [customerNames, setCustomerNames] = useState(customerAllNames.filter(cn => cn !== ''))
  
  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)
  //eslint-disable-next-line
  const [selectedCustomer, setSelectedCustomer] = useState('')
  //eslint-disable-next-line
  const [selectedCustomerId, setSelectedCustomerId] = useState('')
  //eslint-disable-next-line
  const [loadingData, setLoadingData] = useState(false)
  //eslint-disable-next-line
  const [dataToEditLoaded, setDataToEditLoaded] = useState(false)

  //eslint-disable-next-line
  let currentUserArray = {id:''}
  //eslint-disable-next-line
  currentUserArray = JSON.parse(localStorage.getItem('user')!)

  // Seznam dodavatelů pro rozevírací seznam
  const suppliersSelect = rootDialsStore.dialsSuppliers
  .slice()
  //.filter(dst => dst.isActive === true)
  .sort(function(a:any, b:any) {
    var sort1A = a.name; 
    var sort1B = b.name; 

    if (sort1A! < sort1B!) {
      return -1;
    }
    if (sort1A! > sort1B!) {
      return 1;
    }
    return 0;
  })
  .map(item => (
    <option key={item.id} value={item.id}>{item.name}</option>
  ))  

  // Seznam typů dokladů
  const invoicesTypesSelect = rootInvoicesStore.dialsInvoicesTypes
  .slice()
  //.filter(dst => dst.isActive === true)
  .map((item,index) => (
    <option key={index} value={item}>{getConstantTranslate(item, 'value')}</option>
  ))  

  // Seznam způsobů zaokrouhlení DPH
  const invoicesVatRoundingTypesSelect = rootInvoicesStore.dialsInvoicesVatRoundingTypes
  .slice()
  //.filter(dst => dst.isActive === true)
  .map((item,index) => (
    <option key={index} value={item}>{getConstantTranslate(item, 'value')}</option>
  ))  
  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)

    
    if (sessionStorage.getItem('invoicesPeriodicTemplatesDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')      
       //rootInvoicesStore.fetchInvoicesPeriodicTemplatesList(Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage') !== 'null' ? sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')))
       //rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('invoicesPeriodicTemplatesDetailEdited', 'N')
       
    }
    sessionStorage.removeItem('autoCloseModal')

    setLoading(false)
    setShowDetail(false)
    setActiveNav('items')
    setShowForm('')
    setShowInfo(false)
    setOpening(false)
    setEditSubscriber(false)
    setInsertNewItem(false)
    setCentered(false)


  }

    // Seznam způsobů úhrady
    const paymentTermsSelect = rootInvoicesStore.dialsInvoicesPaymentTerms
    .slice()
    //.filter(dst => dst.isActive === true)
    .sort(function(a:any, b:any) {
      var sort1A = a.paymentTerm; 
      var sort1B = b.paymentTerm; 
  
      if (sort1A! < sort1B!) {
        return -1;
      }
      if (sort1A! > sort1B!) {
        return 1;
      }
      return 0;
    })
    .map((item,index) => (
      <option key={index} value={item.paymentTerm}>{getConstantTranslate(item.paymentTerm!, 'value')}</option>
    ))  

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    sessionStorage.setItem('invoicesPeriodicTemplatesDetailEdited', 'N')

    if (props.editing !== true) { 
      await rootInvoicesStore.addInvoicesPeriodicTemplatesNew() 
      setDataToEditLoaded(true)
    }
    else {
      await setShowDetail(true)
      if (item?.header.subscriber?.businessName === '') {
        await loadCustomerData(item?.customerId!,formikRef.current!.setFieldValue)
        formikRef.current!.setFieldValue('useDefaultSubscriber',true)
      }
      formikRef.current!.setFieldValue('customerId',item?.accountNumber)
      formikRef.current!.setFieldValue('customerIdToSend',item?.customerId)
      
      setDataToEditLoaded(true)
    }
    //await rootInvoicesStore.fetchInvoiceById(props.invoiceId)

    //await rootInvoicesStore.fetchInvoiceById(props.templateId)
      
  setShowDetail(true)

  //await rootStore.fetchSupportById(props.supportId)
  await setLoading(false)

}

// Funkce pro načtení dat o ZÁKAZNÍKOVI
const loadCustomerData = async (customerId:string, setFieldValue:any) => {
  await setLoadingData(true)
  
  // Kontrola na platnost přihlášení
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
    sessionStorage.setItem('automaticLogout', '1')
    props.history.push('/logout')
  }

  
  // Načtení základních informací o zákazníkovi a nastavení stavů pro indikaci
  await axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+customerId,{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {
      setFieldValue('street',response.data.billingAddress.street)
      setFieldValue('houseNumber',response.data.billingAddress.houseNumber)
      setFieldValue('postalCode',response.data.billingAddress.postalCode)
      setFieldValue('city',response.data.billingAddress.city)
      setFieldValue('identificationNumber',response.data.identificationNumber)

      setFieldValue('name',response.data.name)
      setFieldValue('businessName',response.data.businessName)
      setFieldValue('accountNumber',response.data.accountNumber)
      setFieldValue('companyRegistrationNumber',response.data.companyRegistrationNumber)
      setFieldValue('taxIdentificationNumber',response.data.taxIdentificationNumber)
      setFieldValue('title',response.data.title)
      setFieldValue('firstName',response.data.firstName)
      setFieldValue('surName',response.data.surName)
      setFieldValue('titleAfter',response.data.titleAfter)



  }).catch(error => {
    
  });



  setLoadingData(false)

}

// Deklarace a nastavení stavů pro seznamy ala google
const [state, setState] = useState({
  activeItem: 0,
  filteredItems: [],
  displayItems: false,
  inputValue: ''
})

// Filtrování zákazníků v seznamu
const filterCustomers = (value:string) => {
  //setCustomers(rootStore.customerListAll)
  //const newList = rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))
  //setCustomers(newList)
  if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

    (async () => {
      setCustomerDeepLoading(true)
      await rootStore.fetchCustomerListAllNoCache(value,'',1)
      //setCustomers(rootStore.getCustomerListAllByFilter(value))
      console.log(localStorage.getItem('customerQuickSearchValue'))
      setCustomers(rootStore.getCustomerListAllByFilter(localStorage.getItem('customerQuickSearchValue')!))
      setCustomerDeepLoading(false)
    })();
  }
  else {
    setCustomers(rootStore.getCustomerListAllByFilter(value))
  }
  //setCustomers(rootStore.getCustomerListAllByFilter(value))  
}



const setNewCustomer = (customerId:string,accountNumber:string,businessName:string, setFieldValue:any) => {
 
  setCustomerAllNames([""])
  setCustomerNames([""])

  setSelectedCustomer(businessName);
  setSelectedCustomerId(customerId);

  setTimeout(async () => {
    await loadCustomerData(customerId,setFieldValue) // Načtení dat

    

  },10)
}


const scrollToBeVisible = function (ele:any, container:any) {
  
  if (ele !== null) {
    const eleTop = ele.offsetTop;
    const eleBottom = eleTop + ele.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    if (eleTop < containerTop) {
        // Scroll to the top of container
        container.scrollTop -= containerTop - eleTop;
    } else if (eleBottom > containerBottom) {
        // Scroll to the bottom of container
        container.scrollTop += eleBottom - containerBottom;
    }
  }
};

// Odchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;
  
  //if (props.editing !== true) setThemes(['']) // Pokud se nejedná o editaci - vymazat naklikaná témata

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        var form = e.currentTarget.form;
        form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
     if (customers[activeItem] !== undefined) {
        setFieldValue('customerId',customers[activeItem].accountNumber)
        setFieldValue('customerIdToSend',customers[activeItem].id)
        setFieldValue('customerBusinessName',customers[activeItem].businessName)
        setNewCustomer(customers[activeItem].id,customers[activeItem].accountNumber!,customers[activeItem].businessName, setFieldValue)
      }
      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('customerId','')
      setFieldValue('customerIdToSend',customers[activeItem].id)
      setFieldValue('customerBusinessName','')
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }


};

  // Dotaz před editací odběratele
  const handleOnClickEditSubscriber = () => {
    (async () => {
      let textEdit = <>Opravdu chcete upravit adresu odběratele? Pokud adresu upravíte, vždy bude <strong>použita výhradně takto upravená adresa</strong>. Pokud dojde ke změně informací u zákazníka, šablona periodické fakturace nebude takovéto změny respektovat!</>
      
      if (await confirm(textEdit,'Ano, pokračovat', 'Ne', {title: 'Úprava adresy odběratele', style: {backgroundColor: 'rgba(200, 58, 58, 0.4)'}})) {
        setEditSubscriber(true)
      } else {
      
      }  
    })()
  }


  // Funkce po odeslání formuláře
  const handleUpdate = (values:any, activate:boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      let dataToSend:any
      let urlForEditing:string = ''

      if (props.editing === true) {
        dataToSend = {
          id: props.templateId,
          customerId: values.customerIdToSend,
          supplierId: values.supplierId,
          name: values.templateName,
          emailAddressesNotification: item?.emailAddressesNotification,
          emailAddressesInvoice: item?.emailAddressesInvoice,
          createParameters: values.createParameters,
          createParametersAfterPayment: values.createParametersAfterPayment,
          isActive: item?.isActive,
          proformaEmailContent: {
            subject: values.proformaSubject,
            plainText: values.proformaPlainText,
            htmlText: values.proformaHtmlText
          },
          taxEmailContent: {
            subject: values.taxSubject,
            plainText: values.taxPlainText,
            htmlText: values.taxHtmlText
          },
          period: {
            startDate: values.startDate,
            frequency: values.frequency,
            endDate: values.endDate === '' ? null : values.endDate,
          },
          texts: item?.texts.filter(txt => { if (values.invoiceType === 'T') { return txt.documentType === 'K'} else { return txt } }).map(item => { return {text: item.text, type: item.type, documentType: item.documentType} }),
          header: {
            invoiceType: values.invoiceType,
            invoiceTypeScope: values.invoiceTypeScope,
            dueDateIncrement: values.dueDateIncrement,
            variableSymbol: values.variableSymbol,
            specificSymbol: values.specificSymbol,
            vatRoundingType: values.vatRoundingType,
            isRoundingTotal: values.isRoundingTotal,
            paymentTerms: values.paymentTerms,
            note: values.note,
            
            subscriber: values.useDefaultSubscriber === true ? null : {
              accountNumber: values.accountNumber,
              name: values.name,
              businessName: values.businessName,
              useDefaultSubscriber: values.useDefaultSubscriber,
              companyRegistrationNumber: values.companyRegistrationNumber,
              taxIdentificationNumber: values.taxIdentificationNumber,
              title: values.title,
              firstName: values.firstName,
              surName: values.surName,
              titleAfter: values.titleAfter,
              street: values.street,
              houseNumber: values.houseNumber,
              identificationNumber: values.identificationNumber,
              postalCode: values.postalCode,
              city: values.city
            },
          },
          items: 
            item?.items.map(item => { 
              if (item.new === true) { 
               return {name: item.name, count: item.count, unit: item.unit, vatType: item.vatType, vatRate: item.vatRate, accountingType: item.accountingType, rank: item.rank, unitPrice: {value: item.unitPrice.value, expression: item.unitPrice.expression}, amount: {value: item.amount.value, expression: item.amount.expression}, data: item.data  }
              }
              else {
                return {id: item.id, name: item.name, count: item.count, unit: item.unit, vatType: item.vatType, vatRate: item.vatRate, accountingType: item.accountingType, rank: item.rank, unitPrice: {value: item.unitPrice.value, expression: item.unitPrice.expression}, amount: {value: item.amount.value, expression: item.amount.expression}, data: item.data  } 
              }
              
            })
        }

        urlForEditing = '/'+props.templateId
        }
        else {
          dataToSend = {
            customerId: values.customerIdToSend,
            supplierId: values.supplierId,
            name: values.templateName,
            emailAddressesNotification: item?.emailAddressesNotification,
            emailAddressesInvoice: item?.emailAddressesInvoice,
            createParameters: values.createParameters,
            createParametersAfterPayment: values.createParametersAfterPayment,
            isActive: activate,
            proformaEmailContent: {
              subject: values.proformaSubject,
              plainText: values.proformaPlainText,
              htmlText: values.proformaHtmlText
            },
            taxEmailContent: {
              subject: values.taxSubject,
              plainText: values.taxPlainText,
              htmlText: values.taxHtmlText
            },
            period: {
              startDate: values.startDate,
              frequency: values.frequency,
              endDate: values.endDate === '' ? null : values.endDate,
            },
            texts: item?.texts.filter(txt => { if (values.invoiceType === 'T') { return txt.documentType === 'K'} else { return txt } }).map(item => { return {text: item.text, type: item.type, documentType: item.documentType} }),
            header: {
              invoiceType: values.invoiceType,
              invoiceTypeScope: values.invoiceTypeScope,
              dueDateIncrement: values.dueDateIncrement,
              variableSymbol: values.variableSymbol,
              specificSymbol: values.specificSymbol,
              vatRoundingType: values.vatRoundingType,
              isRoundingTotal: values.isRoundingTotal,
              paymentTerms: values.paymentTerms,
              note: values.note,
              
              subscriber: values.useDefaultSubscriber === true ? null : {
                accountNumber: values.accountNumber,
                name: values.name,
                businessName: values.businessName,
                useDefaultSubscriber: values.useDefaultSubscriber,
                companyRegistrationNumber: values.companyRegistrationNumber,
                taxIdentificationNumber: values.taxIdentificationNumber,
                title: values.title,
                firstName: values.firstName,
                surName: values.surName,
                titleAfter: values.titleAfter,
                street: values.street,
                houseNumber: values.houseNumber,
                identificationNumber: values.identificationNumber,
                postalCode: values.postalCode,
                city: values.city
              },
            },
            items: 
              item?.items.map(item => { 
                if (item.new === true) { 
                 return {name: item.name, count: item.count, unit: item.unit, vatType: item.vatType, vatRate: item.vatRate, accountingType: item.accountingType, rank: item.rank, unitPrice: {value: item.unitPrice.value, expression: item.unitPrice.expression}, amount: {value: item.amount.value, expression: item.amount.expression}, data: item.data  }
                }
                else {
                  return {id: item.id, name: item.name, count: item.count, unit: item.unit, vatType: item.vatType, vatRate: item.vatRate, accountingType: item.accountingType, rank: item.rank, unitPrice: {value: item.unitPrice.value, expression: item.unitPrice.expression}, amount: {value: item.amount.value, expression: item.amount.expression}, data: item.data  } 
                }
                
              })
          }
        }

      // Nastavení adresy požadavku podle typu NOVÝ/EDITACE
      
      

      // Zavolání API
      await axios.post(getUrl('URL_INVOICES_PERIODIC_TEMPLATES')+urlForEditing, dataToSend , { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
            
          (async () => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setCentered(true)
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          setInsertNewItem(false)
          sessionStorage.setItem('autoCloseModal','1')
          await rootInvoicesStore.fetchInvoicesPeriodicTemplatesList( Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')))

          sessionStorage.setItem('invoicesPeriodicTemplatesDetailEdited', 'Y')

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShowForm('')
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              setShowInfo(false)
              handleCloseMain()
              //setShowForm('')
              setCentered(false)
            }
          }, 2800);

        })()

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        
    })()

  }

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Nastavení dnešního data na začátek dne
  
  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    startDate: Yup.date()
    .required('Datum začátku musí být vyplněno!')
    .min(today, 'Datum začátku nesmí být v minulosti!'),
    customerId: Yup.string()
    .required('Zákazník musí být vybrán!'),
    frequency: Yup.string()
    .required('Frekvence fakturace musí být vyplněna!'),
    invoiceType: Yup.string()
    .required('Typ dokladu musí být vyplněn!'),
    supplierId: Yup.string()
    .required('Dodavatel musí být vybrán!'),
    templateName: Yup.string()
    .required('Název šablony musí být vyplněn!'),
    dueDateIncrement: Yup.number()
    .required('Počet dní splatnosti musí být vyplněn!'),
    paymentTerms: Yup.string()
    .required('Způsob úhrady musí být vyplněn!'),
    vatRoundingType: Yup.string()
    .required('Způsob zaokrouhlení DPH musí být nastaven!'),
    createParameters: Yup.string()
    .required('Stupeň automatizace musí být nastaven!'),
    
    
  }) 



return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>{props.editing === true ? 'Upravit':'Vytvořit novou šablonu'}</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail šablony' onClick={() => {startSequence()}}>{props.editing === true ? 'Upravit šablonu':'Vytvořit novou šablonu'}</span>
      </>
    :
    <>
      <Button variant='success' size="sm" style={{}} onClick={() => {startSequence()}} ><i className="fas fa-plus"></i> Přidat šablonu</Button>
    </>
    }
  
    <Modal 
      
      scrollable
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered={centered}
      >
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          {props.editing === true 
          ? 
            <>
              Úprava šablony&nbsp;<Badge variant='secondary'>{item?.name}</Badge>
            </>
            :
            <>
              Vytvoření nové šablony periodické fakturace
            </>}
        </div>
        <div className="showWeb">
          {props.editing === true 
          ? 
            <>
              Úprava šablony&nbsp;<Badge variant='secondary'>{item?.name}</Badge>
            </>
            :
            <>
              Vytvoření nové šablony periodické fakturace
            </>}
          
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <div ref={modalRef}> 
          <Alert variant="success" show={showInfo}>
            <strong>Šablona periodické fakturace byla úspěšně {props.editing === true ? 'upravena':'přidána'}!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              innerRef={formikRef}
              validationSchema={schemaNewItem}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ 
                customerId: props.editing === true ? item?.accountNumber : '', //
                customerIdToSend: props.editing === true ? item?.customerId : '', //
                customerBusinessName: props.editing === true ? item?.businessName : '', //
                supplierId: props.editing === true ? item?.supplierId : !(localStorage.getItem('invoicesLastSupplier') === null || localStorage.getItem('invoicesLastSupplier') === undefined) ? localStorage.getItem('invoicesLastSupplier') : '', //
                templateName: props.editing === true ? item?.name : '',

                invoiceType: props.editing === true ? item?.header.invoiceType : 'T',
                invoiceTypeScope: props.editing === true ? item?.header.invoiceTypeScope : '',

                dueDateIncrement: props.editing === true ? item?.header.dueDateIncrement : 14,
                variableSymbol: props.editing === true ? item?.header.variableSymbol : '',
                specificSymbol: props.editing === true ? item?.header.specificSymbol : '',
                vatRoundingType: props.editing === true ? item?.header.vatRoundingType : 'MH',
                isRoundingTotal: props.editing === true ? item?.header.isRoundingTotal : false,
                paymentTerms: props.editing === true ? item?.header.paymentTerms : 'Bank transfer',
                note: props.editing === true ? item?.header.note : '',

                accountNumber: props.editing === true ? item?.header.subscriber!.accountNumber : '',
                name: props.editing === true ? item?.header.subscriber!.name : '',
                businessName: props.editing === true ? item?.header.subscriber!.businessName : '',
                useDefaultSubscriber: props.editing === true ? (item?.header.subscriber! === undefined || item?.header.subscriber! ===  null || item?.header.subscriber?.businessName ===  '') ? true:false : true,
                subscriberName: props.editing === true ? item?.header.subscriber!.name : '',
                companyRegistrationNumber: props.editing === true ? item?.header.subscriber!.companyRegistrationNumber : '',
                taxIdentificationNumber: props.editing === true ? item?.header.subscriber!.taxIdentificationNumber : '',
                title: props.editing === true ? item?.header.subscriber!.title : '',
                firstName: props.editing === true ? item?.header.subscriber!.firstName : '',
                surName: props.editing === true ? item?.header.subscriber!.surName : '',
                titleAfter: props.editing === true ? item?.header.subscriber!.titleAfter : '',
                street: props.editing === true ? item?.header.subscriber!.street : '',
                houseNumber: props.editing === true ? item?.header.subscriber!.houseNumber : '',
                identificationNumber: props.editing === true ? item?.header.subscriber!.identificationNumber : '',
                postalCode: props.editing === true ? item?.header.subscriber!.postalCode : '',
                city: props.editing === true ? item?.header.subscriber!.city : '',

                
                startDate: props.editing === true ? item?.period.startDate : moment(new Date()).format("YYYY-MM-DD"),
                endDate: props.editing === true ? item?.period.endDate : '',
                frequency: props.editing === true ? item?.period.frequency : 'Month',
                createParameters: props.editing === true ? item?.createParameters : 'send-email',
                createParametersAfterPayment: props.editing === true ? item?.createParametersAfterPayment : 'send-email',

                proformaSubject: props.editing === true ? item?.proformaEmailContent.subject : 'Ježek software - zálohová faktura',
                proformaPlainText: props.editing === true ? item?.proformaEmailContent.plainText : 'Dobrý den,\n\nv příloze naleznete zálohovou fakturu.\n\nS pozdravem\n\nJežek software',
                proformaHtmlText: props.editing === true ? item?.proformaEmailContent.htmlText : 'Dobrý den,<br><br>v příloze naleznete zálohovou fakturu.<br><br>S pozdravem<br><br>Ježek software',

                taxSubject: props.editing === true ? item?.taxEmailContent.subject : 'Ježek software - daňový doklad',
                taxPlainText: props.editing === true ? item?.taxEmailContent.plainText : 'Dobrý den,\n\nv příloze naleznete daňový doklad.\n\nS pozdravem\n\nJežek software',
                taxHtmlText: props.editing === true ? item?.taxEmailContent.htmlText : 'Dobrý den,<br><br>v příloze naleznete daňový doklad.<br><br>S pozdravem<br><br>Ježek software',
                
                temp: '',

              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(async () => {
                  if (props.editing !== true) {
                    let textEdit = <>Chcete šablonu po uložení rovnou aktivovat?</>
                    if (await confirm(textEdit,'Ano, uložit a aktivovat', 'Ne, pouze uložit', {title: 'Aktivace šablony po vytvoření', style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}})) {
                      handleUpdate(values,true)
                    } else {
                      handleUpdate(values,false)
                    }  
                  }
                  else {
                    handleUpdate(values,false)
                  }
                  //handleUpdate(values)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                validateField,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <CardDeck>
                  <Card>
                    <Card.Header>Dodavatel / Zákazník</Card.Header>
                    <Card.Body>
                      <Form.Row>
                        <FormGroup as={Col} lg={9} controlId="supplierId">
                          <FormLabel>Dodavatel</FormLabel>
                            <Form.Control as="select" 
                              name="supplierId" 
                              //onChange={handleChange}
                              onChange={async (e) => { await handleChange(e); validateField('supplierId'); localStorage.setItem('invoicesLastSupplier', e.target.value); }}
                              value={values.supplierId!}
                              isInvalid={!!errors.supplierId}
                              onBlur={() => validateField('supplierId')}
                              //tabIndex={undefined}
                              //onKeyDown={(e:any) => handleKeyDownNextWIthCorrection(e, 1, handleSubmit)}
                              //onKeyDown={(e:any) => handleKeyDownNextToRef(e, editorReference, handleSubmit, true)}
                              >
                                <option value="">--- Vyberte ---</option>
                                {
                                suppliersSelect
                                }
                            </Form.Control>
                            
                            <Form.Control.Feedback type="invalid">
                              {errors.supplierId}
                            </Form.Control.Feedback>
                        </FormGroup>

                      </Form.Row>
                      <Form.Row>
                      <FormGroup as={Col} lg={2} md={3} sm={3} xs={12} controlId="customerId">
                        <FormLabel >Zákazník</FormLabel>
                          <FormControl 
                            type="text"            
                            name="customerId"
                            value={values.customerId!}
                            onChange={async(e) => {await handleChange(e);  state.displayItems = true; state.activeItem = 0; await localStorage.setItem('customerQuickSearchValue', e.target.value);  await filterCustomers(e.target.value) }}
                            //tabIndex={1}
                            autoComplete="off"
                            autoFocus={true}
                            //disabled={disabledAN}
                            isValid={touched.customerId && !errors.customerId} 
                            isInvalid={!!errors.customerId}
                            onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 16)}
                            //id="customerId"
                          />
                          {values.customerId! !== '' && state.displayItems ?
                              <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                  <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                  {
                                      customers.map((dp,index) => {
                                          return (
                                              <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                                  onClick={async (e:any) => {await setFieldValue("customerId",dp.accountNumber); await setFieldValue("customerIdToSend",dp.id); await setFieldValue("customerBusinessName",dp.businessName); state.displayItems = false; await setNewCustomer(dp.id,dp.accountNumber!,dp.businessName, setFieldValue)}}
                                                  key = {dp.id}
                                                  >
                                                  {dp.accountNumber} - {dp.businessName}
                                              </li>
                                          )
                                      }).slice(0, 50)
                                  }
                                  </ul>
                              </div>
                              : null
                          }
                          <Form.Control.Feedback type="invalid">
                            {errors.customerId}
                          </Form.Control.Feedback>
                      </FormGroup>

                      <FormGroup as={Col} lg={7} md={7} sm={9} xs={12}  controlId="customerBusinessName">
                        <FormLabel >Název zákazníka</FormLabel>
                        <FormControl 
                            type="text"
                            name="customerBusinessName"
                            value={values.customerBusinessName!}
                            onChange={handleChange}
                            disabled={true}
                            isValid={touched.customerBusinessName && !errors.customerBusinessName} 
                            isInvalid={!!errors.customerBusinessName}
                          />
                      </FormGroup> 
                      </Form.Row>



                    </Card.Body>
                  </Card>

                  <Card className='border-left-info'>
                    <Card.Header>
                      <Button style={{float: 'right'}} variant='info' size='sm' onClick={() => { (values.useDefaultSubscriber && !editSubscriber) ? handleOnClickEditSubscriber() : setEditSubscriber(!editSubscriber)}}>{editSubscriber ? 'Uložit':'Upravit'}</Button>
                      Odběratel
                    </Card.Header>
                    <Card.Body>
                      <div className={editSubscriber ? 'nodisplay':''}>
                        <Badge variant={values.useDefaultSubscriber ? 'info':'warning'}>{values.useDefaultSubscriber ? 'Pro fakturaci bude vždy použita aktuální adresa zákazníka':'Pro fakturaci bude vždy použita tato UPRAVENÁ adresa'}</Badge>
                        <br />
                      <strong>{values.name}</strong>{values.name !== '' &&<br />}
                      <strong>{values.title}{values.title !== '' && ' '}{values.firstName} {values.surName}{values.titleAfter !== '' && ' '}{values.titleAfter}</strong>{values.surName !== '' &&<br />}
                      <br />
                      {values.street}&nbsp;{values.houseNumber}{!(values.identificationNumber === null || values.identificationNumber === undefined || values.identificationNumber === '') && '/'}{values.identificationNumber}<br />
                      {values.postalCode}&nbsp;{values.city}<br />
                      
                      {values.companyRegistrationNumber !== '' && <><br />IČO:&nbsp;{values.companyRegistrationNumber}</>}
                      {values.taxIdentificationNumber !== '' && <><br />DIČ:&nbsp;{values.taxIdentificationNumber}</>}
                          
                      </div>
                      <div className={editSubscriber ? '':'nodisplay'}>
                    
                      <FormGroup controlId="useDefaultSubscriber">
                                  <Form.Row>
                                    <Col md="1">
                                      <FormControl 
                                        type="checkbox"
                                        name="useDefaultSubscriber"
                                        size="sm"
                                        className="checkbox-small"
                                        //value={values.taxAble!}
                                        onChange={handleChange}
                                        checked={values.useDefaultSubscriber}
                                        isValid={touched.useDefaultSubscriber && !errors.useDefaultSubscriber} 
                                        isInvalid={!!errors.useDefaultSubscriber}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.useDefaultSubscriber}
                                      </Form.Control.Feedback>
                                    </Col>
                                    
                                    <FormLabel column lg={6}>Vždy používat aktuální adresu</FormLabel>
                                    
                                  </Form.Row>  
                                </FormGroup>
                    
                    <Form.Row>
                      <FormGroup as={Col} controlId="name">
                        <FormLabel >Název</FormLabel>
                          <FormControl 
                            type="text"
                            name="name"
                            value={values.name!}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name} 
                            isInvalid={!!errors.name}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                    <Form.Row>
                      <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="title">
                        <FormLabel >Titul</FormLabel>
                          <FormControl 
                            type="text"
                            name="title"
                            value={values.title!}
                            onChange={handleChange}
                            isValid={touched.title && !errors.title} 
                            isInvalid={!!errors.title}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.title}
                          </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} sm={4}  controlId="name">
                        <FormLabel>Jméno</FormLabel>
                          <FormControl 
                            type="text"
                            name="firstName"
                            value={values.firstName!}
                            onChange={handleChange}
                            isValid={touched.firstName && !errors.firstName} 
                            isInvalid={!!errors.firstName}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                          </FormGroup> 
                          <FormGroup as={Col} sm={4} md={4}  controlId="surName">
                          <FormLabel>Příjmení</FormLabel>
                          <FormControl 
                            type="text"
                            name="surName"
                            value={values.surName!}
                            onChange={handleChange}
                            isValid={touched.surName && !errors.surName} 
                            isInvalid={!!errors.surName}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.surName}
                          </Form.Control.Feedback>
                      </FormGroup> 
                      <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="titleAfter">
                        <FormLabel >Titul za</FormLabel>
                          <FormControl 
                            type="text"
                            name="titleAfter"
                            value={values.titleAfter!}
                            onChange={handleChange}
                            isValid={touched.titleAfter && !errors.titleAfter} 
                            isInvalid={!!errors.titleAfter}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.titleAfter}
                          </Form.Control.Feedback>
                      </FormGroup>
                    </Form.Row>
                      <Form.Row>
                        <FormGroup as={Col}  controlId="street">
                          <FormLabel>Ulice</FormLabel>
                          <FormControl 
                            type="text"
                            name="street"
                            value={values.street!}
                            onChange={handleChange}
                            
                            isValid={touched.street && !errors.street} 
                            isInvalid={!!errors.street}
                            disabled={values.useDefaultSubscriber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.street}
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup as={Col} lg={2} controlId="houseNumber">
                          <FormLabel>Číslo popisné</FormLabel>
                            <FormControl 
                              type="text"
                              name="houseNumber"
                              value={values.houseNumber!}
                              onChange={handleChange}
                              
                              isValid={touched.houseNumber && !errors.houseNumber} 
                              isInvalid={!!errors.houseNumber}
                              disabled={values.useDefaultSubscriber}
                            />
                            
                            <Form.Control.Feedback type="invalid">
                              {errors.houseNumber}
                            </Form.Control.Feedback>
                          </FormGroup>
                          <FormGroup as={Col} lg={2} controlId="identificationNumber">
                            <FormLabel>Číslo orientační</FormLabel>
                            <FormControl 
                              type="text"
                              name="identificationNumber"
                              value={values.identificationNumber!}
                              onChange={handleChange}
                              disabled={values.useDefaultSubscriber}  
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.identificationNumber}
                            </Form.Control.Feedback>
                        </FormGroup>
                      </Form.Row>

                      <Form.Row>  
                        <FormGroup as={Col} lg={3} controlId="postalCode">
                          <FormLabel>PSČ</FormLabel>
                          <FormControl 
                            type="text"
                            name="postalCode"
                            value={values.postalCode!}
                            onChange={handleChange}
                            isValid={touched.postalCode && !errors.postalCode} 
                            isInvalid={!!errors.postalCode}
                            disabled={values.useDefaultSubscriber}
                          />
                            
                          <Form.Control.Feedback type="invalid">
                            {errors.postalCode}
                          </Form.Control.Feedback>
                          </FormGroup>
                          <FormGroup as={Col} controlId="city">
                            <FormLabel>Město</FormLabel>
                            <FormControl 
                              type="text"
                              name="city"
                              value={values.city!}
                              onChange={handleChange}
                              
                              isValid={touched.city && !errors.city} 
                              isInvalid={!!errors.city}
                              disabled={values.useDefaultSubscriber}
                            />
                            
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          </FormGroup> 
                        </Form.Row>  


                        <FormGroup controlId="companyRegistrationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>IČO</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="companyRegistrationNumber"
                          value={values.companyRegistrationNumber!}
                          onChange={handleChange}

                          isValid={touched.companyRegistrationNumber && !errors.companyRegistrationNumber} 
                          isInvalid={!!errors.companyRegistrationNumber}
                          disabled={values.useDefaultSubscriber}
                        />                       
                        <Form.Control.Feedback type="invalid">
                          {errors.companyRegistrationNumber}
                        </Form.Control.Feedback>
                        
                        

                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="taxIdentificationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>DIČ</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="taxIdentificationNumber"
                          value={values.taxIdentificationNumber!}
                          onChange={handleChange}                          
                          isValid={touched.taxIdentificationNumber && !errors.taxIdentificationNumber} 
                          isInvalid={!!errors.taxIdentificationNumber}
                          disabled={values.useDefaultSubscriber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.taxIdentificationNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
<br />
                      


                  </div>


                    </Card.Body>
                  </Card>
                  </CardDeck>

                  <CardDeck style={{marginTop: '15px'}}>
                          <Card>
                          <Card.Header>Nastavení periodické fakturace</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col>
                              <FormGroup controlId="templateName">
                                <Form.Row>
                                  <FormLabel column lg={3}>Název šablony</FormLabel>
                                  <Col>
                                    <FormControl 
                                      type="text"
                                      name="templateName"
                                      value={values.templateName!}
                                      //onChange={handleChange}
                                      onChange={async (e) => {await handleChange(e); validateField('templateName')}}
                                      isValid={touched.templateName && !errors.templateName} 
                                      isInvalid={!!errors.templateName}
                                      onBlur={() => validateField('templateName')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.templateName}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Row>  
                              </FormGroup>
                              </Col>
                              <Col>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col>
                                <FormGroup controlId="invoiceType">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Typ dokladu</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="invoiceType"
                                        value={values.invoiceType!}
                                        onChange={async (e) => {await handleChange(e); validateField('invoiceType')}}
                                        onBlur={() => validateField('invoiceType')}
                                        isValid={touched.invoiceType && !errors.invoiceType} 
                                        isInvalid={!!errors.invoiceType}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          {invoicesTypesSelect}
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.invoiceType}
                                      </Form.Control.Feedback>

                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="paymentTerms">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Způsob úhrady</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="paymentTerms"
                                        value={values.paymentTerms!}
                                        onChange={async(e) => { await handleChange(e); setFieldValue('isRoundingTotal', rootInvoicesStore.dialsInvoicesPaymentTerms.find(pt => pt.paymentTerm === e.target.value)?.roundTotal ); setFieldValue('dueDateIncrement', e.target.value === 'Cash' ? 0:14) }}
                                        isValid={touched.paymentTerms && !errors.paymentTerms} 
                                        isInvalid={!!errors.paymentTerms}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          {paymentTermsSelect}
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.paymentTerms}
                                      </Form.Control.Feedback>

                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="dueDateIncrement">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Počet dní splatnosti</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="number"
                                        name="dueDateIncrement"
                                        value={values.dueDateIncrement!}
                                        onChange={handleChange}
                                        isValid={touched.dueDateIncrement && !errors.dueDateIncrement} 
                                        isInvalid={!!errors.dueDateIncrement}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.dueDateIncrement}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="variableSymbol">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Variabilní symbol</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="text"
                                        name="variableSymbol"
                                        value={values.variableSymbol!}
                                        onChange={handleChange}
                                        isValid={touched.variableSymbol && !errors.variableSymbol} 
                                        isInvalid={!!errors.variableSymbol}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.variableSymbol}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="specificSymbol">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Specifický symbol</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="text"
                                        name="specificSymbol"
                                        value={values.specificSymbol!}
                                        onChange={handleChange}
                                        isValid={touched.specificSymbol && !errors.specificSymbol} 
                                        isInvalid={!!errors.specificSymbol}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.specificSymbol}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                
                              </Col>
                              <Col>
                                <FormGroup controlId="startDate">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Začátek fakturace</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="date"
                                        name="startDate"
                                        value={values.startDate!}
                                        onChange={handleChange}
                                        isValid={touched.startDate && !errors.startDate} 
                                        isInvalid={!!errors.startDate}
                                      />
                                      
                                      <Form.Control.Feedback type="invalid">
                                        {errors.startDate}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="frequency">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Frekvence</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="frequency"
                                        value={values.frequency!}
                                        onChange={handleChange}
                                        isValid={touched.frequency && !errors.frequency} 
                                        isInvalid={!!errors.frequency}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          <option value="Day">Den</option>
                                          <option value="Week">Týden</option>
                                          <option value="Month">Měsíc</option>
                                          <option value="Quarter">Čtvrtletí</option>
                                          <option value="HalfYear">Půlrok</option>
                                          <option value="Year">Rok</option>
                                          <option value="LastDayOfMonth">Poslední den v měsíci</option>
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.frequency}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                
                                <FormGroup controlId="endDate">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Konec fakturace</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="date"
                                        name="endDate"
                                        value={values.endDate!}
                                        onChange={handleChange}
                                        isValid={touched.endDate && !errors.endDate} 
                                        isInvalid={!!errors.endDate}
                                      />
                                      
                                      <Form.Control.Feedback type="invalid">
                                        {errors.endDate}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="createParameters">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Stupeň automatizace</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="createParameters"
                                        value={values.createParameters!}
                                        onChange={handleChange}
                                        isValid={touched.createParameters && !errors.createParameters} 
                                        isInvalid={!!errors.createParameters}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          <option value="create">Doklad pouze vytvořit</option>
                                          <option value="pdf">Doklad vytvořit a vygenerovat PDF</option>
                                          <option value="send-email">Doklad vytvořit, vygenerovat PDF, zveřejnit a odeslat e-mail</option>
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.createParameters}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                {values.invoiceType === 'P' &&
                                <FormGroup controlId="createParametersAfterPayment">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Akce po úhradě proformy</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="createParametersAfterPayment"
                                        value={values.createParametersAfterPayment!}
                                        onChange={handleChange}
                                        isValid={touched.createParametersAfterPayment && !errors.createParametersAfterPayment} 
                                        isInvalid={!!errors.createParametersAfterPayment}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          <option value="none">Nedělat nic</option>
                                          <option value="send-email">Vytvořit konečnou fakturu, vygenerovat PDF, zveřejnit a odeslat e-mail</option>
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.createParametersAfterPayment}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>}
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col>
                                

                                
                                <FormGroup controlId="isRoundingTotal">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Zaokrouhlovat celkem</FormLabel>
                                    <Col md="1">
                                      <FormControl 
                                        type="checkbox"
                                        name="isRoundingTotal"
                                        size="sm"
                                        className="checkbox-small"
                                        //value={values.taxAble!}
                                        onChange={handleChange}
                                        checked={values.isRoundingTotal}
                                        isValid={touched.isRoundingTotal && !errors.isRoundingTotal} 
                                        isInvalid={!!errors.isRoundingTotal}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.isRoundingTotal}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                              </Col>
                              <Col>
                                
                                
                                <FormGroup controlId="vatRoundingType">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Zaokrouhlení DPH</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="vatRoundingType"
                                        value={values.vatRoundingType!}
                                        onChange={handleChange}
                                        isValid={touched.vatRoundingType && !errors.vatRoundingType} 
                                        isInvalid={!!errors.vatRoundingType}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          {invoicesVatRoundingTypesSelect}
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.vatRoundingType}
                                      </Form.Control.Feedback>

                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                              </Col>
                            </Row>
                          </Card.Body>  

                          </Card>


                  </CardDeck>  

                  <>
                  <br />
                  <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={6}>
                      <CardDeck>

                        <Card className='border-left-danger'>
                          
                          <Card.Body>
                          
                              <Row><Col><strong>Celkem bez DPH:</strong></Col><Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.totalWithoutVatFromItems} /></Col></Row>
                          </Card.Body>
                        </Card>


                      </CardDeck>
                    </Col>
                  </Row>
                  <hr />

    <Tabs
      id="controlled-tab-example"
      activeKey={activeNav}
      onSelect={(k) => setActiveNav(k!)}
       className='invoice-items'
    >
      <Tab eventKey="items" tabClassName={item?.items.length === 0 ? '':'tab-with-data'} title="Položky">
      </Tab>
      <Tab eventKey="textsAbove" tabClassName={item?.textsAbove.length === 0 ? '':'tab-with-data'} title="Texty nad">
      </Tab>
      <Tab eventKey="textsUnder" tabClassName={item?.textsUnder.length === 0 ? '':'tab-with-data'} title="Texty pod">
      </Tab>
      <Tab eventKey="emailsInvoices" tabClassName={item?.emailAddressesInvoice.length === 0 ? '':'tab-with-data'} title="E-maily - fakturace">
      </Tab>
      <Tab eventKey="emailsNotifications" tabClassName={item?.emailAddressesNotification.length === 0 ? '':'tab-with-data'} title="E-maily - notifikace">
      </Tab>
      {values.invoiceType === 'P' &&
      <Tab eventKey="proformaEmailContent" tabClassName='tab-with-data' title="Obsah e-mailu - proforma">
      </Tab>}
      <Tab eventKey="taxEmailContent" tabClassName='tab-with-data' title="Obsah e-mailu - faktura">
      </Tab>

    </Tabs>





            <div style={{border: '1px solid #C0D1E1', padding: '5px', borderTopWidth: '0px'}}>
            
            {activeNav === 'textsAbove' && <>
              <InvoicesPeriodicTemplatesAddText addText={item?.addTextToNew} setFieldValue={setFieldValue} type='above' invoiceType={values.invoiceType} /> <br />
              {item?.textsAbove.length === 0 && <><br />Doklad neobsahuje žádné texty nad.</>}
              {item?.textsAbove
              .filter(textAbove => { if (values.invoiceType === 'T') { return textAbove.documentType === 'K' } else { return textAbove }})
              .map(textAbove => (
                <Card key={textAbove.id} style={{marginTop: '10px'}}>
                  <Card.Body>
                    <div style={{float: 'right'}}>
                      <Button variant='danger' size='sm' onClick={async () => {await item.removeText(textAbove.id); setFieldValue('temp', Math.random())}}>Smazat</Button>
                    </div>
                    <Badge variant='secondary'>{textAbove.documentType === 'Z' ? 'Zálohová faktura': textAbove.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textAbove.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'textsUnder' && <>
              <InvoicesPeriodicTemplatesAddText addText={item?.addTextToNew} setFieldValue={setFieldValue} type='under' /> <br />
              {item?.textsUnder.length === 0 && <><br />Doklad neobsahuje žádné texty pod.</>}
              {item?.textsUnder
              .filter(textUnder => { if (values.invoiceType === 'T') { return textUnder.documentType === 'K' } else { return textUnder }})
              .map(textUnder => (
                <Card key={textUnder.id}  style={{marginTop: '10px'}}>
                  <Card.Body>
                    <div style={{float: 'right'}}>
                      <Button variant='danger' size='sm' onClick={async () => {await item.removeText(textUnder.id); setFieldValue('temp', Math.random())}}>Smazat</Button>
                    </div>
                    <Badge variant='secondary'>{textUnder.documentType === 'Z' ? 'Zálohová faktura': textUnder.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textUnder.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}


            {activeNav === 'items' && <>
              <InvoicesPeriodicTemplatesAddItem addItem={item?.addItem} setFieldValue={setFieldValue} /> <br />
              {item?.items.length === 0 ? <><br />Doklad neobsahuje žádné položky.</>
              :

              <Table hover size='sm' style={{marginTop: '10px'}}>
                <thead>
                  <tr className="jsw-table-head">
                    <th className="jsw-table-head" title=''>Název položky</th>
                    <th className="jsw-table-head" title='Celková částka dokladu včetně daně' style={{textAlign: 'right'}}>Jednotková cena</th>
                    <th className="jsw-table-head" title='Množství' style={{textAlign: 'right'}}>Množství</th>
                    <th className="jsw-table-head row-slim" title='Jednotka' ></th>
                    <th className="jsw-table-head" title='Celková částka položky bez daně' style={{textAlign: 'right'}}>Celkem</th>
                    <th className="jsw-table-head row-slim" title='Sazba DPH' style={{textAlign: 'center'}}>DPH</th>
                    <th className="jsw-table-head row-slim" title='Druh účtování'>Účtování</th>
                    <th className="jsw-table-head row-slim" title='Akce'>Akce</th>
                  </tr>
                </thead>
                <tbody>
                  {item?.items.map(invoiceItem => ( 
                    <tr key={invoiceItem.id}>
                      <td>{invoiceItem.name}</td>
                      <td style={{textAlign: 'right'}}>{!(invoiceItem.unitPrice.value === 0 || invoiceItem.unitPrice.value === null) ? <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.unitPrice.value} />: invoiceItem.unitPrice.expression}</td>
                      <td style={{textAlign: 'right'}}>{invoiceItem.count}</td>
                      <td>{invoiceItem.unit}</td>
                      <td style={{textAlign: 'right'}}>{!(invoiceItem.amount.value === 0 || invoiceItem.amount.value === null) ? <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.amount.value} />: invoiceItem.amount.expression}</td>
                      <td style={{textAlign: 'center'}}>{invoiceItem.vatRate}&nbsp;%</td>
                      <td>{invoiceItem.accountingType}</td>
                      <td>
                      <ButtonGroup size='sm'>
                        <InvoicesPeriodicTemplatesAddItem addItem={invoiceItem.updateItem} setFieldValue={setFieldValue} editing={true} templateId={item.id} itemId={invoiceItem.id} /> 
                        <Button variant='outline-danger' size='sm' onClick={async () => {await item.removeItem(invoiceItem.id); setFieldValue('temp', Math.random())}}>Smazat</Button>
                      </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>}

            </>}


            {activeNav === 'emailsInvoices' && <>
              <InvoicesPeriodicTemplatesAddEmail addEmail={item?.addEmailAdressInvoice}  setFieldValue={setFieldValue} /> <br />
              {item?.emailAddressesInvoice.length === 0 && <><br />Doklad neobsahuje žádné emaily.</>}
              {item?.emailAddressesInvoice.map((emails,index) => (
                <Card key={emails} style={{marginTop: '10px'}}>
                  <Card.Body>
                    <div style={{float: 'right'}}>
                      <Button variant='danger' size='sm' onClick={async () => {await item.removeEmailAdressInvoice(index); setFieldValue('temp', Math.random())  }}>Smazat</Button>
                    </div>
                    
                    {emails!} 
                    
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'emailsNotifications' && <>
              <InvoicesPeriodicTemplatesAddEmail addEmail={item?.addEmailAdressNotification}  setFieldValue={setFieldValue} /> <br />
              {item?.emailAddressesNotification.length === 0 && <><br />Doklad neobsahuje žádné emaily.</>}
              {item?.emailAddressesNotification.map((emails,index) => (
                <Card key={emails} style={{marginTop: '10px'}}>
                  <Card.Body>
                  <div style={{float: 'right'}}>
                      <Button variant='danger' size='sm' onClick={async () => {await item.removeEmailAdressNotification(index); setFieldValue('temp', Math.random()) }}>Smazat</Button>
                    </div>
                    
                    {emails!} 
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'proformaEmailContent' && <>
                  <FormGroup controlId="proformaSubject">
                    <Form.Row>
                      <FormLabel column lg={3}>Předmět</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="proformaSubject"
                          value={values.proformaSubject}
                          onChange={handleChange}
                          isValid={touched.proformaSubject && !errors.proformaSubject} 
                          isInvalid={!!errors.proformaSubject}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.proformaSubject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="proformaPlainText">
                    <Form.Row>
                      <FormLabel column lg={3}>Textový obsah</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl as="textarea" style={{height: "150px"}}
                          type="text"
                          name="proformaPlainText"
                          value={values.proformaPlainText}
                          onChange={handleChange}
                          isValid={touched.proformaPlainText && !errors.proformaPlainText} 
                          isInvalid={!!errors.proformaPlainText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.proformaPlainText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>           
                  <FormGroup controlId="proformaHtmlText">
                    <Form.Row>
                      <FormLabel column lg={3}>HTML obsah</FormLabel>
                      
                    </Form.Row> 
                    <Form.Row >
                      <Col>
                        <FormControl as="textarea" style={{height: "450px"}} 
                          type="text"
                          name="proformaHtmlText"
                          value={values.proformaHtmlText}
                          onChange={handleChange}
                          isValid={touched.proformaHtmlText && !errors.proformaHtmlText} 
                          isInvalid={!!errors.proformaHtmlText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.proformaHtmlText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup> 
            </>}

            {activeNav === 'taxEmailContent' && <>
                  <FormGroup controlId="taxSubject">
                    <Form.Row>
                      <FormLabel column lg={3}>Předmět</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl 
                          type="text"
                          name="taxSubject"
                          value={values.taxSubject}
                          onChange={handleChange}
                          isValid={touched.taxSubject && !errors.taxSubject} 
                          isInvalid={!!errors.taxSubject}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.taxSubject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="taxPlainText">
                    <Form.Row>
                      <FormLabel column lg={3}>Textový obsah</FormLabel>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <FormControl as="textarea" style={{height: "150px"}}
                          type="text"
                          name="taxPlainText"
                          value={values.taxPlainText}
                          onChange={handleChange}
                          isValid={touched.taxPlainText && !errors.taxPlainText} 
                          isInvalid={!!errors.taxPlainText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.taxPlainText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>           
                  <FormGroup controlId="taxHtmlText">
                    <Form.Row>
                      <FormLabel column lg={3}>HTML obsah</FormLabel>
                      
                    </Form.Row> 
                    <Form.Row >
                      <Col>
                        <FormControl as="textarea" style={{height: "450px"}} 
                          type="text"
                          name="taxHtmlText"
                          value={values.taxHtmlText}
                          onChange={handleChange}
                          isValid={touched.taxHtmlText && !errors.taxHtmlText} 
                          isInvalid={!!errors.taxHtmlText}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.taxHtmlText}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup> 
            </>}
            
              
            </div>


          </>
              <br />
              {error}
              <br />
          <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám šablonu...' : 'Uložit šablonu'}</Button>
                

                </Form>
              )}
            </Formik> 
      </div>
      </div>
      

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(InvoicesPeriodicTemplatesAddUpdate))
