import React from "react";
import { Switch, Route } from "react-router-dom";
import { Pages } from "./pages";
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import OrdinaryRoute from './Utils/OrdinaryRoute';


export type ResetPasswordUrlParams = {
    resetToken: string
}

export type RegistrationUrlParams = {
    token: string
}

export type ConfirmRegistrationUrlParams = {
    confToken: string
}

export type UserDetailUrlParams = {
    id: string
}

export type ClientDetailUrlParams = {
    id: string
}

export type OverviewsDetailUrlParams = {
    id: string
}

export type OverviewsDetailOfferUrlParams = {
    id: string
}

export type TemplatesDetailUrlParams = {
    id: string
}

export type UpgradeOfferDetailUrlParams = {
    id: string
}

export type EmailingUnsubscribeUrlParams = {
    email: string,
    hash: string
}

export type InvoicesUrlParams = {
    invoiceType: string
}

export const ROUTES = {
    /* ACCOUNTS */
    login: {
        route: "/login",
        getLink: () => {
            return ROUTES.login.route
        } 
    },
    logout: {
        route: "/logout",
        getLink: () => {
            return ROUTES.logout.route
        } 
    },  
    forgottenPassword: {
        route: "/forgotten-password",
        getLink: () => {
            return ROUTES.forgottenPassword.route
        } 
    },
    resetPassword: {
        route: "/reset-password/:resetToken",
        getLink: (resetToken: string) => {
            return ROUTES.resetPassword.route.replace(":resetToken", resetToken)
        } 
    },
    registration: {
        route: "/invite/:token",
        getLink: (token: string) => {
            return ROUTES.registration.route.replace(":token", token)
        } 
    },
    confirmRegistration: {
        route: "/confirm-registration/:confToken",
        getLink: (confToken: string) => {
            return ROUTES.confirmRegistration.route.replace(":confToken", confToken)
        } 
    },   
    myAccount: {
        route: "/my-account",
        getLink: () => {
            return ROUTES.myAccount.route
        } 
    },  
    accounts: {
        route: "/accounts",
        getLink: () => {
            return ROUTES.accounts.route
        } 
    },    
    

    /* CUSTOMERS */
    open: {
        route: "/customers-open",
        getLink: () => {
            return ROUTES.open.route
        } 
    },
    dashboard: {
        route: "/customers-dashboard",
        getLink: () => {
            return ROUTES.dashboard.route
        } 
    },
    documents: {
        route: "/customers-documents",
        getLink: () => {
            return ROUTES.documents.route
        } 
    },
    userManagement: {
        route: "/customers-user-management",
        getLink: () => {
            return ROUTES.userManagement.route
        } 
    },
    licenses: {
        route: "/customers-licenses",
        getLink: () => {
            return ROUTES.licenses.route
        } 
    },
    history: {
        route: "/history",
        getLink: () => {
            return ROUTES.history.route
        } 
    },
    emailsSent: {
        route: "/emails-history",
        getLink: () => {
            return ROUTES.emailsSent.route
        } 
    },

    /* SELECTIONS */
    overviews: {
        route: "/overviews",
        getLink: () => {
            return ROUTES.overviews.route
        } 
    },
    overviewsDetail: {
        route: "/overviews-detail/:id",
        getLink: (id: number) => {
            return ROUTES.overviewsDetail.route.replace(":id", id.toString())
        } 
    },
    overviewsDetailOffer: {
        route: "/overviews-detail-offer/:id",
        getLink: (id: number) => {
            return ROUTES.overviewsDetailOffer.route.replace(":id", id.toString())
        } 
    },
    overviewsonlycustomers: {
        route: "/overviews-customers",
        getLink: () => {
            return ROUTES.overviewsonlycustomers.route
        } 
    },

    /* TEMPLATES */
    templates: {
        route: "/templates",
        getLink: () => {
            return ROUTES.templates.route
        } 
    },
    templatesDetail: {
        route: "/templates-detail/:id",
        getLink: (id: number) => {
            return ROUTES.templatesDetail.route.replace(":id", id.toString())
        } 
    },

    /* OPERATIONS */
    operations: {
        route: "/operations",
        getLink: () => {
            return ROUTES.operations.route
        } 
    },

    /* LICENSES TRANSFER */
    licensesTransfer: {
        route: "/licenses-transfer",
        getLink: () => {
            return ROUTES.licensesTransfer.route
        } 
    },

    /* UPGRADE */
    upgradeOfferList: {
        route: "/upgrade-offers",
        getLink: () => {
            return ROUTES.upgradeOfferList.route
        } 
    },
    upgradeOfferDetail: {
        route: "/upgrade-offer-detail/:id",
        getLink: (id: string) => {
            return ROUTES.upgradeOfferDetail.route.replace(":id", id)
        } 
    },

    userDetail: {
        route: "/user-detail/:id",
        getLink: (id: number) => {
            return ROUTES.userDetail.route.replace(":id", id.toString())
        }
    },

    /* CALCULATORS */
    upgradeCalculator: {
        route: "/upgrade-calculator",
        getLink: () => {
            return ROUTES.upgradeCalculator.route
        } 
    },

    /* SUPPORT */
    support: {
        route: "/support",
        getLink: () => {
            return ROUTES.support.route
        } 
    },

    /* SUPPORT */
    invoices: {
        route: "/invoices/:invoiceType",
        getLink: (invoiceType: string) => {
            return ROUTES.invoices.route.replace(":invoiceType", invoiceType.toString())
        } 
    },

    invoicesF: {
        route: "/invoices-f",
        getLink: () => {
            return ROUTES.invoicesF.route
        } 
    },
    invoicesFCustomer: {
        route: "/customer-invoices-tax",
        getLink: () => {
            return ROUTES.invoicesFCustomer.route
        } 
    },

    invoicesP: {
        route: "/invoices-p",
        getLink: () => {
            return ROUTES.invoicesP.route
        } 
    },

    invoicesPCustomer: {
        route: "/customer-invoices-proforma",
        getLink: () => {
            return ROUTES.invoicesPCustomer.route
        } 
    },

    invoicesPeriodicTemplates: {
        route: "/invoices-periodic-templates",
        getLink: () => {
            return ROUTES.invoicesPeriodicTemplates.route
        } 
    },

    /* DIALS */
    dials: {
        route: "/dials",
        getLink: () => {
            return ROUTES.dials.route
        } 
    },
    dialsSupportTypes: {
        route: "/dials-support-types",
        getLink: () => {
            return ROUTES.dialsSupportTypes.route
        } 
    },
    dialsSupportThemes: {
        route: "/dials-support-themes",
        getLink: () => {
            return ROUTES.dialsSupportThemes.route
        } 
    },
    dialsProgramVersions: {
        route: "/dials-program-versions",
        getLink: () => {
            return ROUTES.dialsProgramVersions.route
        } 
    },
    dialsFreeText: {
        route: "/dials-free-text",
        getLink: () => {
            return ROUTES.dialsFreeText.route
        } 
    },
    dialsOperationsPreferences: {
        route: "/dials-operation-preferences",
        getLink: () => {
            return ROUTES.dialsOperationsPreferences.route
        } 
    },
    dialsDeliveryCzechPostServices: {
        route: "/dials-delivery-czech-post-services",
        getLink: () => {
            return ROUTES.dialsDeliveryCzechPostServices.route
        } 
    },
    dialsDeliveryCzechPostProducts: {
        route: "/dials-delivery-czech-post-products",
        getLink: () => {
            return ROUTES.dialsDeliveryCzechPostProducts.route
        } 
    },
    dialsDeliveryCzechPostTemplates: {
        route: "/dials-delivery-czech-post-templates",
        getLink: () => {
            return ROUTES.dialsDeliveryCzechPostTemplates.route
        } 
    },
    dialsMeetingsCategories: {
        route: "/dials-meetings-categories",
        getLink: () => {
            return ROUTES.dialsMeetingsCategories.route
        } 
    },
    dialsUserGroups: {
        route: "/dials-user-groups",
        getLink: () => {
            return ROUTES.dialsUserGroups.route
        } 
    },    
    submissions: {
        route: "/submissions",
        getLink: () => {
            return ROUTES.submissions.route
        } 
    },
    /* MEETINGS & TASKS */
    meetings: {
        route: "/meetings",
        getLink: () => {
            return ROUTES.meetings.route
        } 
    },

    tasks: {
        route: "/tasks",
        getLink: () => {
            return ROUTES.tasks.route
        } 
    },

    /* EMAILING */
    emailingWhiteList: {
        route: "/emailing-whitelist",
        getLink: () => {
            return ROUTES.emailingWhiteList.route
        } 
    },
    emailingBlackList: {
        route: "/emailing-blacklist",
        getLink: () => {
            return ROUTES.emailingBlackList.route
        } 
    },
    datafiltersCustomersCustomList: {
        route: "/datafilters-customers-custom-list",
        getLink: () => {
            return ROUTES.datafiltersCustomersCustomList.route
        } 
    },
    emailingUnsubscribe: {
        route: "/unsubscribe/:email/:hash",
        getLink: (email: string, hash: string) => {
            return ROUTES.emailingUnsubscribe.route.replace(":email", email).replace(":hash", hash)
        } 
    },
}

export const RouterSwitch = () => <Switch>
    <Route path="/" exact component={Pages.Home} />
    
    <PublicRoute path={ROUTES.login.route} component={Pages.AccountsLogin} />
    <PrivateRoute path={ROUTES.logout.route} component={Pages.AccountsLogout} />
    <OrdinaryRoute path={ROUTES.forgottenPassword.route} component={Pages.AccountsForgottenPassword} />
    <OrdinaryRoute path={ROUTES.resetPassword.route} component={Pages.AccountsResetPassword} />
    <OrdinaryRoute path={ROUTES.registration.route} component={Pages.AccountsRegistration} />
    <OrdinaryRoute path={ROUTES.confirmRegistration.route} component={Pages.AccountsConfirmRegistration} />
    <PrivateRoute path={ROUTES.myAccount.route} component={Pages.AccountsMyAccount} />
    <PrivateRoute path={ROUTES.accounts.route} component={Pages.Accounts} />

    <PrivateRoute path={ROUTES.open.route} component={Pages.CustomersOpen} />
    <PrivateRoute path={ROUTES.dashboard.route} component={Pages.CustomersDashboard} />
    <PrivateRoute path={ROUTES.documents.route} component={Pages.CustomersDocuments} />
    <PrivateRoute path={ROUTES.userManagement.route} component={Pages.CustomersUserManagement} />
    <PrivateRoute path={ROUTES.licenses.route} component={Pages.CustomersLicenses} />
    <PrivateRoute path={ROUTES.history.route} component={Pages.CustomersHistory} />
    <PrivateRoute path={ROUTES.emailsSent.route} component={Pages.CustomersEmailsSent} />
    <PrivateRoute path={ROUTES.invoicesPCustomer.route} component={Pages.InvoicesPCustomer} />
    <PrivateRoute path={ROUTES.invoicesFCustomer.route} component={Pages.InvoicesFCustomer} />

    <PrivateRoute path={ROUTES.overviews.route} component={Pages.Overviews} />
    <PrivateRoute path={ROUTES.overviewsonlycustomers.route} component={Pages.OverviewsOnlyCustomersList} />
    <PrivateRoute path={ROUTES.overviewsDetail.route} component={Pages.OverviewsDetail} />
    <PrivateRoute path={ROUTES.overviewsDetailOffer.route} component={Pages.OverviewsDetailOffer} />

    <PrivateRoute path={ROUTES.templates.route} component={Pages.Templates} />
    <PrivateRoute path={ROUTES.templatesDetail.route} component={Pages.TemplatesDetail} />

    <PrivateRoute path={ROUTES.operations.route} component={Pages.Operations} />

    <PrivateRoute path={ROUTES.upgradeOfferList.route} component={Pages.UpgradeOfferList} />
    <PrivateRoute path={ROUTES.upgradeOfferDetail.route} component={Pages.UpgradeOfferDetail} />

    <PrivateRoute path={ROUTES.licensesTransfer.route} component={Pages.LicensesTransfer} />
    <PrivateRoute path={ROUTES.upgradeCalculator.route} component={Pages.UpgradeCalculator} />

    <PrivateRoute path={ROUTES.support.route} component={Pages.Support} />
    <PrivateRoute path={ROUTES.dials.route} component={Pages.Dials} />
    <PrivateRoute path={ROUTES.dialsSupportTypes.route} component={Pages.DialsSupportTypes} />
    <PrivateRoute path={ROUTES.dialsSupportThemes.route} component={Pages.DialsSupportThemes} />
    <PrivateRoute path={ROUTES.dialsProgramVersions.route} component={Pages.DialsProgramVersions} />
    <PrivateRoute path={ROUTES.dialsFreeText.route} component={Pages.DialsFreeText} />
    <PrivateRoute path={ROUTES.dialsOperationsPreferences.route} component={Pages.DialsOperationsPreferences} />
    <PrivateRoute path={ROUTES.dialsDeliveryCzechPostServices.route} component={Pages.DialsDeliveryCzechPostServices} />
    <PrivateRoute path={ROUTES.dialsDeliveryCzechPostProducts.route} component={Pages.DialsDeliveryCzechPostProducts} />
    <PrivateRoute path={ROUTES.dialsDeliveryCzechPostTemplates.route} component={Pages.DialsDeliveryCzechPostTemplates} />
    <PrivateRoute path={ROUTES.dialsMeetingsCategories.route} component={Pages.DialsMeetingsCategories} />
    <PrivateRoute path={ROUTES.dialsUserGroups.route} component={Pages.DialsUserGroups} />
    <PrivateRoute path={ROUTES.submissions.route} component={Pages.Submissions} />

    <PrivateRoute path={ROUTES.emailingWhiteList.route} component={Pages.EmailingWhiteList} />
    <PrivateRoute path={ROUTES.emailingBlackList.route} component={Pages.EmailingBlackList} />

    <OrdinaryRoute path={ROUTES.emailingUnsubscribe.route} component={Pages.EmailingUnsubscribe} />

    <PrivateRoute path={ROUTES.datafiltersCustomersCustomList.route} component={Pages.DatafiltersCustomersCustomList} />

    <PrivateRoute path={ROUTES.meetings.route} component={Pages.Meetings} />
    <PrivateRoute path={ROUTES.tasks.route} component={Pages.Tasks} />

    <PrivateRoute path={ROUTES.invoices.route} component={Pages.Invoices} />
    <PrivateRoute path={ROUTES.invoicesF.route} component={Pages.InvoicesF} />
    <PrivateRoute path={ROUTES.invoicesP.route} component={Pages.InvoicesP} />

    <PrivateRoute path={ROUTES.invoicesPeriodicTemplates.route} component={Pages.InvoicesPeriodicTemplates} />

    <Route>
        <>404</>
    </Route>
</Switch>