import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'


import { Button,  Form, Col, Modal } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'



interface InvoicesPeriodicTemplatesTextAddProps {
  addText:any,   
  type:string, 
  setFieldValue:any,
  invoiceType?:string,
} 

const InvoicesPeriodicTemplatesTextAdd: React.FC<InvoicesPeriodicTemplatesTextAddProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  
  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //clearInterval(interval)
  } 

  
  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    text: Yup.string()
      .required('Text musí být vyplněn!'),
    type: Yup.string()
      .required('Typ textu musí být vyplněn!'),
    documentType: Yup.string()
      .required('Typ dokumentu musí být vyplněn!'),    
    
  })  
  

// Startovací sequence při otevření formuláře
const startSequence = async () => {
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  //setShowInfo(false) 
  setShowForm('')
  
}


// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
            
          {insertNewContact &&<>
          <div className={showForm} >
           
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                text: '',
                type: props.type,
                documentType: '',      
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(async () => {
                  //handleUpdate(values!)   
                  await props.addText(values.text, values.type, values.documentType)     
                  props.setFieldValue('temp', Math.random())
                  setSubmitting(false);
                  handleCloseMain();
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <FormGroup controlId="type">
                      <Form.Row>
                        <FormLabel column lg={3}>Typ</FormLabel>
                        <Col>
                          <FormControl as="select" 
                            name="type"
                            value={values.type!}
                            onChange={handleChange}
                            isValid={touched.type && !errors.type} 
                            isInvalid={!!errors.type}
                            disabled
                            >
                              <option value="">--- Vyberte ---</option>
                              <option value="above">Text nad</option>
                              <option value="under">Text pod</option>
                            </FormControl>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>

                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    <FormGroup controlId="documentType">
                      <Form.Row>
                        <FormLabel column lg={3}>Typ dokumentu</FormLabel>
                        <Col>
                          <FormControl as="select" 
                            name="documentType"
                            value={values.documentType!}
                            onChange={handleChange}
                            isValid={touched.documentType && !errors.documentType} 
                            isInvalid={!!errors.documentType}
                            >
                              <option value="">--- Vyberte ---</option>
                              {props.invoiceType !== 'T' &&<option value="Z">Proforma</option>}
                              {props.invoiceType !== 'T' &&<option value="P">Daňový doklad</option>}
                              <option value="K">Konečný doklad</option>
                            </FormControl>
                            
                          <Form.Control.Feedback type="invalid">
                            {errors.documentType}
                          </Form.Control.Feedback>
                          

                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    <FormGroup  controlId="text">
                      <Form.Row>
                        <FormLabel column >Text</FormLabel>
                      </Form.Row>  
                      <Form.Row>
                        <Col>
                          <FormControl 
                            type="text"
                            name="text"
                            value={values.text!}
                            onChange={handleChange}
                            isValid={touched.text && !errors.text} 
                            isInvalid={!!errors.text}
                          />
                        
                          <Form.Control.Feedback type="invalid">
                            {errors.text}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>  
                    </FormGroup>
                    
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid)} block>Přidat</Button>
                  
                </Form>

              )}
            </Formik>  
            
          </div>
          </>}
          </>



return ( 
  <>
  <Button variant='success' size="sm" onClick={() => {startSequence()}} >+ Přidat text</Button>
    
  
    <Modal 
      show={insertNewContact} 
      onHide={handleCloseMain} 
      backdrop="static"
      //scrollable={true}
      dialogClassName="modal-90w"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Přidání textu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(InvoicesPeriodicTemplatesTextAdd))
