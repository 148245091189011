import { types } from 'mobx-state-tree'

export const DialsSuppliers = types
  .model({
    id: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    companyRegistrationNumber: types.maybeNull(types.string),
    taxIdentificationNumber: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    bankAccount: types.maybeNull(types.string),
    iban: types.maybeNull(types.string),
    swift: types.maybeNull(types.string),
    fileReference: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
