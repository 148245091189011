import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken,setUserRoles, getUrl } from '../../Utils/Common'

import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Alert, Modal, Dropdown, Button } from 'react-bootstrap'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../Utils/Confirmation"
import { login } from '../../authProvider'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

interface CustomersOtherActionsChangeStateProps {
  id: string,
  showAs: string
}

const CustomersOtherActionsChangeState: React.FC<CustomersOtherActionsChangeStateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  //const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //window.location.reload()
  }
  // eslint-disable-next-line
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    //setShowForm('')
    setShow(true)
  }

  // Funkce pro zavření firmy
  const handleCloseFirm = async () => {
    //setError(null)
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    // Zavolání API
    await axios.get(getUrl('URL_CUSTOMER_CLOSE')+localStorage.getItem('openedCustomerId'),{
      headers: {
        'Authorization': 'Bearer ' + aToken
      }}
    ).then(response => {
      (async () => {
      // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
      setUserRoles(response.data.roles)
      login(response.data.token)      
      })();
    }).catch(error => {
      //const errorMessage:any = (<ErrorAlert errorData={error} />)
      //setError(errorMessage)
    });
  }

  // Funkce po otevření firmy
  const handleOpenFirm = async () => {   
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    // Zavolání API
    await axios.get(getUrl('URL_CUSTOMER_OPEN')+localStorage.getItem('openedCustomerId'),{
      headers: {
        'Authorization': 'Bearer ' + aToken
      }}
    ).then(response => {
      (async () => {
      // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
      setUserRoles(response.data.roles)
      login(response.data.token)
      })();
    }).catch(error => {
      //const errorMessage:any = (<ErrorAlert errorData={error} />)
      //setError(errorMessage)
    });
  }


  // Dotaz před smazáním kontaktu
  const handleOnClickChangeState = (isActiveData: boolean) => {
    let stateNameTitle:string = isActiveData ? 'Aktivace':'Zneaktivnění'
    let stateName:string = isActiveData ? 'aktivovat':'zneaktivnit';
    let warningText:string = isActiveData ? 'V rámci aktivace zákazníka neprobíhá aktivace licenceí. Tu je v případě potřeby provést samostatně ve Správě licencí!':'V rámci deaktivace zákaznía budou deaktivování také všechny jeho aktivní licence!';

    (async () => {
      if (await confirm("Opravdu chcete zákazníka "+customer?.businessName+" "+stateName+"? "+warningText,'Ano, '+stateName+'', 'Ne', {title: stateNameTitle+' zákazníka'})) {
        handleChangeState(isActiveData)
      } else {
      
      }  
    })()
  }

  // Funkce pro odeslání e-mailu
  const handleChangeState = (isActiveData: boolean) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      
      await axios.post(getUrl('URL_CUSTOMER_CHANGE_STATE')+props.id+'/state', { customerId: props.id, isActive: isActiveData}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            if (isActiveData === true) {
              await handleOpenFirm()
              await rootStore.fetchCustomerOpenedLicenses(props.id)
              await rootStore.fetchInvite()
              await rootStore.fetchCustomerUsers()
            }
            else {
              await handleCloseFirm()
              await rootStore.deleteCustomerOpenedLicenses()
              await rootStore.deleteInvites()
              await rootStore.deleteCustomerUsers()
            }
            
            
            await rootStore.fetchCustomerOpened()

            setLoading(false)
            handleClose()
            //handleShow()
            //setShowInfo(true)
            //sessionStorage.setItem('autoCloseModal','1');

            // Po 2800 ms celé modální okno zavřeme
            /*
            setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                setShow(false)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //window.location.reload()
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);
            */
          })()
        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)
        });
    })()
  }

  
  return (
    <>
      {rights &&
        <>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleOnClickChangeState(customer?.isActive ? false : true) } }>{customer?.isActive ? 'Zneaktivnit zákazníka' : 'Aktivovat zákazníka'}</Dropdown.Item>}
          {props.showAs === 'button' &&<Button size="sm" variant="outline-primary" onClick={() => { handleOnClickChangeState(customer?.isActive ? false : true)  } }>{customer?.isActive ? 'Zneaktivnit zákazníka' : 'Aktivovat zákazníka'}</Button>}
        </>
      }
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{customer?.isActive ? 'Zneaktivnění':'Aktivace'} zákazníka</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{customer?.isActive ? 'Aktivace' : 'Zneaktivnění'} zákazníka</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Operace byla úspěšně provedena!​</strong>
            
          </Alert>  
          
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(CustomersOtherActionsChangeState))
