import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import { ROUTES } from '../../Routes'
import { Nav, Button, Form } from 'react-bootstrap'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

import EmailingSendEmails  from '../../pages/emailing/emailingSend/Emailing.SendEmails.part'

function SideMenu(props:any) {

    // Nastavení práv zda jsme Ježci
    const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
    const rightsBI:boolean = ((localStorage.getItem('Billing')! === '1')) ? true : false
    const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
    const rightsEM:boolean = ((localStorage.getItem('Emailing')! === '1')) ? true : false
    
    
    //const rightsME:boolean = ((localStorage.getItem('Meeting')! === '1')) ? true : false

    const handleMenuClick = () => {
        if (document.body.classList.contains('sb-sidenav-toggled')) {
            document.body.classList.remove('sb-sidenav-toggled')
        }
      }

      const handleShowHowUser = () => {
        if (localStorage.getItem('BigUser') === "1" && localStorage.getItem('BigUserBefore') === '0') {
            localStorage.removeItem('BigUser')
            localStorage.removeItem('BigUserBefore')
            
            if (localStorage.getItem('BigHedgehogBefore') === '1') { localStorage.setItem('BigHedgehog', '1') }
            if (localStorage.getItem('SmallHedgehogBefore') === '1') { localStorage.setItem('SmallHedgehog', '1') }
            localStorage.removeItem('BigHedgehogBefore')
            localStorage.removeItem('SmallHedgehogBefore')
            


            window.location.reload()
        }
        else {
            if (localStorage.getItem('BigUser') !== "1") { 
                localStorage.setItem('BigUser', '1')
                localStorage.setItem('BigUserBefore', '0')
                if (localStorage.getItem('BigHedgehog') === '1') { localStorage.setItem('BigHedgehogBefore', '1') }
                if (localStorage.getItem('SmallHedgehog') === '1') { localStorage.setItem('SmallHedgehogBefore', '1') }
                localStorage.removeItem('BigHedgehog')
                localStorage.removeItem('SmallHedgehog')


                window.location.reload()
             }
            
            
        }
      }
    
    const rootStore = useStore()
    const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

    if (customer !== undefined) {}

    if (localStorage.getItem('isLogged')) {
        return( 
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">                        
                        <Nav>
                            {rightsBH === true && localStorage.getItem('checkOperationsPreferences') === '1' 
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsOperationsPreferences.getLink()} onClick={handleMenuClick} style={{color: 'red'}}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-stopwatch fa-fw"></i></div> Preference procesů
                                </NavLink>
                                
                                : ''    
                            }
                            
                            <div >
                            <div className="sb-sidenav-menu-heading">{rights === true ? 'Zákazník' : 'Moje firma'}</div>
                            </div>
                            {localStorage.getItem('openedCustomerId') !== null 
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.dashboard.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt fa-fw"></i></div> Přehled
                                </NavLink>
                                : ''    
                            } 

                            {localStorage.getItem('openedCustomerId') !== null  
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.upgradeOfferList.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-cart-plus fa-fw"></i></div> Objednávky upgrade
                                </NavLink>
                                : ''    
                            }

                            {localStorage.getItem('openedCustomerId') !== null  
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.documents.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-file-pdf fa-fw"></i></div> Dokumenty
                                </NavLink>
                                : ''    
                            }

                            {rightsBH === true && localStorage.getItem('openedCustomerId') !== null 
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.invoicesPCustomer.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Zálohové faktury
                                </NavLink>
                                
                                : ''    
                            }
                            {rightsBH === true && localStorage.getItem('openedCustomerId') !== null 
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.invoicesFCustomer.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Daňové doklady
                                </NavLink>
                                
                                : ''    
                            } 

                            {localStorage.getItem('openedCustomerId') !== null && rightsBH === true  
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.licenses.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list-alt fa-fw"></i></div> Správa licencí
                                </NavLink>
                                : ''    
                            } 

                            {localStorage.getItem('openedCustomerId') !== null
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.userManagement.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-user fa-fw"></i></div> Správa přístupů
                                </NavLink>
                                : ''    
                            } 
                            

                            {localStorage.getItem('customersTotalCountInit') === '999' 
                                ? 
                                <div className="sb-sidenav-menu-heading">Další volby</div>
                                : ''
                            }

                            {localStorage.getItem('customersTotalCountInit') !== '1' 
                                ? 
                                <NavLink className="nav-link" to={ROUTES.open.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-arrows-alt-h fa-fw"></i></div> Přepnout {rights === true ? 'zákazníka' : 'firmu'}
                                </NavLink>
                                : ''
                            }

                            {rights === true 
                                ?
                                <div className="sb-sidenav-menu-heading">Podpora</div>
                                : ''    
                            }
                            {rights === true
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.support.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-headset fa-fw"></i></div> Kontakty
                                </NavLink>
                                
                                : ''    
                            }
                            

                            {rightsBH === true 
                                ?
                                <div className="sb-sidenav-menu-heading">Obchod</div>
                                : ''    
                            }

                        
                            {rightsBH === true  
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.overviewsonlycustomers.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Zákazníci
                                </NavLink>
                                
                                : ''    
                            } 
                            {rightsBH === true  
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.overviews.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Upgrade
                                </NavLink>
                                
                                : ''    
                            } 

                            

                            {rightsBH === true && localStorage.getItem('lastDatafilter') !== null && localStorage.getItem('lastDatafilterType') === 'customers' 
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.overviewsDetail.getLink(Number(localStorage.getItem('lastDatafilter')))} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Poslední seznam
                                </NavLink>
                                : ''    
                            }

                            {rightsBH === true && localStorage.getItem('lastDatafilter') !== null && localStorage.getItem('lastDatafilterType') === 'offers' 
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.overviewsDetailOffer.getLink(Number(localStorage.getItem('lastDatafilter')))} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Poslední seznam
                                </NavLink>
                                : ''    
                            }


                            {rightsBH === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.licensesTransfer.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-exchange-alt fa-fw"></i></div> Převody licencí
                                </NavLink>
                                
                                : ''    
                            }

                            {rightsBH === true  
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.submissions.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-truck-loading"></i></div> Distribuce zásilek
                                </NavLink>
                                
                                : ''    
                            } 


                            {rights === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.upgradeCalculator.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-calculator fa-fw"></i></div> Kalkulačka upgrade
                                </NavLink>
                                
                                : ''    
                            }


                            {(rightsBH === true || rightsBI === true) 
                                ?
                                <div className="sb-sidenav-menu-heading">Fakturace</div>
                                : ''    
                            }

                        
                            {(rightsBH === true || rightsBI === true)
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.invoicesP.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Zálohové faktury
                                </NavLink>
                                
                                : ''    
                            }
                            {(rightsBH === true || rightsBI === true)
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.invoicesF.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list fa-fw"></i></div> Daňové doklady
                                </NavLink>
                                
                                : ''    
                            } 
                            {(rightsBH === true || rightsBI === true)
                                ?       
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.invoicesPeriodicTemplates.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-redo fa-fw"></i></div> Periodická fakturace
                                </NavLink>                               
                                : ''    
                            } 

                             

                            {rights === true 
                                ?
                                <div className="sb-sidenav-menu-heading">Porady & úkoly</div>
                                : ''    
                            }
                            {rights === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.meetings.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-handshake fa-fw"></i></div> Porady
                                </NavLink>
                                
                                : ''    
                            }
                            {rights === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.tasks.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-tasks fa-fw"></i></div> Úkoly
                                </NavLink>
                                
                                : ''    
                            }



                            {(rightsEM === true || rights === true) && <div className="sb-sidenav-menu-heading">Emailing</div>}
                            {rightsEM === true  
                                &&
                                <EmailingSendEmails showAs='menu' />
                            }
                            {rights === true
                                &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.templates.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-envelope-open-text fa-fw"></i></div> Šablony e-mailů
                                </NavLink>    
                            }
                            {rightsEM === true  
                                &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.emailingWhiteList.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-thumbs-up fa-fw"></i></div> Interní Whitelist
                                </NavLink>  
                            } 
                            {rightsEM === true  
                                &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.emailingBlackList.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="far fa-thumbs-down fa-fw"></i></div> Interní Blacklist
                                </NavLink>  
                            } 
                            {rightsEM === true  
                                &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.datafiltersCustomersCustomList.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-user-edit fa-fw"></i></div> Uživatelské filtry
                                </NavLink>  
                            } 

                            
                            

                            {rights === true 
                                ?
                                <div className="sb-sidenav-menu-heading">Správa</div>
                                : ''    
                            }
                            {rights === true
                                ?
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.accounts.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-user fa-fw"></i></div> Uživatelské účty
                                </NavLink>
                                
                                : ''    
                            }
                            {rightsBH === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.operations.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-stopwatch fa-fw"></i></div> Hromadné operace
                                </NavLink>
                                
                                : ''    
                            }

                            
                            {rights === true
                                ?
                                
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.dials.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-list-ul fa-fw"></i></div> Číselníky
                                </NavLink>
                                
                                : ''    
                            }
                            

                            {rightsBH === true && sessionStorage.getItem('isTestRelease') === '1'
                                ?
                                ''
                                : ''    
                            }
                            <div className="sb-sidenav-menu-heading showMobileNav">Přihlášený uživatel</div>
                            <NavLink className="nav-link showMobileNav" to={ROUTES.myAccount.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fa fa-fingerprint fa-fw"></i></div> Můj účet
                            </NavLink>
                            {rights === true || localStorage.getItem('BigUserBefore') === '0'
                                ?
                                <div className="nav-link showMobileNav" style={{marginLeft: '0px', marginTop: '0px'}}>
                                    <div className="float-left">
                                    <Form.Check 
                                    type="switch"
                                    id="show-how-user"
                                    label=""
                                    name="show-how-user"
                                    //disabled={!rights || !upgradeOffer.showControlButtons}
                                    checked={localStorage.getItem('BigUser') === '1' ? true:false}
                                    onChange={async (e:any) => {await handleShowHowUser()}}
                                    //onClick={async () => {await handleShowHowUser();}}
                                    //checked={true}
                                    />
                                    </div>
                                    <div className="float-left" style={{marginLeft: '0px'}}>
                                    Pohled zákazníka
                                    </div>
                                    <div className="cleaner"></div>
                                </div>
                                
                                
                                : ''    
                            }




                            <NavLink className="nav-link showMobileNav" to={ROUTES.logout.getLink()} onClick={handleMenuClick}><Button variant="danger" size="sm">Odhlásit</Button></NavLink>
                        </Nav>
                    </div>
                    {rights !== true &&
                    <div className="sb-sidenav-footer">
                        Obchod<br />
                        <i className="fas fa-envelope"></i>&nbsp;&nbsp;<a style={{color: 'rgba(255, 255, 255, 0.5)'}} href="mailto:obchod@jezeksw.cz">obchod@jezeksw.cz</a>
                        <br />
                        <i className="fas fa-phone-square-alt"></i>&nbsp;&nbsp;+420 487 522 449
                        <br /><br />
                        
                        <div className="small">© {new Date().getFullYear().toString()}, Ježek software s.r.o.</div>
                        
                        {(props.loadingVersion === undefined || props.loadingVersion === false) ?
                        <div className="small">Verze: {sessionStorage.getItem("version")}</div>:<div className="small">Kontroluji verzi...</div>}
                        
                    </div>}
                    {rights === true &&
                    <div className="sb-sidenav-footer">
                        
                        
                        {(props.loadingVersion === undefined || props.loadingVersion === false) ?
                        <div className="small">Verze: {sessionStorage.getItem("version")}</div>:<div className="small">Kontroluji verzi...</div>}
                        
                    </div>}
                </nav>
            </div>
        )
    }
    else {
        return(
            <div id="layoutSidenav_nav"></div>
        )  
    }
      
}
  
export default observer(withRouter(SideMenu))