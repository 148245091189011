import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ReactDOM from 'react-dom';


import { Button,  Form, Col, Modal, ButtonGroup, DropdownButton, Dropdown, Alert } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { useInvoicesStore } from '../../../store'
import {vatTypes} from '../../../Constants/vatTypes'

interface InvoicesPeriodicTemplatesItemAddProps {
  addItem:any,   
  //type:string, 
  setFieldValue:any,
  editing?:boolean,
  itemId?:string,
  templateId?:string,
} 

const InvoicesPeriodicTemplatesItemAdd: React.FC<InvoicesPeriodicTemplatesItemAddProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  //const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  //const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  const [insertNewContact, setInsertNewContact] = useState(false)
  
  const rootInvoicesStore = useInvoicesStore()

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = () => {
    setInsertNewContact(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
    //clearInterval(interval)
  } 

  
  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    name: Yup.string()
      .required('Název položky musí být vyplněn!'),
    count: Yup.number()
      .required('Množství u položky musí být vyplněno!'),
    
  })  

const countEdit = rootInvoicesStore.invoicesPeriodicTemplates.filter(template => template.id === props.templateId).length

const master = countEdit > 0 ? rootInvoicesStore.invoicesPeriodicTemplates.find(template => template.id === props.templateId) : rootInvoicesStore.invoicesPeriodicTemplatesNewEdit.find(template => template.id === props.templateId)

const editedItem = master?.items.find(item => item.id === props.itemId)
  

// Startovací sequence při otevření formuláře
const startSequence = async () => {
  setInsertNewContact(true)
  //setStartDate(new Date()) 
  //setShowInfo(false) 
  setShowForm('')
  
}

const createDataString = (type:string, serviceId:string, moduleId:string, licenseId:string) => {
  let data = {
    type: type,
    serviceId: serviceId,
    moduleId: moduleId,
    licenseId: licenseId,
  }
  return JSON.stringify(data)
}

  // Seznam sazeb DPH
  const vatTypesSelect = vatTypes
  .filter(vt => vt.isActive === true)
  .slice()
  //.filter(dst => dst.isActive === true)
  .map((item,index) => (
    <option key={index} value={item.value}>{item.name}</option>
  ))  

  // Seznam jednotek
  const unitsSelect = rootInvoicesStore.dialsInvoicesUnits
  .slice()
  //.filter(dst => dst.isActive === true)
  .map((item,index) => (
    <option key={index} value={item}>{item}</option>
  ))  



// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>
            
          {insertNewContact &&<>
          <div className={showForm} >
           
            
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={props.validateOnMount}
              initialValues={{ 
                name: props.editing ? editedItem?.name : '',
                type: props.editing ?  !(editedItem?.data === '' || editedItem?.data === null) ? JSON.parse(editedItem?.data!).type : 'fixed' : 'fixed',
                count: props.editing ? editedItem?.count : 1,  
                vatType: props.editing ? editedItem?.vatType : 'Z', 
                vatRate: props.editing ? editedItem?.vatRate : Number(vatTypes.find(vt => vt.value === 'Z')?.vatRate),  
                unit: props.editing ? editedItem?.unit : 'ks',
                amountValue: props.editing ? editedItem?.amount.value : 0,
                amountExpression: props.editing ? editedItem?.amount.expression : '',
                unitPriceValue: props.editing ? editedItem?.unitPrice.value : 0,
                unitPriceExpression: props.editing ? editedItem?.unitPrice.expression : '',
                accountingType: props.editing ? editedItem?.accountingType : '',
                serviceId: props.editing ?  !(editedItem?.data === '' || editedItem?.data === null) ? JSON.parse(editedItem?.data!).serviceId : '' : '',
                moduleId: props.editing ?  !(editedItem?.data === '' || editedItem?.data === null) ? JSON.parse(editedItem?.data!).moduleId : '' : '',
                licenseId: props.editing ?  !(editedItem?.data === '' || editedItem?.data === null) ? JSON.parse(editedItem?.data!).licenseId : '' : '',
                data: props.editing ? editedItem?.data :'',
              }}
              
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(async () => {
                  //handleUpdate(values!)  


                  
                  let dataToSend = createDataString(values.type, values.serviceId, values.moduleId, values.licenseId)
                  await props.addItem(values.name, values.count, values.amountValue, values.amountExpression, values.vatType, values.vatRate, values.unit, values.unitPriceValue, values.unitPriceExpression, values.accountingType, dataToSend)
                  props.setFieldValue('temp', Math.random())
                  setSubmitting(false);
                  handleCloseMain();
                }, 1);
              }}
              
              
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                

                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                 
                  <Form.Row>
                    <FormGroup as={Col} md={2} controlId="type">
                      <FormLabel>Typ</FormLabel>
                      <FormControl as="select" 
                        name="type"
                        value={values.type!}
                        onChange={async (e) => {await handleChange(e); setFieldValue('data', createDataString(e.target.value, values.serviceId, values.moduleId, values.licenseId))}}
                        isValid={touched.type && !errors.type} 
                        isInvalid={!!errors.type}
                        >
                          <option value="fixed">Ručně zadaná položka</option>
                        </FormControl>
                      <Form.Control.Feedback type="invalid">
                        {errors.type}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}  md={2} controlId="name">
                      <FormLabel>Název</FormLabel>
                      <FormControl 
                        type="text"
                        name="name"
                        value={values.name!}
                        onChange={handleChange}
                        isValid={touched.name && !errors.name} 
                        isInvalid={!!errors.name}
                        autoFocus={true}
                      />
                      <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="Vložit proměnou " size="sm"> 
                        <Dropdown.Item onClick={() => {setFieldValue('name', values.name+' {{Month}}/{{Year}}')}}>Měsíc/Rok</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setFieldValue('name', values.name+' {{Year}}/{{Month}}')}}>Rok/Měsíc</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setFieldValue('name', values.name+' {{Month}}')}}>Měsíc</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setFieldValue('name', values.name+' {{Year}}')}}>Rok</Dropdown.Item>
                      </DropdownButton>  

                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col}  md={1} controlId="count">
                      <FormLabel>Množství</FormLabel>
                      <FormControl 
                        type="number"
                        name="count"
                        value={values.count!}
                        onChange={async(e) => {await handleChange(e); setFieldValue('amountValue', (Number(e.target.value)*Number(values.unitPriceValue)))   }}
                        isValid={touched.count && !errors.count} 
                        isInvalid={!!errors.count}
                      />
                    
                      <Form.Control.Feedback type="invalid">
                        {errors.count}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="unit">
                      <FormLabel>Jednotka</FormLabel>
                      
                      <FormControl as="select" 
                        name="unit"
                        value={values.unit!}
                        onChange={handleChange}
                        isValid={touched.unit && !errors.unit} 
                        isInvalid={!!errors.unit}
                        >
                        {unitsSelect}
                      </FormControl>
                      <Form.Control.Feedback type="invalid">
                        {errors.unit}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="unitPriceValue">
                      <FormLabel>Jedn. cena</FormLabel>
                      <FormControl 
                        type="number"
                        name="unitPriceValue"
                        value={values.unitPriceValue!}
                        onChange={async(e) => {await handleChange(e); setFieldValue('amountValue', (Number(e.target.value)*Number(values.count)))   }}
                        isValid={touched.unitPriceValue && !errors.unitPriceValue} 
                        isInvalid={!!errors.unitPriceValue}
                      />
                    
                      <Form.Control.Feedback type="invalid">
                        {errors.unitPriceValue}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="amountValue">
                      <FormLabel>Celkem</FormLabel>
                      <FormControl 
                        type="number"
                        name="amountValue"
                        value={values.amountValue!}
                        onChange={async(e) => {await handleChange(e); if (Number(values.count) !== 0) {setFieldValue('unitPriceValue', (Number(e.target.value)/Number(values.count))) }   }}
                        isValid={touched.amountValue && !errors.amountValue} 
                        isInvalid={!!errors.amountValue}
                      />
                    
                      <Form.Control.Feedback type="invalid">
                        {errors.amountValue}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="vatType">
                      <FormLabel>Typ DPH</FormLabel>
                      <FormControl as="select" 
                        name="vatType"
                        value={values.vatType!}
                        onChange={async(e) => { await handleChange(e); setFieldValue('vatRate', Number(vatTypes.find(vt => vt.value === e.target.value)?.vatRate) ); }}
                        isValid={touched.vatType && !errors.vatType} 
                        isInvalid={!!errors.vatType}
                        >
                          
                          {vatTypesSelect}
                        </FormControl>
                      <Form.Control.Feedback type="invalid">
                        {errors.vatType}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="vatRate">
                      <FormLabel>Sazba DPH</FormLabel>
                      <FormControl 
                        type="number"
                        name="vatRate"
                        value={values.vatRate!}
                        onChange={handleChange}
                        isValid={touched.vatRate && !errors.vatRate} 
                        isInvalid={!!errors.vatRate}
                      />
                    
                      <Form.Control.Feedback type="invalid">
                        {errors.vatRate}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="accountingType">
                      <FormLabel>Účtování</FormLabel>
                      <FormControl 
                        type="text"
                        name="accountingType"
                        value={values.accountingType!}
                        onChange={handleChange}
                        isValid={touched.accountingType && !errors.accountingType} 
                        isInvalid={!!errors.accountingType}
                      />
                    
                      <Form.Control.Feedback type="invalid">
                        {errors.accountingType}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} md={1} controlId="submit">
                      <Button type="submit" variant="success" disabled={!(isValid)} style={{height: '100%'}} block>{props.editing ? 'Upravit položku':'Přidat položku'}</Button>
                    </FormGroup>


                  </Form.Row>
                  {error}
                  

                    


                </Form>

              )}
            </Formik>  
            
          </div>
          </>}
          </>



return ( 
  <>
  {props.editing 
    ? 
    <Button variant='outline-primary' size="sm" onClick={() => {startSequence()}} >Upravit</Button>
    :
    <Button variant='success' size="sm" onClick={() => {startSequence()}} >+ Přidat položku</Button>  
    }
  
    {ReactDOM.createPortal(
    <Alert show={false} variant="info" tabIndex={0}>
      <Alert.Heading>{props.editing ? 'Úprava položky šablony' : 'Přidání položky šablony'} </Alert.Heading>
      {contactForm}
    </Alert>,
          document.body
    )}

    <Modal 
      show={insertNewContact} 
      //show={false}
      onHide={handleCloseMain} 
      backdrop="static"
      //scrollable={true}
      dialogClassName="modal-90wf"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editing ? 'Úprava položky šablony' : 'Přidání položky šablony'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>

    
  </>
)

}

export default withRouter(observer(InvoicesPeriodicTemplatesItemAdd))
