import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { Invoices } from './invoices.store'
import { InvoicesPeriodicTemplates } from './invoicesPeriodicTemplates.store';
//import { InvoicesPeriodicTemplatesNewEdit } from './invoicesPeriodicTemplatesNew.store';


import { DialsInvoicesPaymentTerms } from './dialsInvoicesPaymentTerms.store'
//import { DialsInvoicesVatRoundingTypes } from './dialsInvoicesVatRoundingTypes.store'

import { Helpers } from './helpers.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
//import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootInvoicesStore = types
  .model({
    
    invoices: types.array(Invoices),
    invoicesPeriodicTemplates: types.array(InvoicesPeriodicTemplates),
    invoicesPeriodicTemplatesNewEdit: types.array(InvoicesPeriodicTemplates),
    invoicesFromTemplate: types.array(Invoices),

    dialsInvoicesPaymentTerms: types.array(DialsInvoicesPaymentTerms),
    dialsInvoicesVatRoundingTypes: types.array(types.string),
    //dialsInvoicesVatRoundingTypes: types.array(DialsInvoicesVatRoundingTypes),
    dialsInvoicesUnits: types.array(types.string),
    dialsInvoicesTypes: types.array(types.string),
    

    
    helpers: types.array(Helpers),

    
  })
  .views(self => ({
    

  }))
  .actions(self => ({
    fetchInvoicesFromTemplate: flow(function* fetchProjects(pageNumber:number, templateId:string) {
      try {
        
        self.invoicesFromTemplate.clear()
        let filter:string = ''
        let order:string = ''

        //let filterAllways:string = '((invoiceType~eq~'+filterSet+'))'
        //filterAllways = ''

        
        filter = getFilterStringToBE(localStorage.getItem('invoicesListFromTemplateSearchArray'))

        if (filter !== '') {
          //filter = filter+'~and~'+filterAllways
        }
        else {
          //filter = filterAllways
        }
        //console.log(filter)

        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'DateOfIssue') order = 'DateOfIssue~asc'
        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'DateOfIssueDesc') order = 'DateOfIssue~desc'

        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'InvoiceNumber') order = 'InvoiceNumber~asc'
        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'InvoiceNumberDesc') order = 'InvoiceNumber~desc'

        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'Total') order = 'Total~asc'
        if (localStorage.getItem('invoicesListFromTemplateOrderBy') === 'TotalDesc') order = 'Total~desc'

        const page = pageNumber
        const pagesize = 9999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_INVOICES_PERIODIC_TEMPLATES')+'/'+templateId+'/invoices',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastInvoicesListFromTemplateTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastInvoicesListFromTemplateTotalCount') === '0') {
          self.invoicesFromTemplate.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.invoicesFromTemplate,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst faktury. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    
    // PERIODICKÁ FAKTURACE - PŘÍPRAVA EDICE ŠABLONY
    prepareInvoicesPeriodicTemplatesToEdit: flow(function* fetchProjects(templateId:string) {
      try {
        self.invoicesPeriodicTemplatesNewEdit.clear()
          // eslint-disable-next-line      
          const accessToken = yield getToken()
        
          let newData:any = self.invoicesPeriodicTemplates.find(inv => inv.id === templateId)
          
          self.invoicesPeriodicTemplatesNewEdit.push(newData)
          //self.submissions.clear()
          //applySnapshot(self.submissions,cl) 

    } catch (error) {
      console.error('Nepodařilo se vložit novou šablonu. Může dojít k odhlášení!', error)
    }
    }
    ),
    
    // PERIODICKÁ FAKTURACE - NOVÁ ŠABLONA
    addInvoicesPeriodicTemplatesNew: flow(function* fetchProjects() {
      try {
        self.invoicesPeriodicTemplatesNewEdit.clear()
          // eslint-disable-next-line      
          const accessToken = yield getToken()
        
          let newData = 
            {
              id: 'NEW',
              customerId: '',
              supplierId: null,
              userId: '',
              name: '',
              /*header: {
                
              },*/
              accountNumber: null,
              businessName: '',
              
              texts: [
                {
                  id: 'NEW1',
                  text: 'Fakturujeme Vám období {{Month}}/{{Year}}',
                  type: 'above',
                  documentType: 'Z'
                },
                {
                  id: 'NEW2',
                  text: 'Děkujeme za platbu zálohové faktury za období {{Month}}/{{Year}}:',
                  type: 'above',
                  documentType: 'P'
                },
                {
                  id: 'NEW3',
                  text: 'Zasíláme konečný doklad za období {{Month}}/{{Year}}',
                  type: 'above',
                  documentType: 'K'
                },
                {
                  id: 'NEW4',
                  text: 'Děkujeme!',
                  type: 'under',
                  documentType: 'Z'
                },
                {
                  id: 'NEW5',
                  text: 'Děkujeme!',
                  type: 'under',
                  documentType: 'P'
                },
                {
                  id: 'NEW6',
                  text: 'Děkujeme!',
                  type: 'under',
                  documentType: 'K'
                },
              ],
              emailAddressesInvoice: [],
              emailAddressesNotification: [],
              /*period: {
                
              },*/
              proformaEmailContent: {
                subject: '',
                plainText: '',
                htmlText: ''
              },
              taxEmailContent: {
                subject: '',
                plainText: '',
                htmlText: ''
              },
              items: [
                /*
                {
                  id: 'NEW1',
                  name: 'Položka 1',
                  count: 2,
                  amount: {
                    value: 2000,
                    expression: ''
                  },
                  vatType: 'Z',
                  vatRate: 21,
                  unit: 'ks',
                  unitPrice: {
                    value: 1000,
                    expression: ''
                  },
                  accountingType: 'X',
                  rank: 1
                }*/
              ],
              //createParameters: '',
              //createParametersAfterPayment: '',
              //isActive: false,
              //checked: false,
              //files: []
            }
          


          self.invoicesPeriodicTemplatesNewEdit.push(newData)
          //self.submissions.clear()
          //applySnapshot(self.submissions,cl) 

    } catch (error) {
      console.error('Nepodařilo se vložit novou šablonu. Může dojít k odhlášení!', error)
    }
    }
    ),


    // PERIODICKÁ FAKTURACE - SEZNAM ŠABLON
    fetchInvoicesPeriodicTemplatesList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = 'BusinessName~asc'

        //let filterAllways:string = '((invoiceType~eq~'+filterSet+'))'
        //let filterAllways = ''

        
        filter = getFilterStringToBE(localStorage.getItem('invoicesPeriodicTemplatesListSearchArray'))

        if (filter !== '') {
          //filter = filter+'~and~'+filterAllways
        }
        else {
          //filter = filterAllways
        }
        //console.log(filter)
        
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'BusinessName') order = 'BusinessName~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'BusinessNameDesc') order = 'BusinessName~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'AccountNumber') order = 'AccountNumber~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'AccountNumberDesc') order = 'AccountNumber~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'NameDesc') order = 'Name~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Period.startDate') order = 'Period.startDate~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Period.startDateDesc') order = 'Period.startDate~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Period.frequency') order = 'Period.frequency~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Period.frequency') order = 'Period.frequency~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Header.totalWithoutVat') order = 'Header.totalWithoutVat~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Header.totalWithoutVatDesc') order = 'Header.totalWithoutVat~desc'

        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Header.total') order = 'Header.total~asc'
        if (localStorage.getItem('invoicesPeriodicTemplatesListOrderBy') === 'Header.totalDesc') order = 'Header.total~desc'
        
        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_INVOICES_PERIODIC_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastInvoicesPeriodicTemplatesListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastInvoicesPeriodicTemplatesListTotalCount') === '0') {
          self.invoicesPeriodicTemplates.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.invoicesPeriodicTemplates,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst šablony periodických faktury. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchInvoicesList: flow(function* fetchProjects(pageNumber:number, filterSet:string, fromCustomer?:boolean) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        // Nastavení práv
        //eslint-disable-next-line
        const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
        
        let filter:string = ''
        let order:string = ''

        let filterAllways:string = '((invoiceType~eq~'+filterSet+'))'
        let url = getUrl('URL_INVOICES')
        
        if (fromCustomer === true) {
          //Následující filterAllways zakázat a url povolit po vytvoření endpointu pro zákazníky
          filterAllways = '((invoiceType~eq~'+filterSet+')~and~(customerId~eq~'+localStorage.getItem('openedCustomerId')!+'))'
          //url = getUrl('URL_CUSTOMER_INVOICES')+localStorage.getItem('openedCustomerId')+'/invoices'
        }
        
        
        filter = getFilterStringToBE(localStorage.getItem('invoicesListSearchArray'))

        if (filter !== '') {
          filter = filter+'~and~'+filterAllways
        }
        else {
          filter = filterAllways
        }
        //console.log(filter)

        if (localStorage.getItem('invoicesListOrderBy') === 'DateOfIssue') order = 'DateOfIssue~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'DateOfIssueDesc') order = 'DateOfIssue~desc'

        if (localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumber') order = 'InvoiceNumber~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumberDesc') order = 'InvoiceNumber~desc'

        if (localStorage.getItem('invoicesListOrderBy') === 'Total') order = 'Total~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'TotalDesc') order = 'Total~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastInvoicesListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastInvoicesListTotalCount') === '0') {
          self.invoices.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.invoices,cl) 

        } 
        
        self.invoices.forEach((invoice) => {
          axios.get(getUrl('URL_INVOICES')+'/'+invoice.id+'/pdf',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            }
          }).then(response => {
            //console.log(response.data)
            //return response.data
            invoice.addDocumentsFromData(response.data)
          }).catch(error => {
            errorEvaluation(error)
          });



        })
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst faktury. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchInvoiceById: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
       const cl = yield axios.get(getUrl('URL_INVOICES')+'/'+id+'?include=true',{
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }).then(response => {
        //console.log(response.data)
        return response.data
        
      }).catch(error => {
        errorEvaluation(error)
      });

        // Najdeme index existující faktury
        const existingIndex = self.invoices.findIndex((inv) => inv.id === id);

        // Pokud záznam existuje, nahradíme jej, jinak přidáme nový
        const invoicesMerge = [...self.invoices];
        if (existingIndex !== -1) {
          invoicesMerge[existingIndex] = cl; // Nahradí existující záznam
        } else {
          invoicesMerge.push(cl); // Přidá nový záznam na konec, pokud neexistuje
        }

        applySnapshot(self.invoices, invoicesMerge);



        //const texts = yield axios.get(getUrl('URL_INVOICES')+'/proforma/0F21109E-51B0-4A17-82B2-6E3B93C7529B/texts',{
        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/texts',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addTextsFromData(response.data)
        }).catch(error => {
          errorEvaluation(error)
        });

        //const texts = yield axios.get(getUrl('URL_INVOICES')+'/proforma/0F21109E-51B0-4A17-82B2-6E3B93C7529B/texts',{
        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/email',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addEmailsFromData(response.data.items)
        }).catch(error => {
          errorEvaluation(error)
        });

        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/pdf',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addDocumentsFromData(response.data)
        }).catch(error => {
          errorEvaluation(error)
        });


       /*
        const cl = yield axios.get(getUrl('URL_INVOICES')+'/'+id+'?include=true',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const invoicesMerge:any = self.invoices.filter(inv => inv.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.invoices,invoicesMerge)
       */   
    } catch (error) {
      console.error('Failed to fetch invoice', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),


    addInvoiceFromData: flow(function* fetchProjects(invoiceData:any) {
      try {
        //self.submissions.clear()
          // eslint-disable-next-line      
          const accessToken = yield getToken()
        
          self.invoices.push(invoiceData)
          //self.submissions.clear()
          //applySnapshot(self.submissions,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchDialsInvoicesPaymentTerms: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesPaymentTerms.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_PAYMENT_TERMS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesPaymentTermsTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesPaymentTermsTotalCount') === '0') {
          self.dialsInvoicesPaymentTerms.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesPaymentTerms,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst platební podmínky. Může dojít k odhlášení!', error)
    } 
    }),

    fetchDialsInvoicesVatRoundingTypes: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesVatRoundingTypes.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_VAT_ROUNDING_TYPES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesVatRoundingTypesTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesVatRoundingTypesTotalCount') === '0') {
          self.dialsInvoicesVatRoundingTypes.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesVatRoundingTypes,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst způsoby zaokrouhlení DPH. Může dojít k odhlášení!', error)
    } 
    }),

    fetchDialsInvoicesUnits: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesUnits.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_UNITS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesUnitsTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesUnitsTotalCount') === '0') {
          self.dialsInvoicesUnits.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesUnits,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst způsoby zaokrouhlení DPH. Může dojít k odhlášení!', error)
    } 
    }),

    fetchDialsInvoicesTypes: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesTypes.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_TYPES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesTypesTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesTypesTotalCount') === '0') {
          self.dialsInvoicesTypes.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesTypes,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst Typy dokladů. Může dojít k odhlášení!', error)
    } 
    }),

    

  }))

export type RootInvoicesStoreType = Instance<typeof RootInvoicesStore>
export type InvoicesList = Instance<typeof Invoices>
