import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../../Constants/Constants'
import axios from 'axios';


import { useInvoicesStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface InvoicesPeriodicTemplatesActivateProps {
  templateId: string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  validateOnMount : boolean
}

const InvoicesPeriodicTemplatesActivate: React.FC<InvoicesPeriodicTemplatesActivateProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)  
  
  // Připojení store
  const rootInvoicesStore = useInvoicesStore()
  const item = rootInvoicesStore.invoicesPeriodicTemplates.find(item => item.id === props.templateId)
  
  // Otevírání a zavírání modálního okna Změny adresy
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  

  // Funkce pro změnu adresy
  const handleUpdate = (startDateData:string, endDateData:string, frequencyData:string) => {
    setError(null)
    setLoading(true);

    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      // Zavolání API
      await axios.post(getUrl('URL_INVOICES_PERIODIC_TEMPLATES')+'/'+props.templateId+'/activate', { templateId: props.id, startDate: startDateData, frequency: frequencyData, endDate: endDateData === '' ? null : endDateData  }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          
          rootInvoicesStore.fetchInvoicesPeriodicTemplatesList(Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')))
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()

  }

  // Schéma validace formuláře
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // Přidá 1 den
  tomorrow.setHours(0, 0, 0, 0); // Nastavení dnešního data na začátek dne

  const schemaUpdate = Yup.object().shape({
    startDate: Yup.date()
    .required('Datum začátku musí být vyplněno!')
    .min(tomorrow, 'Datum začátku musí být alespoň zítřejší!'),
    frequency: Yup.string()
    .required('Frekvence fakturace musí být vyplněna!'),
    
  })   
  return (
    <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {handleShow()}}>Aktivovat</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail šablony' onClick={() => {handleShow()}}>Aktivovat</span>
      </>
    :
    <>
      <Button variant='success' size="sm" style={{}} onClick={() => {handleShow()}} >Aktivovat šablonu</Button>
    </>
    }

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Aktivace šablony periodické fakturace</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Aktivace proběhla v pořádku!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              validateOnMount={true}
              initialValues={{ 
                startDate: item?.period.startDate,
                endDate: item?.period.endDate,
                frequency: item?.period.frequency,
              
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.startDate!, values.endDate!, values.frequency!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="startDate">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Začátek fakturace</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="date"
                                        name="startDate"
                                        value={values.startDate!}
                                        onChange={handleChange}
                                        isValid={touched.startDate && !errors.startDate} 
                                        isInvalid={!!errors.startDate}
                                      />
                                      
                                      <Form.Control.Feedback type="invalid">
                                        {errors.startDate}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                <FormGroup controlId="frequency">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Frekvence</FormLabel>
                                    <Col>
                                      <FormControl as="select" 
                                        name="frequency"
                                        value={values.frequency!}
                                        onChange={handleChange}
                                        isValid={touched.frequency && !errors.frequency} 
                                        isInvalid={!!errors.frequency}
                                        >
                                          <option value="">--- Vyberte ---</option>
                                          <option value="day">Den</option>
                                          <option value="week">Týden</option>
                                          <option value="month">Měsíc</option>
                                          <option value="quarter">Čtvrtletí</option>
                                          <option value="halfYear">Půlrok</option>
                                          <option value="year">Rok</option>
                                          <option value="lastDayOfMonth">Poslední den v měsíci</option>
                                        </FormControl>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.frequency}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                                
                                <FormGroup controlId="endDate">
                                  <Form.Row>
                                    <FormLabel column lg={3}>Konec fakturace</FormLabel>
                                    <Col>
                                      <FormControl 
                                        type="date"
                                        name="endDate"
                                        value={values.endDate!}
                                        onChange={handleChange}
                                        isValid={touched.endDate && !errors.endDate} 
                                        isInvalid={!!errors.endDate}
                                      />
                                      
                                      <Form.Control.Feedback type="invalid">
                                        {errors.endDate}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Row>  
                                </FormGroup>
                     
                             
                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Aktivuji šablonu...' : 'Aktivovat šablonu'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(InvoicesPeriodicTemplatesActivate))
