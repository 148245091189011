import React, { useState, useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { RouterSwitch } from "./Routes"
import { Alert, Modal } from 'react-bootstrap'

import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css'
//import 'mobx-react-lite/batchingOptOut'
//import 'mobx-react-lite/batchingForReactDom'
import { observer } from 'mobx-react'
//import withAppInsights from './AppInsights'

function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>      
      <Router>        
        <RouterSwitch />
      </Router>
      <Modal 
          show={!isOnline}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
            <Modal.Header>
              <Modal.Title>Chyba připojení</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="danger">
              Nejste připojeni k internetu. Připojte se prosím k internetu a zkuste to znovu.
              </Alert>
            </Modal.Body>
            
          
          </Modal>    
    </div>
  );
}
 
export default observer(App)