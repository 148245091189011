import { types, destroy } from 'mobx-state-tree'


//Odběratel
const Subscriber = types.model('Subscriber',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  
  companyRegistrationNumber: types.maybeNull(types.string),
  taxIdentificationNumber: types.maybeNull(types.string),

  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),

  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  
})

//Odběratel
const Recipient = types.model('Recipient',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  
  companyRegistrationNumber: types.maybeNull(types.string),
  taxIdentificationNumber: types.maybeNull(types.string),

  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),

  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  
})

//Celková cena položky
const Amount = types.model('Amount',{
  value: types.optional(types.number, 0),
  expression: types.optional(types.string, ''),
})

//Celková cena položky
const UnitPrice = types.model('UnitPrice',{
  value: types.optional(types.number, 0),
  expression: types.optional(types.string, ''),
})

//Položky
const Items = types.model('Items',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),

  count: types.optional(types.number, 0),
  amount: types.optional(Amount, {value: 0, expression: ''}),

  vatType: types.optional(types.string, ''),
  vatRate: types.optional(types.number, 0),

  unit: types.optional(types.string, ''),
  unitPrice: types.optional(UnitPrice, {value: 0, expression: ''}),

  accountingType: types.optional(types.string, ''),
  data: types.maybeNull(types.string),
  rank: types.optional(types.number, 0),
  new: types.optional(types.boolean, false),
  
})
.actions(self => ({
  updateItem (name:string, count:number, amountValue:number, amountExpression:string, vatType:string, vatRate:number, unit:string, unitPriceValue:number, unitPriceExpression:string, accountingType:string, data:string) {
    self.name = name
    self.count = count
    self.amount = {value: amountValue, expression: amountExpression}
    self.vatType = vatType
    self.vatRate = vatRate
    self.unit = unit
    self.unitPrice = {value: unitPriceValue, expression: unitPriceExpression}
    self.accountingType = accountingType
    self.data = data
  }
}))


//Texty
const Texts = types.model('Texts',{
  id: types.optional(types.string, ''),
  invoiceId: types.optional(types.string, ''),
  text: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  documentType: types.maybeNull(types.string),
  createDate: types.optional(types.string, ''),
  source: types.maybeNull(types.string),

})

//Soubory
const Files = types.model('Files',{
  id: types.optional(types.string, ''),
  documentType: types.optional(types.string, ''),
  fileName: types.optional(types.string, ''),
})
.views(self => ({

}))

//Perioda
const Period = types.model('Period',{
  startDate: types.optional(types.string, ''),
  endDate: types.maybeNull(types.string),
  frequency: types.optional(types.string, ''),
})

//Proforma - obsah e-mailu
const ProformaEmailContent = types.model('ProformaEmailContent',{
  subject: types.optional(types.string, ''),
  plainText: types.optional(types.string, ''),
  htmlText: types.optional(types.string, ''),
})

//Daňový doklad - obsah e-mailu
const TaxEmailContent = types.model('TaxEmailContent',{
  subject: types.optional(types.string, ''),
  plainText: types.optional(types.string, ''),
  htmlText: types.optional(types.string, ''),
})


//Texty
const Header = types.model('Header',{
  invoiceType: types.optional(types.string, ''),
  invoiceTypeScope: types.optional(types.string, ''),

  subscriberId: types.maybeNull(types.string),
  subscriber: types.maybeNull(types.frozen(Subscriber)),

  recipientId: types.maybeNull(types.string),
  recipient: types.maybeNull(types.frozen(Recipient)),

  dueDateIncrement: types.optional(types.number, 0),
  variableSymbol: types.maybeNull(types.string),
  specificSymbol: types.maybeNull(types.string),

  vatRoundingType: types.maybeNull(types.string),
  isRoundingTotal: types.optional(types.boolean, false),
  paymentTerms: types.optional(types.string, ''),
  note: types.maybeNull(types.string),
  data: types.maybeNull(types.string),

  totalVat: types.optional(types.number, 0),
  totalWithVat: types.optional(types.number, 0),
  total: types.optional(types.number, 0),
  totalWithoutVat:  types.optional(types.number, 0),


})
.views(self => ({

  get invoiceTypeText() {
    let text: string = ''
    if (self.invoiceType === 'T') text = 'Daňový doklad'
    if (self.invoiceType === 'P') text = 'Proforma'
    return text
  }, 
  
}))



export const InvoicesPeriodicTemplates = types
  .model({
    id: types.optional(types.string, ''),

    customerId: types.optional(types.string, ''),
    supplierId: types.maybeNull(types.string),
    userId: types.optional(types.string, ''),

    name: types.optional(types.string, ''),

    header: types.optional(Header,{}),

    accountNumber: types.maybeNull(types.string),
    businessName: types.optional(types.string, ''),

    

    items: types.optional(types.array(Items),[]),
    texts: types.optional(types.array(Texts),[]),

    emailAddressesInvoice: types.optional(types.array(types.string),[]),
    emailAddressesNotification : types.optional(types.array(types.string),[]),

    period: types.optional(Period,{}),
    proformaEmailContent: types.optional(ProformaEmailContent,{}),
    taxEmailContent: types.optional(TaxEmailContent,{}),

    createParameters: types.optional(types.string, ''),
    createParametersAfterPayment: types.optional(types.string, ''),

    isActive: types.optional(types.boolean, false),
    checked: types.optional(types.boolean, false),

    
    files: types.optional(types.array(Files),[]),
    helpState: types.optional(types.string, ''),

  })
  .views(self => ({
    get infoText() {
      return self.accountNumber + ' - ' + self.businessName
      
    }, 

    get textsAbove() {
      return self.texts.filter(item => item.type === 'above').sort((a, b) => a.createDate > b.createDate ? 1 : -1)
    },
    get textsUnder() {
      return self.texts.filter(item => item.type === 'under').sort((a, b) => a.createDate > b.createDate ? 1 : -1)
    },
    get invoiceTypeText() {
      let text: string = ''
      if (self.header.invoiceType === 'T') text = 'Daňový doklad'
      if (self.header.invoiceType === 'P') text = 'Proforma'


      return text === '' ? self.header.invoiceType : text
    }, 
    get totalWithoutVatFromItems() {
      return self.items.reduce((sum, item) => sum + item.amount.value, 0)
    }
  }))
  .actions(self => ({
    selectItem(newState: boolean) {
      self.checked = newState
    },
    addTextsFromData(data: any) {
      self.texts = data
    },
    addEmailsInvoiceFromData(data: any) {
      //console.log(data)
      self.emailAddressesInvoice = data
    },
    addEmailsNotificationFromData(data: any) {
      //console.log(data)
      self.emailAddressesNotification = data
    },
    addFilesFromData(data: any) {
      self.files = data
    },
    addSubscriberFromData(data: any) {

      self.header.subscriber = data
    },
    addEmailAdressInvoice(email: string) {
      self.emailAddressesInvoice.push(email)
    },
    addEmailAdressNotification(email: string) {
      self.emailAddressesNotification.push(email)
    },
    removeEmailAdressInvoice(index: number) {
      self.emailAddressesInvoice.splice(index, 1)
    },
    removeEmailAdressNotification(index: number) {
      self.emailAddressesNotification.splice(index, 1)
    },

    addTextToNew(text: string, type: string, documentType: string) {
      let id:string = 'NEW'+(self.texts.length+1).toString()
      self.texts.push({id: id, text: text, type: type, documentType: documentType })
    },
    removeText(id: string) {
      const item = self.texts.find(item => item.id === id)
      if (item) {
        destroy(item) // MST-friendly způsob odstranění položky
      }
    },
    addItem(name:string, count:number, amountValue:number, amountExpression:string, vatType:string, vatRate:number, unit:string, unitPriceValue:number, unitPriceExpression:string, accountingType:string, data:string) {
        // Najděte nejvyšší rank v poli self.items
        const maxRank = self.items.length > 0 
        ? Math.max(...self.items.map(item => item.rank)) 
        : 0;
      self.items.push({id: 'NEW'+(maxRank+1).toString(), name: name, count: count, amount: {value: amountValue, expression: amountExpression}, vatType: vatType, vatRate: vatRate, unit: unit, unitPrice: {value: unitPriceValue, expression: unitPriceExpression}, accountingType: accountingType, data: data, rank: maxRank+1, new: true})
    },
    
    removeItem(id: string) {
      const item = self.items.find(item => item.id === id)
      if (item) {
        destroy(item) // MST-friendly způsob odstranění položky
      }
    },


    setHelpSate(newState: string) {
      self.helpState = newState
    }

  }))
