import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getUser, getToken, getUrl } from '../../../Utils/Common'
//eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Card, CardDeck, Table, Nav, Tabs, Tab } from 'react-bootstrap'

import { useInvoicesStore } from '../../../store'
import { observer } from 'mobx-react'

import { getToken, getUrl } from '../../../Utils/Common'

//eslint-disable-next-line
import { confirm } from "../../../Utils/Confirmation";
import axios from 'axios';

import moment from 'moment'
import NumberFormat from 'react-number-format'
import {getConstantTranslate} from '../../../helpers/globalFunctions'
//eslint-disable-next-line
import InvoicesOpenDocument from './Invoices.OpenDocument.part'

import InvoicesDetail from '../../invoices/components/Invoices.Detail.part'

//import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

//import axios from 'axios';

//import ScrollToTopBtn from "../../../sharedComponents/scrollToTop/ScrollToTopBtn"


interface InvoicesPeriodicTemplatesDetailProps { 
  templateId?:string
  editing?:boolean,
  //supportId?:string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const InvoicesPeriodicTemplatesDetail: React.FC<InvoicesPeriodicTemplatesDetailProps & RouteComponentProps> = (props:any) => {
  
  const modalRef = React.useRef<HTMLDivElement>(null);
  
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')  || (localStorage.getItem('Billing')! === '1')) ? true : false
  //eslint-disable-next-line
  const rightsEdit:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  || (localStorage.getItem('Billing')! === '1')) ? true : false


  // Nastavení stavů pro zobrazení 
  //eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  //eslint-disable-next-line
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const [showE, setShowE] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const [activeNav, setActiveNav] = useState('items')

  const [useDefaultSubscriber, setUseDefaultSubscriber] = useState(false)

  // Připojení store
  //const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  const rootInvoicesStore = useInvoicesStore()

  // Nastavení editivaného kontaktu
  const item = rootInvoicesStore.invoicesPeriodicTemplates.find(i => i.id === props.templateId)
  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    
    if (sessionStorage.getItem('invoicesPeriodicTemplatesDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')
      
      
       rootInvoicesStore.fetchInvoicesPeriodicTemplatesList(Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage') !== 'null' ? sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('invoicesPeriodicTemplatesListSearchPage')))
       //rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('invoicesPeriodicTemplatesDetailEdited', 'N')
       
    }

    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    sessionStorage.setItem('invoicesPeriodicTemplatesDetailEdited', 'N')
    //await rootInvoicesStore.fetchInvoiceById(props.invoiceId)

    //await rootInvoicesStore.fetchInvoiceById(props.templateId)
    if (item?.header.subscriber?.id === null || item?.header.subscriber?.id === undefined) {
      await loadCustomerData(item?.customerId!)
      setUseDefaultSubscriber(true)
    }
      
  setShowDetail(true)

  rootInvoicesStore.fetchInvoicesFromTemplate(1,item?.id!)
  await setLoading(false)

}

// Funkce pro načtení dat o ZÁKAZNÍKOVI
const loadCustomerData = async (customerId:string) => {
  await setLoadingData(true)
  
  // Kontrola na platnost přihlášení
  const aToken = await getToken()
  if ((aToken === null) || (aToken === undefined)) { 
    sessionStorage.setItem('automaticLogout', '1')
    props.history.push('/logout')
  }

  
  // Načtení základních informací o zákazníkovi a nastavení stavů pro indikaci
  await axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+customerId,{
    headers: {
      'Authorization': 'Bearer ' + aToken
    }  }).then(response => {
      /*
      setFieldValue('street',response.data.billingAddress.street)
      setFieldValue('houseNumber',response.data.billingAddress.houseNumber)
      setFieldValue('postalCode',response.data.billingAddress.postalCode)
      setFieldValue('city',response.data.billingAddress.city)
      setFieldValue('identificationNumber',response.data.identificationNumber)

      setFieldValue('name',response.data.name)
      setFieldValue('businessName',response.data.businessName)
      setFieldValue('accountNumber',response.data.accountNumber)
      setFieldValue('companyRegistrationNumber',response.data.companyRegistrationNumber)
      setFieldValue('taxIdentificationNumber',response.data.taxIdentificationNumber)
      setFieldValue('title',response.data.title)
      setFieldValue('firstName',response.data.firstName)
      setFieldValue('surName',response.data.surName)
      setFieldValue('titleAfter',response.data.titleAfter)
      */

      let dataToInsert = {
        id: response.data.id,
        accountNumber: response.data.accountNumber,
        businessName: response.data.businessName,
        name: response.data.name,
        
        companyRegistrationNumber: response.data.companyRegistrationNumber,
        taxIdentificationNumber: response.data.taxIdentificationNumber,

        title: response.data.title,
        firstName: response.data.firstName,
        surName: response.data.surName,
        titleAfter: response.data.titleAfter,

        street: response.data.billingAddress.street,
        houseNumber: response.data.billingAddress.houseNumber,
        identificationNumber: response.data.identificationNumber,
        postalCode: response.data.billingAddress.postalCode,
        city: response.data.billingAddress.city,
      }

      item?.addSubscriberFromData(dataToInsert)


  }).catch(error => {
    
  });



  setLoadingData(false)

}

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('invoicesListFromTemplateOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('invoicesListFromTemplateSearchPage', String(1))
        await rootInvoicesStore.fetchInvoicesFromTemplate(1, props.templateId!)
        setLoading(false);  
      })()
    }

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>

          {showDetail &&<>
          <div id='invoiceDetail'>
            {!(item?.header.note === '' || item?.header.note === null || item?.header.note === undefined) && <Alert variant='info'>{item?.header.note}</Alert>}
            <CardDeck>

              <Card className='border-left-info'>
                <Card.Header>Odběratel</Card.Header>
                <Card.Body>
                  <Badge variant={useDefaultSubscriber ? 'info':'warning'}>{useDefaultSubscriber ? 'Pro fakturaci bude vždy použita aktuální adresa zákazníka':'Pro fakturaci bude vždy použita tato UPRAVENÁ adresa'}</Badge>
                  {loadingData && <Spinner animation="border" variant="info" />}
                  <br />
                  <strong>{item?.header.subscriber?.businessName}</strong>{item?.header.subscriber?.businessName !== '' &&<br />}
                  <strong>{item?.header.subscriber?.title}{item?.header.subscriber?.title !== '' && ' '}{item?.header.subscriber?.firstName} {item?.header.subscriber?.surName}{item?.header.subscriber?.titleAfter !== '' && ' '}{item?.header.subscriber?.titleAfter}</strong>{item?.header.subscriber?.surName !== '' &&<br />}
                  <br />
                  {item?.header.subscriber?.street}&nbsp;{item?.header.subscriber?.houseNumber}{!(item?.header.subscriber?.identificationNumber === null || item?.header.subscriber?.identificationNumber === undefined || item?.header.subscriber?.identificationNumber === '') && '/'}{item?.header.subscriber?.identificationNumber}<br />
                  {item?.header.subscriber?.postalCode}&nbsp;{item?.header.subscriber?.city}<br />
                  
                  {item?.header.subscriber?.companyRegistrationNumber !== '' && <><br />IČO:&nbsp;{item?.header.subscriber?.companyRegistrationNumber}</>}
                  {item?.header.subscriber?.taxIdentificationNumber !== '' && <><br />DIČ:&nbsp;{item?.header.subscriber?.taxIdentificationNumber}</>}


                </Card.Body>
              </Card>

              <Card>
                <Card.Header>Platební informace</Card.Header>
                <Card.Body>
                  <Row><Col><strong>Var. symbol:</strong></Col><Col style={{textAlign: 'right'}}>{item?.header.variableSymbol}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Spec. symbol:</strong></Col><Col style={{textAlign: 'right'}}>{item?.header.specificSymbol}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Způsob úhrady:</strong></Col><Col style={{textAlign: 'right'}}>{getConstantTranslate(item?.header.paymentTerms!, 'value')}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  
                  <Row><Col><strong>Frekvence:</strong></Col><Col style={{textAlign: 'right'}}>{item?.period.frequency}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Začátek platnosti:</strong></Col><Col style={{textAlign: 'right'}}>{item?.period.startDate !== null ? moment(item?.period.startDate).format('DD.MM.YYYY') : 'nezadáno'}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Konec platnosti:</strong></Col><Col style={{textAlign: 'right'}}>{item?.period.endDate !== null ? moment(item?.period.endDate).format('DD.MM.YYYY') : 'nezadáno'}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Stav:</strong></Col><Col style={{textAlign: 'right'}}>{item?.isActive ? <Badge variant='success'>Aktivní</Badge>:<Badge variant='danger'>Neaktivní</Badge>}</Col></Row>
                  
                </Card.Body>
              </Card>

            </CardDeck>
            <br />
            <Row>
              <Col md={6}>
              </Col>
              <Col md={6}>
                <CardDeck>

                  <Card className='border-left-danger'>
                    <Card.Header>Celkem + rekapitulace DPH</Card.Header>
                    <Card.Body>
                      <Alert variant='secondary'>
                        <Row style={{fontSize: '20px'}}><Col><strong>Celkem:</strong></Col><Col style={{textAlign: 'right', fontWeight: 'bold', fontSize: '30px'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.header.total} /></Col></Row>
                        <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                        <Row><Col><strong>Celkem bez DPH:</strong></Col><Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.header.totalWithoutVat} /></Col></Row>
                        <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                        <Row><Col><strong>DPH:</strong></Col><Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.header.totalVat} /></Col></Row>
                        
                      </Alert>
                      

                      <Alert variant='light'>
                      <h5 style={{fontWeight: 'bold'}}>Rekapitulace DPH</h5>
                      <Row><Col style={{ fontStyle: 'italic' }}>{getConstantTranslate(item?.header.vatRoundingType!, 'text')}</Col></Row>
                      </Alert>
                    </Card.Body>
                  </Card>


                </CardDeck>
              </Col>
            </Row>
            
            


<br />


    <Tabs
      id="controlled-tab-example"
      activeKey={activeNav}
      onSelect={(k) => setActiveNav(k!)}
       className='invoice-items'
    >
      <Tab eventKey="items" tabClassName={item?.items.length === 0 ? '':'tab-with-data'} title="Položky">
      </Tab>
      <Tab eventKey="textsAbove" tabClassName={item?.textsAbove.length === 0 ? '':'tab-with-data'} title="Texty nad">
      </Tab>
      <Tab eventKey="textsUnder" tabClassName={item?.textsUnder.length === 0 ? '':'tab-with-data'} title="Texty pod">
      </Tab>
      <Tab eventKey="emailsInvoices" tabClassName={item?.emailAddressesInvoice.length === 0 ? '':'tab-with-data'} title="E-maily - fakturace">
      </Tab>
      <Tab eventKey="emailsNotifications" tabClassName={item?.emailAddressesNotification.length === 0 ? '':'tab-with-data'} title="E-maily - notifikace">
      </Tab>
      
      <Tab eventKey="invoices" tabClassName={rootInvoicesStore.invoicesFromTemplate.length === 0 ? '':'tab-with-data'} title="Vytvořené faktury">
      </Tab>
    </Tabs>



            <Nav justify variant="tabs"  style={{fontSize: '20px', display: 'none'}} defaultActiveKey='items' >
              <Nav.Item>
                <Nav.Link eventKey="items" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('items') }}>Položky</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="textsAbove" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('textsAbove') }}>Texty nad</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="textsUnder" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('textsUnder') }}>Texty pod</Nav.Link>
              </Nav.Item>
            </Nav>

            <div style={{border: '1px solid #C0D1E1', padding: '5px', borderTopWidth: '0px'}}>
            
            {activeNav === 'textsAbove' && <>
              {item?.textsAbove.length === 0 && <>Doklad neobsahuje žádné texty nad.</>}
              {item?.textsAbove.map(textAbove => (
                <Card key={textAbove.id} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <Badge variant='secondary'>{textAbove.documentType === 'Z' ? 'Zálohová faktura': textAbove.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textAbove.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'textsUnder' && <>
              {item?.textsUnder.length === 0 && <>Doklad neobsahuje žádné texty pod.</>}
              {item?.textsUnder.map(textUnder => (
                <Card key={textUnder.id}  style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <Badge variant='secondary'>{textUnder.documentType === 'Z' ? 'Zálohová faktura': textUnder.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textUnder.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}


            {activeNav === 'items' && <>
            <Table hover size='sm' style={{marginTop: '10px'}}>
              <thead>
                <tr className="jsw-table-head">
                  <th className="jsw-table-head" title=''>Název položky</th>
                  <th className="jsw-table-head" title='Celková částka dokladu včetně daně' style={{textAlign: 'right'}}>Jednotková cena</th>
                  <th className="jsw-table-head" title='Množství' style={{textAlign: 'right'}}>Množství</th>
                  <th className="jsw-table-head row-slim" title='Jednotka' ></th>
                  <th className="jsw-table-head" title='Celková částka položky bez daně' style={{textAlign: 'right'}}>Celkem</th>
                  <th className="jsw-table-head row-slim" title='Sazba DPH' style={{textAlign: 'center'}}>DPH</th>
                  <th className="jsw-table-head row-slim" title='Druh účtování'>Účtování</th>
                </tr>
              </thead>
              <tbody>
                {item?.items.map(invoiceItem => ( 
                  <tr key={invoiceItem.id}>
                    <td>{invoiceItem.name}</td>
                    <td style={{textAlign: 'right'}}>{!(invoiceItem.unitPrice.value === 0 || invoiceItem.unitPrice.value === null) ? <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.unitPrice.value} />: invoiceItem.unitPrice.expression}</td>
                    <td style={{textAlign: 'right'}}>{invoiceItem.count}</td>
                    <td>{invoiceItem.unit}</td>
                    <td style={{textAlign: 'right'}}>{!(invoiceItem.amount.value === 0 || invoiceItem.amount.value === null) ? <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.amount.value} />: invoiceItem.amount.expression}</td>
                    <td style={{textAlign: 'center'}}>{invoiceItem.vatRate}&nbsp;%</td>
                    <td>{invoiceItem.accountingType}</td>
                    
                  </tr>
                ))}
              </tbody>
            </Table>

            </>}


            {activeNav === 'emailsInvoices' && <>
              {item?.emailAddressesInvoice.length === 0 && <>Doklad neobsahuje žádné emaily.</>}
              {item?.emailAddressesInvoice.map(emails => (
                <Card key={emails} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <div dangerouslySetInnerHTML={{__html: emails!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'emailsNotifications' && <>
              {item?.emailAddressesNotification.length === 0 && <>Doklad neobsahuje žádné emaily.</>}
              {item?.emailAddressesNotification.map(emails => (
                <Card key={emails} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <div dangerouslySetInnerHTML={{__html: emails!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}


            {activeNav === 'documents' && <>
              {item?.files.length === 0 && <>Doklad neobsahuje žádné dokumenty.</>}
              {item?.files.map(doc => (
                <Card key={doc.id} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    {doc.fileName} ({doc.documentType})
                    
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'invoices' && <>

              {(loading === true) 
                    ? 
                    <><Spinner animation='border'  />&nbsp;<br /></>
                    :
                    <> 

            {rootInvoicesStore.invoicesFromTemplate.length === 0 ? <>Šablona zatím nevytvořila žádné faktury.</> : <>
            
              <Table hover size='sm' style={{marginTop: '10px'}}>
                <thead>
                  <tr className="jsw-table-head">
                    <th title='Datum vystavení dokladu' className="jsw-table-head" style={{cursor: 'pointer', width: '160px'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListFromTemplateOrderBy') === 'DateOfIssue' || localStorage.getItem('invoicesListFromTemplateOrderBy') === null || localStorage.getItem('invoicesListFromTemplateOrderBy') === undefined) ? 'DateOfIssueDesc' : 'DateOfIssue'  )}>
                      Datum vystavení
                      <div style={{float: 'right'}}>
                        {(localStorage.getItem('invoicesListFromTemplateOrderBy') === 'DateOfIssue' || localStorage.getItem('invoicesListFromTemplateOrderBy') === null || localStorage.getItem('invoicesListFromTemplateOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                        {localStorage.getItem('invoicesListFromTemplateOrderBy') === 'DateOfIssueDesc' && <i className="fas fa-chevron-circle-down"></i>}
                      </div>
                    </th>
                    <th className="jsw-table-head row-slim" title='Typ dokladu'>Typ</th>
                    <th className="jsw-table-head" title='Číslo vystaveného dokladu'  style={{cursor: 'pointer', textAlign: 'right'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListFromTemplateOrderBy') === 'InvoiceNumber') ? 'InvoiceNumberDesc' : 'InvoiceNumber'  )}>Číslo faktury
                    <div style={{float: 'right'}}>
                        {(localStorage.getItem('invoicesListFromTemplateOrderBy') === 'InvoiceNumber') &&<>&nbsp;<i className="fas fa-chevron-circle-up"></i></>}
                        {localStorage.getItem('invoicesListFromTemplateOrderBy') === 'InvoiceNumberDesc' && <>&nbsp;<i className="fas fa-chevron-circle-down"></i></>}
                      </div>
                    </th>
    
                    <th className="jsw-table-head" title='Celková částka dokladu včetně daně a zaokrouhlení dle platebních podmínek'  style={{cursor: 'pointer', textAlign: 'right'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListFromTemplateOrderBy') === 'Total') ? 'TotalDesc' : 'Total'  )}>Celkem
                    <div style={{float: 'right'}}>
                        {(localStorage.getItem('invoicesListFromTemplateOrderBy') === 'Total') && <>&nbsp;<i className="fas fa-chevron-circle-up"></i></>}
                        {localStorage.getItem('invoicesListOFromTemplaterderBy') === 'TotalDesc' && <>&nbsp;<i className="fas fa-chevron-circle-down"></i></>}
                      </div>
                    </th>
                    <th className="jsw-table-head" title='Datum splatnosti vystaveného dokladu'>Splatnost</th>
                    <th className="jsw-table-head" title='Stav, ve kterém se doklad momentálně nachází'>Stav</th>
                    <th className="jsw-table-head row-slim" title='Příznak, zda byl doklad přenesen do účetnictví'>Přeneseno</th>
                    <th className="jsw-table-head">Akce</th>
    
                    
                  </tr>
                </thead>
                <tbody>
                  {rootInvoicesStore.invoicesFromTemplate.map((item) => (
                    <tr key={item.id}>
                      <td>{moment(item.dateOfIssue).format("DD.MM.YYYY")}</td>
                      <td><Badge variant="secondary">{item.invoiceType === 'T' ? 'Faktura':'Proforma'}</Badge></td>
                      <td style={{textAlign: 'right'}}><strong>{item.invoiceNumber}</strong></td>
                      
                      <td style={{textAlign: 'right'}}><strong><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.total} /></strong></td>
                      <td>{item.dueDate && moment(item.dueDate).format("DD.MM.YYYY")}</td>
                      <td>
                        {item.isPaid === 1 ? <Badge variant='success' style={{marginRight: '5px'}}  title={'Zaplaceno '+moment(item.dateOfPayment).format("DD.MM.YYYY")}>Zaplaceno</Badge> : <Badge style={{marginRight: '5px'}} variant={item.isPaid === 0 ? 'warning' : 'danger'}>Nezaplaceno</Badge>}
                        {item.isClosed  ? <Badge variant='info' style={{marginRight: '5px'}}>Uzavřeno</Badge> : <></>}
                        </td>
                      <td>{item.isTransferredToAccounting ? 'Ano':'Ne'}</td>
                      <td>
                        <InvoicesDetail invoiceId={item.id} fromSubMenu={false} invoiceType={item.invoiceType} />
                      </td>
                      
                    </tr>
                  ))}
                  
                </tbody>
              </Table>
            
            </>}
            </>}
            

            </>}


              
            </div>

          </div>
          </>}

          </>





return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail šablony' onClick={() => {startSequence()}}>Detail šablony</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail dokladu' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
    </>
    }
  
    <Modal 
      
      scrollable
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      >
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail šablony&nbsp;<Badge variant='secondary'>{item?.name}</Badge>
          
        </div>
        <div className="showWeb">
          Detail šablony&nbsp;<Badge variant='secondary'>{item?.name}</Badge>
          
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <div ref={modalRef}> 
        {contactForm}
      </div>
      
      <div className='showWeb'>
      <Button variant="light" size='sm' style={{float: 'right', borderRadius: '0'}} onClick={() => {modalRef.current?.scrollIntoView({ behavior: 'smooth' });}}>↑ nahoru</Button>
      <div style={{clear: 'both', paddingBottom: '10px'}}></div>
      </div>

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(InvoicesPeriodicTemplatesDetail))
