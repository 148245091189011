// eslint-disable-next-line
import React, { useState }  from 'react'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl } from '../../../../Utils/Common'
// eslint-disable-next-line
// eslint-disable-next-line
import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Row, Badge, Spinner} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'
import { Formik } from 'formik'
import * as Yup from 'yup'

//import FormControl from 'react-bootstrap/FormControl'
//import FormGroup from 'react-bootstrap/FormGroup'
//import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../Constants/Constants'
// eslint-disable-next-line
//import axios from 'axios';

//import { confirm } from "../../../../Utils/Confirmation";
import Pagination from 'react-js-pagination'
import OthersOperationsDetail from './Others.Operations.Detail.part'


import { useStore } from '../../../../store'
import { observer } from 'mobx-react'

import { confirm } from '../../../../Utils/Confirmation'
//import { Formik } from 'formik'
//import * as Yup from 'yup'

//import Pagination from 'react-js-pagination'

import moment from 'moment'
import axios from 'axios'
import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

const OthersOperationsList: React.FC = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line

  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)

  const [dateTo, setDateTo] = useState(!(localStorage.getItem('operationsToDate') === null || localStorage.getItem('operationsToDate') === undefined || localStorage.getItem('operationsToDate') === '' ) ? localStorage.getItem('operationsToDate')! : moment(new Date()).subtract('months',2).format("YYYY-MM-DDTHH:mm"))

  // Nastavení pro stránkování
  const itemsPerPage = 10

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Nastavení práv pro editaci
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  

  // Načtení Store
  const rootStore = useStore()
  
  const operations = rootStore.operations.slice(indexOfFirstItem, indexOfLastItem)
  
  //Počet záznamů
  const countData:number = rootStore.operations.length

  // Seznam pozvánek do řádků tabulky
  const linesOperations = operations.map((operation) => (
    <tr key={operation.id}>
      <td onClick={() => operation.selectItem(!operation.checked)}>
        <input
          type="checkbox"
          checked={operation.checked}
          className=""
          id={'rowcheck'+operation.id}
          //onChange={() => operation.selectItem(!operation.checked)}
          onChange={() => {}}
          style={{height: '18px', width: '18px'}}
        />
      </td>
      <td>{moment(operation.startOn).format("D.M.YYYY H:mm")}</td> 
      <td>{operation.nameForMortal}</td>
      <td>{operation.userName}</td>
      <td>{operation.finishedCount}/{operation.totalCount}</td>
      <td>{moment(operation.expireOn).format("D.M.YYYY H:mm")}</td>

      <td>
        {(operation.stateLoaded === false) 
        ? 
        <Spinner animation='border' size="sm" /> 
        : 
        <>
          {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
          {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
          {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
          {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
          <Badge variant='secondary' style={{marginLeft: '5px'}}>{moment(operation.isFinished ? operation.lastActivity : new Date()).diff(operation.startOn, 'minutes')}&nbsp;min.</Badge>
        </>}
      </td>
      
      {rights &&<td>
      <div className="float-left"></div>
      <ButtonGroup>
        <OthersOperationsDetail id={operation.id} showAs='button' />
        <Button size='sm' variant='outline-secondary' onClick={() => rootStore.fetchStateForOperation(operation.id) }><i className="fas fa-sync-alt"></i></Button>

      </ButtonGroup>
      {rightsBH &&<>
        <div className="float-left"><span style={{marginLeft: '10px'}}></span></div>
        <div className="float-left">

          
        </div>
      </>}
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesOperationsCard = operations.map((operation) => (
    <div key={operation.id}>
    <Card>
      <Card.Body>
        {rights && <>
          {rightsBH &&<>  
            <div className="float-right">

            </div>
            <div className="float-right"><span style={{marginLeft: '10px'}}></span></div>
          </>}
        <div className="float-right">
        <ButtonGroup>
          <OthersOperationsDetail id={operation.id} showAs='button' />
          <Button size='sm' variant='outline-secondary' onClick={() => rootStore.fetchStateForOperation(operation.id) }><i className="fas fa-sync-alt"></i></Button>
        </ButtonGroup>
          
        </div>
        </>}
        
        <div style={{minWidth: '150px', float: 'left'}}>
        {(operation.stateLoaded === false) 
        ? 
        <Spinner animation='border' size="sm" /> 
        : 
        <>
          {operation.state === 1 && <Badge variant="primary">Probíhá</Badge>}
          {operation.state === 2 && <Badge variant="warning">Probíhá s chybou</Badge>}
          {operation.state === 3 && <Badge variant="danger">Dokončeno s chybou</Badge>}
          {operation.state === 4 && <Badge variant="success">Dokončeno</Badge>}
          <Badge variant='secondary' style={{marginLeft: '5px'}}>{moment(operation.isFinished ? operation.lastActivity : new Date()).diff(operation.startOn, 'minutes')}&nbsp;min.</Badge>
        </>}
          <br />          
          <strong>Spuštěno:</strong> {moment(operation.startOn).format("D.M.YYYY H:mm")}<br />
          <strong>Název:</strong> {operation.nameForMortal}<br />
          <strong>Uživatel:</strong> {operation.userName}<br />
          <strong>Zpracováno:</strong> {operation.finishedCount}/{operation.totalCount}<br />
          <strong>Expirace:</strong> {moment(operation.expireOn).format("D.M.YYYY H:mm")}<br />

        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   


 // Dotaz před smazáním kontaktu
 const handleOnClickDeleteOperations = () => {
  (async () => {
    if (await confirm("Opravdu chcete označené operace ("+operations.filter(op => op.checked === true).length+") smazat?",'Ano, smazat', 'Ne', {title: 'Smazání vybraných operací'})) {
      deleteOperations()
    } else {
    
    }  
  })()
}

// Funkce pro smazání kontaktu
const deleteOperations = () => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {  
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }   
    // Zavolání API
    for (let i = 0; i < operations.length; i++) {
      if (operations[i].checked) {
        await axios.delete(getUrl('URL_OPERATIONS')+'/'+operations[i].id, { headers: { 'Authorization': 'Bearer ' + aToken} }
        ).then(response => {
          // Po smazání také odebrat ze store
          
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          //setShowE(true)
        });
      }
    }

    if (error === null) {
      setShowE(false)
    } else {
      setShowE(true)
    }
    
    // Znovunačtení dat
    await rootStore.fetchOperationsList(dateTo)
    rootStore.fetchStatesForOperations()

    })()    
}

const handleClose = () => {
  setShowE(false)
  setError(null)
  sessionStorage.removeItem('autoCloseModal')
}
  

const handlePageChange = (pageNumber:number) => {
  (async () => { 
    setLoading(true);
    // Kontrola přihlášení  
    /*
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) { 
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }*/
    sessionStorage.setItem('operationsListSearchPage', String(pageNumber))
    // Předat hledanou hodnotu action pro načtení zákazníků
    setPage(pageNumber)
    //await rootInvoicesStore.fetchInvoicesList(pageNumber, props.invoiceType)
    setLoading(false);  
  })()
}

// Schéma validace formuláře
const schemaUpdate = Yup.object().shape({
  operationsToDate: Yup.string()
    .required('Vyplňte datum expirace')
})

  return (
    <>
    <Container fluid>
                <br />
                <div style={{float:'left'}}>
                <h4>Správa hromadných operací</h4>
</div>
    <div style={{float:'right'}}>
    <Formik
                    validationSchema={schemaUpdate}
                    initialValues={{ 
                      operationsToDate: dateTo
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(async () => {
                        //handleInsert(values.idLecturers!)

                        await localStorage.setItem('operationsToDate', values.operationsToDate!)
                        await setDateTo(values.operationsToDate!)
                        setLoading(true)
                        await rootStore.fetchOperationsList(values.operationsToDate!)
                        setLoading(false)
                        setSubmitting(false);
                      }, 100);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                    
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        
                        <FormGroup controlId="operationsToDate" >
                        <Form.Row>
                          <FormLabel column lg={3}>Expirace do</FormLabel>
                          <Col lg={6}>
                            <FormControl 
                              type="datetime-local"
                              name="operationsToDate"
                              value={values.operationsToDate!}
                              onChange={async (e:any) => { await handleChange(e); }}
                              //isValid={touched.educationName && !errors.educationName} 
                              //isInvalid={!!errors.educationName}
                              placeholder='datum expirace'
                              autoFocus
                            />
                            
                            <Form.Control.Feedback type="invalid">
                              {errors.operationsToDate}
                            </Form.Control.Feedback>
                          </Col>
                          <Col>
                            <Button variant="primary" size='sm' type="submit" disabled={isSubmitting || localStorage.getItem('operationsToDate') === values.operationsToDate}>
                              Změnit
                            </Button>
                          
                          </Col>
                          </Form.Row>
                        </FormGroup> 

                        
                      </Form>
                    )}
                  </Formik> 
      
      </div>  
      <div style={{clear:'both'}}></div>                            
    </Container>
    <Container fluid>
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <> 
      
      <br />
      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam operací neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesOperationsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
              <th className="jsw-table-head" style={{width: '30px'}}></th>
                <th className="jsw-table-head" title='Datum a čas spuštění operace'>Spuštěno</th>
                <th className="jsw-table-head" title='Název operace'>Název</th>
                <th className="jsw-table-head" title='Uživatel, ktrý spustil operaci'>Uživatel</th>
                <th className="jsw-table-head" title='Počet položek, které bez ohledu na chyby prošly zpracováním / celkový počet položek, které byly předány ke zpracování'>Zpracováno</th>
                <th className="jsw-table-head" title='Datum a čas kdy bude operace považována za dokončenou, bez ohledu na její průběh'>Expirace</th>
                <th className="jsw-table-head" title='Informace o průběhu operace'>Stav</th>
                
                {rights &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesOperations}
            </tbody>
          </Table>
          {operations.filter(operation => operation.checked).length > 0 && rightsBH === true &&<Button variant='danger' size='sm' onClick={() => handleOnClickDeleteOperations()}>Hromadné mazání</Button>}
          <Modal 
            show={showE} 
            onHide={handleClose} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title>Smazání operací</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Došlo k chybě.
            </Modal.Body>
          </Modal>
        </div> 
        <br />   
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('operationsListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('operationsListSearchPage')) }
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={countData}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          /> 
      </div>  
      </>
      }
      
    </Container>
    </>
  )  
  
}

export default withRouter(observer(OthersOperationsList))
