import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
//import { getUser, getToken, getUrl } from '../../../Utils/Common'
//eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Card, CardDeck, Table, Nav, Tabs, Tab } from 'react-bootstrap'

import { useInvoicesStore } from '../../../store'
import { observer } from 'mobx-react'

//eslint-disable-next-line
import { confirm } from "../../../Utils/Confirmation";


import moment from 'moment'
import NumberFormat from 'react-number-format'
import {getConstantTranslate} from '../../../helpers/globalFunctions'

import InvoicesOpenDocument from './Invoices.OpenDocument.part'

//import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

//import axios from 'axios';

//import ScrollToTopBtn from "../../../sharedComponents/scrollToTop/ScrollToTopBtn"


interface InvoicesDetailProps { 
  invoiceId?:string
  editing?:boolean,
  invoiceType?:string,
  //supportId?:string,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const InvoicesDetail: React.FC<InvoicesDetailProps & RouteComponentProps> = (props:any) => {
  
  const modalRef = React.useRef<HTMLDivElement>(null);
  
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')  || (localStorage.getItem('Billing')! === '1')) ? true : false
  //eslint-disable-next-line
  const rightsEdit:boolean = ((localStorage.getItem('BigHedgehog')! === '1') ) ? true : false


  // Nastavení stavů pro zobrazení 
  //eslint-disable-next-line 
  const [loading, setLoading] = useState(false)
  //eslint-disable-next-line
  const [error, setError] = useState(null)
  //eslint-disable-next-line
  const [showE, setShowE] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const [activeNav, setActiveNav] = useState('items')

  // Připojení store
  //const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  const rootInvoicesStore = useInvoicesStore()

  // Nastavení editivaného kontaktu
  const item = rootInvoicesStore.invoices.find(i => i.id === props.invoiceId)
  
  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    
    if (sessionStorage.getItem('invoicesDetailEdited') === 'Y') {
      //sessionStorage.setItem('supportSearchPage', '1')
      
      
       rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage') !== 'null' ? sessionStorage.getItem('invoicesListSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType)
       //rootDialsStore.removeHelper('contacts')
       sessionStorage.setItem('invoicesDetailEdited', 'N')
       
    }

    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    setLoading(true)
    sessionStorage.setItem('meetingsDetailEdited', 'N')
    //await rootInvoicesStore.fetchInvoiceById(props.invoiceId)

    await rootInvoicesStore.fetchInvoiceById(props.invoiceId)
      
  setShowDetail(true)

  //await rootStore.fetchSupportById(props.supportId)
  await setLoading(false)

}

// VYKRESLENÍ FORMULÁŘE S KONTAKTEM 
const contactForm = 
      <>

          {showDetail &&<>
          <div id='invoiceDetail'>
            {!(item?.note === '' || item?.note === null || item?.note === undefined) && <Alert variant='info'>{item?.note}</Alert>}
            <CardDeck>
              <Card className='border-left-secondary'>
                <Card.Header>Dodavatel</Card.Header>
                <Card.Body>
                  <strong>{item?.supplier?.name}</strong>{item?.supplier?.name !== '' &&<br />}
                  
                  <br />
                  {item?.supplier?.street}&nbsp;{item?.supplier?.houseNumber}{!(item?.supplier?.identificationNumber === null || item?.supplier?.identificationNumber === '' || item?.supplier?.identificationNumber === undefined ) && '/'}{item?.supplier?.identificationNumber}<br />
                  {item?.supplier?.postalCode}&nbsp;{item?.supplier?.city}<br />
                  
                  {item?.supplier?.companyRegistrationNumber !== '' && <><br />IČO:&nbsp;{item?.supplier?.companyRegistrationNumber}</>}
                  {item?.supplier?.taxIdentificationNumber !== '' && <><br />DIČ:&nbsp;{item?.supplier?.taxIdentificationNumber}</>}


                </Card.Body>
              </Card>
              <Card className='border-left-info'>
                <Card.Header>Odběratel</Card.Header>
                <Card.Body>
                  <strong>{item?.subscriber?.businessName}</strong>{item?.subscriber?.businessName !== '' &&<br />}
                  <strong>{item?.subscriber?.title}{item?.subscriber?.title !== '' && ' '}{item?.subscriber?.firstName} {item?.subscriber?.surName}{item?.subscriber?.titleAfter !== '' && ' '}{item?.subscriber?.titleAfter}</strong>{item?.subscriber?.surName !== '' &&<br />}
                  <br />
                  {item?.subscriber?.street}&nbsp;{item?.subscriber?.houseNumber}{!(item?.subscriber?.identificationNumber === null || item?.subscriber?.identificationNumber === undefined || item?.subscriber?.identificationNumber === '') && '/'}{item?.subscriber?.identificationNumber}<br />
                  {item?.subscriber?.postalCode}&nbsp;{item?.subscriber?.city}<br />
                  
                  {item?.subscriber?.companyRegistrationNumber !== '' && <><br />IČO:&nbsp;{item?.subscriber?.companyRegistrationNumber}</>}
                  {item?.subscriber?.taxIdentificationNumber !== '' && <><br />DIČ:&nbsp;{item?.subscriber?.taxIdentificationNumber}</>}


                </Card.Body>
              </Card>


            </CardDeck>
            <br />
            <CardDeck>
              <Card>
                <Card.Header>Platební informace</Card.Header>
                <Card.Body>
                  <Row><Col><strong>Var. symbol:</strong></Col><Col style={{textAlign: 'right'}}>{item?.variableSymbol}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Spec. symbol:</strong></Col><Col style={{textAlign: 'right'}}>{item?.specificSymbol}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Způsob úhrady:</strong></Col><Col style={{textAlign: 'right'}}>{getConstantTranslate(item?.paymentTerms!, 'value')}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  
                  <Row><Col><strong>Datum splatnosti:</strong></Col><Col style={{textAlign: 'right'}}>{item?.dueDate !== null ? moment(item?.dueDate).format('DD.MM.YYYY') : 'neuvedeno'}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Datum úhrady:</strong></Col><Col style={{textAlign: 'right'}}>{item?.dateOfPayment !== null ? moment(item?.dateOfPayment).format('DD.MM.YYYY') : 'neuhrazeno'}</Col></Row>
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col><strong>Stav:</strong></Col><Col style={{textAlign: 'right'}}>{item?.isPaid === 1 ? <Badge variant='success'>Zaplaceno</Badge>:<Badge variant={item?.isPaid === 2 ? 'danger':'warning'}>Neuhrazeno</Badge>}</Col></Row>
                  
                  </Card.Body>
                </Card>
              <Card className='border-left-warning'>
                <Card.Header>Příjemce</Card.Header>
                <Card.Body>
                  <strong>{item?.recipient?.businessName}</strong>{item?.recipient?.businessName !== '' &&<br />}
                  <strong>{item?.recipient?.title}{item?.recipient?.title !== '' && ' '}{item?.recipient?.firstName} {item?.recipient?.surName}{item?.recipient?.titleAfter !== '' && ' '}{item?.recipient?.titleAfter}</strong>{item?.recipient?.surName !== '' &&<br />}
                  <br />
                  {item?.recipient?.street}&nbsp;{item?.recipient?.houseNumber}{!(item?.recipient?.identificationNumber === '' || item?.recipient?.identificationNumber === null || item?.recipient?.identificationNumber === undefined) && '/'}{item?.recipient?.identificationNumber}<br />
                  {item?.recipient?.postalCode}&nbsp;{item?.recipient?.city}<br />

                  {item?.recipient?.companyRegistrationNumber !== '' && <><br />IČO:&nbsp;{item?.recipient?.companyRegistrationNumber}</>}
                  {item?.recipient?.taxIdentificationNumber !== '' && <><br />DIČ:&nbsp;{item?.recipient?.taxIdentificationNumber}</>}
                </Card.Body>
                </Card>

            </CardDeck>
            <br />
            <CardDeck>
              <Card>
                <Card.Header>Další ev. údaje</Card.Header>
                <Card.Body>
                  

                <Row><Col><strong>Datum vystavení:</strong></Col><Col style={{textAlign: 'right'}}>{moment(item?.dateOfIssue).format('DD.MM.YYYY')}</Col></Row>
                <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                <Row><Col><strong>Datum zdanitelného plnění:</strong></Col><Col style={{textAlign: 'right'}}>{item?.dateOfTaxableSupply !== null ? moment(item?.dateOfTaxableSupply).format('DD.MM.YYYY') : 'neuvedeno'}</Col></Row>
                <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                <Row><Col><strong>Zaokrouhlení celkové částky:</strong></Col><Col style={{textAlign: 'right'}}>{item?.isRoundingTotal ? 'ano' : 'ne'}</Col></Row>
                <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                <Row><Col><strong>Přeneseno do účetnictví:</strong></Col><Col style={{textAlign: 'right'}}>{item?.isTransferredToAccounting ? 'ano' : 'ne'}</Col></Row>
              
                </Card.Body>
              </Card>
              <Card className='border-left-danger'>
                <Card.Header>Celkem + rekapitulace DPH</Card.Header>
                <Card.Body>
                  <Alert variant='secondary'>
                    <Row style={{fontSize: '20px'}}><Col><strong>Celkem:</strong></Col><Col style={{textAlign: 'right', fontWeight: 'bold', fontSize: '30px'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.total} /></Col></Row>
                    <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                    <Row><Col><strong>Celkem bez DPH:</strong></Col><Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.totalWithoutVat} /></Col></Row>
                    <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                    <Row><Col><strong>DPH:</strong></Col><Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item?.totalVat} /></Col></Row>
                    
                  </Alert>
                  

                  <Alert variant='light'>
                  <h5 style={{fontWeight: 'bold'}}>Rekapitulace DPH</h5>
                  <Row>
                    <Col><strong>Sazba DPH</strong></Col>
                    <Col style={{textAlign: 'right'}}><strong>Základ</strong></Col>
                    <Col style={{textAlign: 'right'}}><strong>DPH</strong></Col>
                    <Col style={{textAlign: 'right'}}><strong>Celkem</strong></Col>
                  </Row>

                  {item?.vatTables.map(vatTable => (
                    <Row key={vatTable.vatRate}>
                      <Col>{vatTable.vatRate}&nbsp;%</Col>
                      <Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={vatTable.withoutVat} /></Col>
                      <Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={vatTable.vat} /></Col>
                      <Col style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={vatTable.withVat} /></Col>
                    </Row>
                  ))}
                  <hr style={{marginTop: '3px', marginBottom: '3px'}} />
                  <Row><Col style={{ fontStyle: 'italic' }}>{getConstantTranslate(item?.vatRoutingType!, 'text')}</Col></Row>
                  </Alert>
                </Card.Body>
              </Card>
              

            </CardDeck>
            


<br />


    <Tabs
      id="controlled-tab-example"
      activeKey={activeNav}
      onSelect={(k) => setActiveNav(k!)}
       className='invoice-items'
    >
      <Tab eventKey="items" tabClassName={item?.items.length === 0 ? '':'tab-with-data'} title="Položky">
      </Tab>
      <Tab eventKey="textsAbove" tabClassName={item?.textsAbove.length === 0 ? '':'tab-with-data'} title="Texty nad">
      </Tab>
      <Tab eventKey="textsUnder" tabClassName={item?.textsUnder.length === 0 ? '':'tab-with-data'} title="Texty pod">
      </Tab>
      <Tab eventKey="emails" tabClassName={item?.emails.length === 0 ? '':'tab-with-data'} title="E-maily">
      </Tab>
      <Tab eventKey="documents" tabClassName={item?.documents.length === 0 ? '':'tab-with-data'} title="Dokumenty">
      </Tab>
    </Tabs>



            <Nav justify variant="tabs"  style={{fontSize: '20px', display: 'none'}} defaultActiveKey='items' >
              <Nav.Item>
                <Nav.Link eventKey="items" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('items') }}>Položky</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="textsAbove" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('textsAbove') }}>Texty nad</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="textsUnder" as="button" className='btn-block btn-sm btn-primary' onClick={async () => { setActiveNav('textsUnder') }}>Texty pod</Nav.Link>
              </Nav.Item>
            </Nav>

            <div style={{border: '1px solid #C0D1E1', padding: '5px', borderTopWidth: '0px'}}>
            
            {activeNav === 'textsAbove' && <>
              {item?.textsAbove.length === 0 && <>Doklad neobsahuje žádné texty nad.</>}
              {item?.textsAbove.map(textAbove => (
                <Card key={textAbove.id} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <Badge variant='secondary'>{textAbove.documentType === 'Z' ? 'Zálohová faktura': textAbove.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textAbove.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}

            {activeNav === 'textsUnder' && <>
              {item?.textsUnder.length === 0 && <>Doklad neobsahuje žádné texty pod.</>}
              {item?.textsUnder.map(textUnder => (
                <Card key={textUnder.id}  style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <Badge variant='secondary'>{textUnder.documentType === 'Z' ? 'Zálohová faktura': textUnder.documentType === 'P' ? 'Daňový doklad' : 'Konečná faktura'}</Badge>
                    <div dangerouslySetInnerHTML={{__html: textUnder.text!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}


            {activeNav === 'items' && <>
            <Table hover size='sm' style={{marginTop: '10px'}}>
              <thead>
                <tr className="jsw-table-head">
                  <th className="jsw-table-head" title=''>Název položky</th>
                  <th className="jsw-table-head" title='Celková částka dokladu včetně daně' style={{textAlign: 'right'}}>Jednotková cena</th>
                  <th className="jsw-table-head" title='Množství' style={{textAlign: 'right'}}>Množství</th>
                  <th className="jsw-table-head row-slim" title='Jednotka' ></th>
                  <th className="jsw-table-head" title='Celková částka položky bez daně' style={{textAlign: 'right'}}>Celkem</th>
                  <th className="jsw-table-head row-slim" title='Sazba DPH' style={{textAlign: 'center'}}>DPH</th>
                  <th className="jsw-table-head row-slim" title='Druh účtování'>Účtování</th>
                  <th className="jsw-table-head" title='Informace o původu položky'>Vlákno</th>
                </tr>
              </thead>
              <tbody>
                {item?.items.map(invoiceItem => ( 
                  <tr key={invoiceItem.id}>
                    <td>{invoiceItem.name}</td>
                    <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.unitPrice} /></td>
                    <td style={{textAlign: 'right'}}>{invoiceItem.count}</td>
                    <td>{invoiceItem.unit}</td>
                    <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={invoiceItem.amount} /></td>
                    <td style={{textAlign: 'center'}}>{invoiceItem.vatRate}&nbsp;%</td>
                    <td>{invoiceItem.accountingType}</td>
                    <td>{getConstantTranslate(invoiceItem.source!, 'value')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            </>}


            {activeNav === 'emails' && <>
              {item?.emails.length === 0 && <>Doklad neobsahuje žádné emaily.</>}
              {item?.emails.map(emails => (
                <Card key={emails.id} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    <div dangerouslySetInnerHTML={{__html: emails.email!}} ></div> 
                  </Card.Body>
                </Card>
              ))}
            </>}


            {activeNav === 'documents' && <>
              {item?.documents.length === 0 && <>Doklad neobsahuje žádné dokumenty.</>}
              {item?.documents.map(doc => (
                <Card key={doc.id} style={{marginBottom: '10px'}}>
                  <Card.Body>
                    {doc.typeTextToFile+'-'+String(item.invoiceNumber)+'.pdf'}&nbsp;{doc.isPublished ? <Badge variant="success">Publikováno</Badge>:<Badge variant="warning">Nepublikováno</Badge>}<br /><InvoicesOpenDocument id={doc.id} docName={''+doc.typeTextToFile+'-'+String(item.invoiceNumber)+'.pdf'} showAs='button' invoiceId={item.id} />
                    
                  </Card.Body>
                </Card>
              ))}
            </>}
              
            </div>

          </div>
          </>}

          </>





return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail dokladu' onClick={() => {startSequence()}}>Detail dokladu</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail dokladu' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
    </>
    }
  
    <Modal 
      
      scrollable
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      aria-labelledby="example-custom-modal-styling-title"
      //centered
      >
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail&nbsp;-&nbsp;{item?.invoiceTypeText} {item?.invoiceNumber}
          
        </div>
        <div className="showWeb">
          Detail&nbsp;-&nbsp;{item?.invoiceTypeText}  <Badge variant='secondary'>{item?.invoiceNumber}</Badge>
          
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      <div ref={modalRef}> 
        {contactForm}
      </div>
      


      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(InvoicesDetail))
