export const translateConstants = [
  {
    'original': 'MH',
    'target': 'Matematicky na haléře',
    'targetText': 'DPH je zaokrouhleno matematicky na haléře.'

  },
  {
    'original': 'upgrade',
    'target': 'Upgrade',
    'targetText': 'Upgrade.'

  },
  {
    'original': 'Bank transfer',
    'target': 'Bankovním převodem',
    'targetText': 'Bankovním převodem.'

  },
  {
    'original': 'By card',
    'target': 'Platební kartou',
    'targetText': 'Platební kartou.'

  },
  {
    'original': 'By card or cash',
    'target': 'Platební kartou nebo hotově',
    'targetText': 'Platební kartou nebo hotově.'

  },
  {
    'original': 'Cash',
    'target': 'Hotově',
    'targetText': 'Hotově.'

  },
  {
    'original': 'Cash on delivery',
    'target': 'Dobírka',
    'targetText': 'Dobírka.'

  },
  {
    'original': 'Online banking',
    'target': 'Online bankovnictví',
    'targetText': 'Online bankovnictví.'

  },
  {
    'original': 'Pay by invoice',
    'target': 'Platba na fakturu',
    'targetText': 'Platba na fakturu.'

  },
  {
    'original': 'T',
    'target': 'Daňový doklad',
    'targetText': 'Daňový doklad.'

  },
  {
    'original': 'P',
    'target': 'Proforma',
    'targetText': 'Zálohová faktura.'

  },
  {
    'original': 'day',
    'target': 'Den',
    'targetText': 'Každý den.'

  },
  {
    'original': 'week',
    'target': 'Týden',
    'targetText': 'Každý týden.'

  },
  {
    'original': 'month',
    'target': 'Měsíc',
    'targetText': 'Každý měsíc.'

  },
  {
    'original': 'quarter',
    'target': 'Čtvrtletí',
    'targetText': 'Každé čtvrtletí.'

  },
  {
    'original': 'halfyear',
    'target': 'Půlrok',
    'targetText': 'Každý půlrok.'

  },  
  {
    'original': 'year',
    'target': 'Rok',
    'targetText': 'Každý rok.'

  }, 
  {
    'original': 'lastDayOfMonth',
    'target': 'Poslední den v měsíci',
    'targetText': 'Poslední den v měsíci.'

  }, 



  
]