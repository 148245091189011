import React, {useState}  from 'react'
import Loader from "react-loader"

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import InvoicesList from './components/Invoices.List.part'

import { Container } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
import { useInvoicesStore, useStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent, checkVersion } from '../../Utils/Common'

import CustomerHeader from '../../parts/customerHeader/CustomerHeader.parts'

function InvoicesFCustomer(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  
  // Nastavení práv
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') || (localStorage.getItem('Billing')! === '1')) ? true : false

  // Načtení Store
  const rootInvoicesStore = useInvoicesStore()
  const rootStore = useStore()
  

  //const partName = "dialsDeliveryCzechPostServices"

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    localStorage.setItem('lastOpenedSite', 'invoices')
    await checkVersion()

    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()
          if (localStorage.getItem('openedCustomerId') !== null) {
            await rootStore.fetchCustomerOpened()
          }
          //await rootStore.fetchCustomerList('','',1)
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')

          
        } else {
          
        }
      }
      if (rights === false) {
        props.history.push('/customers-dashboard')
      }  
      
      //if (Object.keys(rootStore.customerListAll).length < 100) rootStore.fetchCustomerListAll('', '', 1)
      if (rootStore.customerListAll.length < 100) rootStore.fetchCustomerListAll('', '', 1)

      rootInvoicesStore.fetchDialsInvoicesPaymentTerms()  
      rootInvoicesStore.fetchDialsInvoicesVatRoundingTypes()  
      rootInvoicesStore.fetchDialsInvoicesUnits()  
      await rootInvoicesStore.fetchInvoicesList( Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), 'T', true)

    }  
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })
  
  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <br />
                              
              </Container>

              <InvoicesList invoiceType='T' fromCustomer={true} />
              <br /><br />
            </Loader>  
            </main>
          </div>
        </div>      
      
    </div> 
  );
}

export default observer(InvoicesFCustomer)
