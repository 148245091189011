import React, { useEffect, useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../Utils/Common'
// eslint-disable-next-line
import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton, Table, Card } from 'react-bootstrap'
// eslint-disable-next-line
import { useStore, useAppStore } from '../../store'
import { observer } from 'mobx-react'

//import FilterPanel from '../../sharedComponents/filterPanel/FilterPanel.part'
//import Pagination from 'react-js-pagination'

import moment from 'moment'
//import NumberFormat from 'react-number-format'

import AppProDuelCompanyAddUpdate from './App.ProDuel.CompanyAddUpdate.part'
import AppProDuelAPIkeyAddUpdate from './App.ProDuel.APIkeyAddUpdate.part'

//import SubmissionPacketEdit from './Submissions.Packet.Edit.part'
//import SubmissionPacketReturn from './Submissions.Packet.Return.part'
//import SubmissionsPacketCopy from './Submissions.Packet.Copy.part'

//import SupportContactsCustomerInfo from '../../support/components/Support.Contacts.CustomerInfo.part'
//import CustomersOtherActionsCashRepackingPacket from '../../../parts/customersOtherActions/Customers.OtherActions.RepackingPacket.part'

import ErrorAlert from '../../parts/errorAlert/ErrorAlert.part'

//import * as Constants from '../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../Utils/Confirmation";

interface AppProDuelDetailProps {
  portalId:string,
  newCustomer?:boolean
}

const AppProDuelDetail: React.FC<AppProDuelDetailProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  // eslint-disable-next-line
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1') ) ? true : false

  // Nastavení stavů pro zobrazení  
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  //const [loadingDocumentTypes, setLoadingDocumentTypes] = useState(false)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [loadingTokens, setLoadingTokens] = useState(false)


  const [showDetail, setShowDetail] = useState(false)
  const [preparingApp, setPreparingApp] = useState(false)


  const [showedTokenId, setShowedTokenId] = useState('')
  const [showedTokenTime, setShowedTime] = useState(10)
  
  // Připojení store
  const rootStore = useStore()
  const rootAppStore = useAppStore()

  // Nastavení editivaného kontaktu
  //const submission = rootStore.getSubmissionById(props.submissionId)

  const customer = rootAppStore.getAppProduelCustomerByPortalId(props.portalId)
  const portalCustomer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  

  useEffect(() => {
    return () => {
      deleteTimeouts()
    }
  }, [])

  const deleteTimeouts = async () => {
    for (var i = 0; i < 99999; i++)
	    clearTimeout(i);
  }


  const handleClose = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Otevírání a zavírání zobrazení formuláře
  const handleCloseMain = async () => {
    setShowDetail(false)
    sessionStorage.removeItem('autoCloseModal')
    

    
  }

// Startovací sequence při otevření formuláře
const startSequence = async () => {
    //localStorage.removeItem('submissionPacketsSearchArray')
    //sessionStorage.removeItem('submissionPacketsSearchPage')
    setLoading(true) 
    
    await rootAppStore.deleteAppProduelCompanies()

    if (props.newCustomer === true) { 
      await handleOnClickStartWithApp()
    }
    else {
      setShowDetail(true)
      setLoadingCompanies(true)
      setLoadingTokens(true)

      await rootAppStore.fetchAppProduelCustomerByPortalId(props.portalId)

      if (!(customer === undefined || customer === null)) {
        await rootAppStore.fetchAppProduelCompanies(1,customer?.id!)
        setLoadingCompanies(false)
        //await setLoadingTokens(true)
        console.log(loadingTokens)
        await rootAppStore.fetchAppProduelTokens(customer?.id!)
        setLoadingTokens(false)
      }
      
      //await setLoadingTokens(false)
      await setLoading(false)
    }

}

  // Dotaz před smazáním pozvánky
  const handleOnClickStartWithApp = async () => {
    
      if (await confirm("Opravdu chcete začít používat mobilní aplikaci proDUEL?",'Ano, začít', 'Ne', {title: 'Úvodní nastavení'})) {
        await startWithApp()
      } else {
      
      }  
    
  }

// Funkce pro založení nové pozvánky
const startWithApp = async () => {
    
  setError(null)
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    } 

    setPreparingApp(true)
    setShowDetail(true)


    // Zavolání API pro přidání customer
    await axios.post(getUrl('URL_DOKLADY_CUSTOMERS'), { 
      portalId: portalCustomer?.id!,
      accountNumber: portalCustomer?.accountNumber!,
      businessName: portalCustomer?.businessName!,
      name: portalCustomer?.name!,
      companyRegistrationNumber: portalCustomer?.companyRegistrationNumber!,
      taxIdentificationNumber: portalCustomer?.taxIdentificationNumber!,
      isTaxAble: portalCustomer?.isTaxAble!,
      firstName: portalCustomer?.firstName!,
      surName: portalCustomer?.surName!,
      title: portalCustomer?.title!,
      titleAfter: portalCustomer?.titleAfter!
     }, { headers: { 'Authorization': 'Bearer ' + aToken}}
      ).then(response => {
        
        //Po založení zákazníka, založíme novou firmu
        (async () => {
          
          await rootAppStore.fetchAppProduelCustomerByPortalId(props.portalId)
          // Zavolání API pro přidání firmy
          await axios.post(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+response.data.id+'/companies', { 
            customerId: response.data.id,
            name: 'Název zpracovávané firmy v DUELu'
          }, { headers: { 'Authorization': 'Bearer ' + aToken}}
            ).then(responseCompany => {
              // Po založení firmy, založíme nový API klíč
              (async () => {
                await rootAppStore.fetchAppProduelCompanies(1,response.data.id)
                // Zavolání API pro přidání API klíče
                await axios.post(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+response.data.id+'/companies/'+responseCompany.data.id+'/tokens', { 
                  companyId: responseCompany.data.id,
                  name: 'API klíč pro mobilní aplikaci proDUEL'
                }, { headers: { 'Authorization': 'Bearer ' + aToken}}
                  ).then(responseToken => {
                    
                    (async () => {
                    await rootAppStore.fetchAppProduelTokens(response.data.id)
                    //setPreparingApp(false)
                    //setLoadingCompanies(false)
                    //setLoadingTokens(false)  
                    })()
                    
                  }).catch(error => {
                    const errorMessage:any = (<ErrorAlert errorData={error} />)
                    setError(errorMessage)
                    setShowE(true)    
                    setPreparingApp(false)      
                  });
                  // Zavolání API pro přidání API klíče
                  await axios.post(getUrl('URL_DOKLADY_CUSTOMERS')+'/'+response.data.id+'/companies/'+responseCompany.data.id+'/tokens', { 
                    companyId: responseCompany.data.id,
                    name: 'Přístupový klíč pro synchronizaci v ekonomickém systému DUEL'
                  }, { headers: { 'Authorization': 'Bearer ' + aToken}}
                    ).then(responseToken => {
                      
                      (async () => {
                      await rootAppStore.fetchAppProduelTokens(response.data.id)
                      setPreparingApp(false)
                      setLoadingCompanies(false)
                      setLoadingTokens(false)  
                      })()
                      
                    }).catch(error => {
                      const errorMessage:any = (<ErrorAlert errorData={error} />)
                      setError(errorMessage)
                      setShowE(true)    
                      setPreparingApp(false)      
                    });
              }
              )()

            }
            ).catch(error => {
              const errorMessage:any = (<ErrorAlert errorData={error} />)
              setError(errorMessage)
              setShowE(true)  
              setPreparingApp(false)        
            });


        })()
      }).catch(error => {
        setLoading(false)
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
        setPreparingApp(false)
      });

}


const handleCopy = (text:string) => {
  //console.log('A')  
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  

}

const handleShowToken = async (tokenId:string) => {
  deleteTimeouts()
  setShowedTokenId(tokenId)
  setShowedTime(10)
  setTimeout(() => {
    setShowedTime(9)
    //console.log('9')
  }, 1000)
  setTimeout(() => {
    setShowedTime(8)
    //console.log('8')
  }, 2000)
  setTimeout(() => {
    setShowedTime(7)
  }, 3000)
  setTimeout(() => {
    setShowedTime(6)
  }, 4000)
  setTimeout(() => {
    setShowedTime(5)
  }, 5000)
  setTimeout(() => {
    setShowedTime(4)
  }, 6000)
  setTimeout(() => {
    setShowedTime(3)
  }, 7000)
  setTimeout(() => {
    setShowedTime(2)
  }, 8000)
  setTimeout(() => {
    setShowedTime(1)
  }, 9000)
  setTimeout(() => {
    setShowedTokenId('')
    setShowedTime(10)
  }, 10000)
}


//Počet firem
const companiesCount:number = rootAppStore.appProduelCompanies.length


// VYKRESLENÍ FORMULÁŘE S APLIKACÍ 
const appProDuelDetail = 
      <>

          {showDetail &&<>
          <div>
            <h5>Seznam zpracovávaných firem</h5>
            <AppProDuelCompanyAddUpdate customerId={customer?.id!} showAs='button' />
            {rights === true && <><br /><br /></>}
            
            {loadingCompanies === true 
              ? 
              <div><Spinner animation='border'  />&nbsp;<br /></div> 
              :
              companiesCount === 0 && <div>Seznam firem neobsahuje žádné záznamy.<br /><br /></div> }

            {rootAppStore.appProduelCompanies.slice().map(company => (
              <div key={company.id}>
                <Card
                  bg='light'
                  text='dark'
                  className='border-left-danger'
                  style={{marginBottom: '15px'}}
                >
                  <Card.Header>
                    <div style={{float: 'right'}}>
                      <AppProDuelCompanyAddUpdate customerId={customer?.id!} companyId={company.id} editing={true} showAs='button' />
                    </div>  
                    
                    <strong>{company.name}</strong>

                  </Card.Header>
                  <Card.Body>
                    <h5>Seznam API klíčů</h5>
                    <AppProDuelAPIkeyAddUpdate customerId={customer?.id!} companyId={company.id} showAs='button' />
                    {rights === true && <><br /><br /></>}
                    
                    {loadingTokens === true 
                      ? 
                      <div><Spinner animation='border'  />&nbsp;<br /></div> 
                      :
                      company.tokens === null ? <div>Seznam API klíčů neobsahuje žádné záznamy.<br /><br /></div> 
                        : 
                        <>
                        <div className="showMobile">
                          {company.tokens?.map(token => (
                              <div key={token.id} style={{paddingBottom: '10px'}}>
                                <Card>
                                  <Card.Body>
                                  <div style={{minWidth: '150px', float: 'left'}}>
                                    <strong>Název</strong><br />
                                    <>{token.name}</><br /><br />
                                    <strong>Token</strong><br />
                                    
                                    {showedTokenId === token.id ? token.token : '•••••••••••••••••••••'}
                                    <br />{showedTokenId === token.id ?<>{showedTokenTime}<Button size='sm' style={{marginLeft: '10px', marginRight: '10px'}} variant='outline-secondary' onClick={() => {deleteTimeouts(); setShowedTokenId('')}} ><i className="fas fa-eye-slash"></i></Button></>:<Button size='sm' style={{marginLeft: '10px', marginRight: '10px'}} variant='outline-secondary' onClick={() => handleShowToken(token.id)} ><i className="fas fa-eye"></i></Button>}<Button size='sm' variant='outline-secondary' onClick={() => handleCopy(token.token)} ><i className="far fa-copy"></i> KOPÍROVAT</Button><br />
                                    <br />
                                    <strong>Vytvořeno</strong><br />
                                    {moment(token.createDate).format('DD.MM.YYYY HH:mm')}<br /><br />
                                    {token.isRevoked === true ? <Badge variant='danger'>Zneplatněn</Badge>:<Badge variant='success'>Platný</Badge> }
                                  </div>
                                  <div style={{float: 'right'}}>
                                    <AppProDuelAPIkeyAddUpdate customerId={customer?.id!} companyId={company.id} tokenId={token.id} editing={true}  />
                                  </div>
                                  </Card.Body>
                                </Card>  
                              
                              </div>
                            ))}
                          



                              
                        </div>
                        <div className="showWeb">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Název klíče</th>
                                <th style={{width: '440px'}}>Token</th>
                                <th style={{width: '150px'}}>Vytvořeno</th>
                                <th style={{width: '150px'}}>Stav</th>
                                <th style={{width: '150px'}}>Akce</th>
                              </tr>
                            </thead>
                            <tbody>
                            {company.tokens?.map(token => (
                              <tr key={token.id}>
                                <td style={{verticalAlign: 'middle'}}>{token.name}</td>
                                <td style={{verticalAlign: 'middle'}}>{showedTokenId === token.id ? token.token : '•••••••••••••••••••••'}<div style={{float: 'right'}}>{showedTokenId === token.id ?<>{showedTokenTime}<Button size='sm' style={{marginLeft: '10px', marginRight: '10px'}} variant='outline-secondary' onClick={() => {deleteTimeouts(); setShowedTokenId('')}} ><i className="fas fa-eye-slash"></i></Button></>:<Button size='sm' style={{marginLeft: '10px', marginRight: '10px'}} variant='outline-secondary' onClick={() => handleShowToken(token.id)} ><i className="fas fa-eye"></i></Button>}<Button size='sm' variant='outline-secondary' onClick={() => handleCopy(token.token)} ><i className="far fa-copy"></i> KOPÍROVAT</Button></div></td>
                                <td style={{verticalAlign: 'middle'}}>{moment(token.createDate).format('DD.MM.YYYY HH:mm')}</td>
                                <td style={{verticalAlign: 'middle'}}>{token.isRevoked === true ? <Badge variant='danger'>Zneplatněn</Badge>:<Badge variant='success'>Platný</Badge> }</td>
                                <td style={{verticalAlign: 'middle'}}><AppProDuelAPIkeyAddUpdate customerId={customer?.id!} companyId={company.id} tokenId={token.id} editing={true}  /></td>
                              </tr>
                            ))}
                            </tbody>
                          </Table>
                        </div>

                        </>

                    }

                  </Card.Body>
                </Card>


              </div>   
            
            )
            )
            }
            

            <br />  
            
          
          </div>
          </>}

          </>



return ( 
  <>
  
    <Button variant={props.newCustomer === true ? 'primary':'outline-primary'} size="sm" title='Nastavení mobilní aplikace proDUEL' onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Nastavení mobilní aplikace proDUEL' ><i className="fas fa-search"></i>&nbsp;</span>{props.newCustomer === true ? 'Začít používat':'Nastavení'}</Button>
        
      
    
  
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úvodní nastavení</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          proDUEL <Badge variant="secondary">Mobilní aplikace</Badge> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {preparingApp === true ? <div><Spinner animation='border'  />&nbsp;<br />Probíhá úvodní nastavení...</div> : <></>}
      {appProDuelDetail}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(AppProDuelDetail))
