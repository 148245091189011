// eslint-disable-next-line
import moment from 'moment'
import {  getOperatorForFiltering, getStatusNumberForFiltering, getStatusDateForFiltering } from '../../../Utils/Common';


export const getFilterStringToBE = (searchArray:any, isCustomer?:boolean) => { 

  let filter:string = ''
  let filterGroup:string = ''

  let supportSearchDataArray = [{filterName:'', filterValue:'',displayValue:''}]

  if (searchArray !== null) {
    supportSearchDataArray = JSON.parse(searchArray!)
  }

  if (isCustomer === true) {
    supportSearchDataArray.push({filterName: 'CustomerId', filterValue: localStorage.getItem('openedCustomerId')!, displayValue: 'Otevřený zákazník' })
  }

  supportSearchDataArray.filter(ssda => !(ssda.filterName === '' || ssda.filterValue === '')).slice().sort(function(a:any, b:any) {
    var nameB = a.filterName; 
    var nameA = b.filterName; 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
    // eslint-disable-next-line
  }).map((ssda,index) => {
    if (filterGroup !== ssda.filterName) {
      if (filterGroup === '') {
        filter = filter + '('
      }
      else {
        filter = filter + ')~and~('
      }
    }
    
    if (filterGroup === ssda.filterName) {
      filter = filter + '~or~'
    }

    if (ssda.filterName !== '') {
      if (
               getStatusDateForFiltering(ssda.filterName) === true 
            || getStatusNumberForFiltering(ssda.filterName) === true 
            || ssda.filterName === 'MyNotFinish' 
            || ssda.filterName === 'MyNotFinishAfterTerm' 
            || ssda.filterName === 'AllNotFinishAfterTerm'
            || ssda.filterName === 'CustomerFulltext'
            || ssda.filterName === 'startFilter'
            || ssda.filterName === 'BusinessName2' 
            || ssda.filterName === 'TemplateName'
            || ssda.filterName === 'MyTasks' 
            || ssda.filterName === 'MyNotFinishTasks' 
            || ssda.filterName === 'MyNotFinishTasksPrivate' 
            || ssda.filterName === 'MyNotFinishTasksAfterTerm' 
            || ssda.filterName === 'AllNotFinishTasksAfterTerm'
            || ssda.filterName === 'AuthorNotFinishTasks'
            || ssda.filterName === 'AuthorNotFinishTasksAfterTerm'
            || ssda.filterName === 'IncompletedMeetingsTasks'
            || ssda.filterName === 'AuthorNotClosedTasks'
            || ssda.filterName === 'UnreadMeetings'
            ) {
        filter = filter + ssda.filterValue
      }
      else {  
        filter = filter + '('+ ssda.filterName+'~'+getOperatorForFiltering(ssda.filterName)+'~'+ssda.filterValue + ')'
      }
    }

    filterGroup = ssda.filterName
  })
  
  if (filter !== '') filter = filter + ')'


  return filter
}

