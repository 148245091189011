import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
// eslint-disable-next-line
import { getToken, getUrl, setUserRoles } from '../../../Utils/Common'
// eslint-disable-next-line
import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'
import ErrorAlertWithoutLogoutPart from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'

import {Container, Button, ButtonGroup, Dropdown, DropdownButton,  Form, Col, Alert, Modal, Table, Card, Spinner, Badge} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
// eslint-disable-next-line
//import * as Constants from '../../../../../Constants/Constants'
// eslint-disable-next-line
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation";

//import EmailingBlackListUpdate from './Emailing.BlackList.Update.part'

import { useInvoicesStore, useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import Pagination from 'react-js-pagination'

import FilterPanel from '../../../sharedComponents/filterPanel/FilterPanel.part'

import moment from 'moment'

import { login } from '../../../authProvider'
import NumberFormat from 'react-number-format'

import InvoicesDetail from './Invoices.Detail.part'
import InvoicesOpenDocument from './Invoices.OpenDocument.part'


interface InvoicesListProps {
  invoiceType: string,
  fromCustomer?: boolean
}

const InvoicesList: React.FC<InvoicesListProps & RouteComponentProps> = (props:any) => {
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errorPdf, setErrorPdf] = useState(null)
  const [errorPublish, setErrorPublish] = useState(null)
  const [errorClose, setErrorClose] = useState(null)
  // eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)

  // eslint-disable-next-line
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [stateNewFilter, setStateNewFilter] = useState(true)

  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)


  const [opening, setOpening] = useState(false)
  //eslint-disable-next-line
  const [openingId, setOpeningId] = useState('')
  


  // Nastavení práv pro editaci
  const rightsI:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  || (localStorage.getItem('Billing')! === '1')) ? true : false
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  
  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setShowE(false)
    setError(null)
    setErrorPdf(null)
    setErrorPublish(null)
    setErrorClose(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  //eslint-disable-next-line
  const handleShow = async() => {
    sessionStorage.removeItem('autoCloseModal')
    //if (Object.keys(rootStore.customerListAll).length < 100) rootStore.fetchCustomerListAll('', '', 1)
    if (rootStore.customerListAll.length < 100) rootStore.fetchCustomerListAll('', '', 1)  
    if (sessionStorage.getItem('invoicesListSearchValue') !== '') {
      
    }

    setError(null)
    setErrorPdf(null)
    setErrorPublish(null)
    setErrorClose(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním dokladu
  const handleOnClickDeleteItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Opravdu chcete doklad "+text+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání dokladu'})) {
        deleteItem(id)
      } else {
      
      }  
    })()
  }

  // Dotaz před publikováním/odpublikováním dokladu
  const handleOnClickPublishItem = (id:string, text:string, isNowPublished:boolean) => {
    (async () => {
      if (await confirm("Opravdu chcete doklad "+text+" "+(isNowPublished ? 'odpublikovat' : 'publikovat')+'?',(isNowPublished ? 'Ano, zrušit publikování' : 'Ano, publikovat'), 'Ne', {title: 'Publikování dokladu'})) {
        publishItem(id, isNowPublished, true)
      } else {
      
      }  
    })()
  }

    // Dotaz před publikováním/odpublikováním dokladu
    const handleOnClickPdfItem = (id:string, text:string, isNowGenerated:boolean) => {
      (async () => {
        if (await confirm("Opravdu chcete k dokladu "+text+" "+(isNowGenerated ? ' odstranit' : ' vytvořit')+' PDF dokumenty?',(isNowGenerated ? 'Ano, odstranit PDF dokumenty' : 'Ano, vytvořit PDF dokumenty'), 'Ne', {title: 'PDF dokumenty k dokladu'})) {
          pdfItem(id, isNowGenerated, true)
        } else {
        
        }  
      })()
    }

  // Dotaz před uzavřením/otevřením dokladu
  const handleOnClickCloseItem = (id:string, text:string, isNowClosed:boolean, fullProcess:boolean) => {
    (async () => {

      let descriptionText = ''
      if (fullProcess) descriptionText = ' Během této akce budou všechny dokumenty k dokladu '+text+' '+ (isNowClosed ? 'odpublikovány a smazány' : 'vytvořeny a publikovány')+'.'

      if (await confirm("Opravdu chcete doklad "+text+" "+(isNowClosed ? 'otevřít' : 'uzavřít')+'?'+descriptionText,(isNowClosed ? 'Ano, otevřít' : 'Ano, uzavřít'), 'Ne', {title: 'Uzavření dokladu'})) {
        closeItem(id, isNowClosed, fullProcess)
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání dokladu
  const deleteItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      
      rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('Mazání...')
      // Zavolání API
      
      await axios.delete(getUrl('URL_INVOICES')+'/proforma/'+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)  
          rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')        
        });
    
      })()    
  }

  // Funkce pro publikování dokladu
  const publishItem = async (id:string, isNowPublished:boolean, withReload:boolean) => {
    
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      

      let urlInvoiceType = ''

      if (props.invoiceType === 'T') urlInvoiceType = '/tax'
      if (props.invoiceType === 'P') urlInvoiceType = '/proforma'

      let urlLastPart = isNowPublished ? '/publish' : '/publish'
      // Zavolání API
      
      rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate(isNowPublished ? 'Odpublikování...' : 'Publikování...')
      //const item = rootInvoicesStore.invoices.find((item) => item.id === id)

      if (isNowPublished) {
        await axios.delete(getUrl('URL_INVOICES')+urlInvoiceType+'/'+id+urlLastPart, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam pozvánek
            if (withReload) rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)

          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setErrorPublish(errorMessage)
            setShowE(true)    
            rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')      
          });
      }
      else {
        await axios.post(getUrl('URL_INVOICES')+urlInvoiceType+'/'+id+urlLastPart, { invoice: id}, { headers: { 'Authorization': 'Bearer ' + aToken}}
          ).then(response => {
            // Po smazání přenačíst seznam pozvánek
            if (withReload) rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)

          }).catch(error => {
            const errorMessage:any = (<ErrorAlert errorData={error} />)
            setErrorPublish(errorMessage)
            setShowE(true)    
            rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')      
          });
      }
    
  }

    // Funkce pro publikování dokladu
    const pdfItem = async (id:string, isNowGenerated:boolean, withReload:boolean) => {
      
        // Kontrola přihlášení  
        const aToken = await getToken()
        if (aToken === null) {   
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }      
  
        let urlInvoiceType = ''
  
        if (props.invoiceType === 'T') urlInvoiceType = '/tax'
        if (props.invoiceType === 'P') urlInvoiceType = '/proforma'
  
        let urlLastPart = isNowGenerated ? '/pdf' : '/pdf'
        // Zavolání API
        
        rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate(isNowGenerated ? 'Mazání pdf...' : 'Vytváření pdf...')
        //const item = rootInvoicesStore.invoices.find((item) => item.id === id)
  
        if (isNowGenerated) {
          await axios.delete(getUrl('URL_INVOICES')+urlInvoiceType+'/'+id+urlLastPart, { headers: { 'Authorization': 'Bearer ' + aToken}}
            ).then(response => {
              // Po smazání přenačíst seznam pozvánek
              if (withReload) rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)
  
            }).catch(error => {
              const errorMessage:any = (<ErrorAlertWithoutLogoutPart errorData={error} />)
              setErrorPdf(errorMessage)
              setShowE(true)    
              rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')      
            });
        }
        else {
          await axios.post(getUrl('URL_INVOICES')+urlInvoiceType+'/'+id+urlLastPart, { invoice: id}, { headers: { 'Authorization': 'Bearer ' + aToken}}
            ).then(response => {
              // Po smazání přenačíst seznam pozvánek
              if (withReload) rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)
  
            }).catch(error => {
              const errorMessage:any = (<ErrorAlertWithoutLogoutPart errorData={error} />)
              setErrorPdf(errorMessage)
              setShowE(true)    
              rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')      
            });
        }

    }

  // Funkce pro otevření/zavření dokladu
  const closeItem = async (id:string, isNowClosed:boolean, fullProcess:boolean) => {
    
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      

      let urlInvoiceType = ''

      if (props.invoiceType === 'T') urlInvoiceType = '/tax'
      if (props.invoiceType === 'P') urlInvoiceType = '/proforma'

      let urlLastPart = isNowClosed ? '/open' : '/close'
      // Zavolání API

      if (fullProcess) {
        if (isNowClosed) {
          await publishItem(id, true, false)
          await pdfItem(id, true, false)

        }
        else {
          //await pdfItem(id, false)
          //await publishItem(id, false)
        }


      }
      
      rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate(isNowClosed ? 'Otevírání...' : 'Uzavírání...')
      //const item = rootInvoicesStore.invoices.find((item) => item.id === id)

      await axios.post(getUrl('URL_INVOICES')+urlInvoiceType+'/'+id+urlLastPart, { invoice: id}, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            if (fullProcess) {
              if (isNowClosed) {
                
              }
              else {
                await pdfItem(id, false, false)
                await publishItem(id, false, false)
              }
            }
          // Po smazání přenačíst seznam pozvánek
          rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)

          })();

        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setErrorClose(errorMessage)
          setShowE(true)    
          rootInvoicesStore.invoices.find((item) => item.id === id)?.setHelpSate('')  
          rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)    
        });

  }

  const [state, setState] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })
  
  // Načtení Store
  const rootStore = useStore()

  const customerListAllF = rootStore.customerListAll
  const [customers, setCustomers] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  

   // Filtrování zákazníků v seznamu
   const filterCustomers = (value:string) => {
    //setCustomers(rootStore.getCustomerListAllByFilter(value))
    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessName?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1,true)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomers(rootStore.getCustomerListAllByFilterWithoutICO(value))
    }
  }

  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 

  // O dchytávání kláves po stisknutí na údaji ZÁKAZNÍK
const handleKeyDownCustomer =  (e:any, setFieldValue:any, nextInput:number) => {
  const { activeItem, filteredItems } = state;

  if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
    e.preventDefault();         // Vyrušit standardní funkci tlačítka

      // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
      if (!state.displayItems) { 
        //var form = e.currentTarget.form;
        //form.elements[nextInput-1].focus()
        return
      }  

      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: filteredItems[activeItem]
      });
      
      if (customers[activeItem]?.accountNumber !== undefined) {
        setFieldValue('licenseNumber',customers[activeItem].accountNumber)
        setFieldValue("customerId",customers[activeItem].id)
        setFieldValue("customerName",customers[activeItem].businessName);
        //handleOld(customers[activeItem].accountNumber!)

      // Posun na pole s indexem dle parametru funkce
      if (e.target.nodeName === "INPUT") {
        var form = e.currentTarget.form;
        form.elements[nextInput].focus()
      }
    }

  }
  else if (e.keyCode === 27) { // ESC (Vyprázdnit)
      e.preventDefault();
      
      setState({
          activeItem: 0,
          filteredItems: [],
          displayItems: false,
          inputValue: e.currentTarget.value
      });
      setFieldValue('licenseNumber','')
      setFieldValue("customerId",'')
      setFieldValue("customerName",'');
      
  }

  else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
      e.preventDefault();
      if (activeItem === 0) {
          return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem - 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  }
  else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
      e.preventDefault();
      if ((customers && activeItem === customers.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
        return;
      }

      //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))

      setState({
          activeItem: activeItem + 1,
          filteredItems,
          displayItems: true,
          inputValue: e.currentTarget.value
      });

      scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  }
  

  };

  

  // Načtení Store
  const rootInvoicesStore = useInvoicesStore()
  
  
  const items = rootInvoicesStore.invoices.slice()
  
  //Počet záznamů
  const countData:number = Object.keys(items).length

  // Seznam pozvánek do řádků tabulky
  const linesItems = items.map((item) => (
    <tr key={item.id}>
      <td>{moment(item.dateOfIssue).format("DD.MM.YYYY")}</td>
      <td><Badge variant="secondary">{item.invoiceType === 'T' ? 'Faktura':'Záloha'}</Badge></td>
      <td style={{textAlign: 'right'}}><strong>{item.invoiceNumber}</strong></td>
      {props.fromCustomer !== true &&<td>{item.accountNumber}</td>}
      {props.fromCustomer !== true &&
      <td>
          {!opening &&<span className="as-link-no-color" onClick={() => { handleOpen(item.customerId!,true) } }>{item.businessName}</span>}
          {opening &&<span className="as-link-no-color">{item.businessName}</span>}
          </td>}
      
      <td style={{textAlign: 'right'}}><strong><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.total} /></strong></td>
      <td>{item.dueDate && moment(item.dueDate).format("DD.MM.YYYY")}</td>
      <td>
        {item.helpState !== '' 
          ? 
            <Badge variant="warning">{item.helpState}</Badge>
          : 
            <>
            {item.isPaid === 1 ? <Badge variant='success' style={{marginRight: '5px'}}  title={'Zaplaceno '+moment(item.dateOfPayment).format("DD.MM.YYYY")}>Zaplaceno</Badge> : <Badge style={{marginRight: '5px'}} variant={item.isPaid === 0 ? 'warning' : 'danger'}>Nezaplaceno</Badge>}
            
            {rightsI && <>

            {item.isPublished  
              ? 
              <Badge variant='success' style={{marginRight: '5px'}}>Publikováno</Badge> 
              : 
              <>
              {item.isClosed  ? <Badge variant='info' style={{marginRight: '5px'}}>Uzavřeno</Badge> : <></>}  
              </>}

              </>}
            </>
        }
        
        
        </td>
        {props.fromCustomer === true &&<td>
          {item.publishedDocumentsCount > 0 && <Card><Card.Header>
            {item?.documents.filter(d => d.isPublished === true).map((doc,index) => (
              <div key={doc.id}>
                <div style={{float: 'left'}}>{doc.typeTextToFile+'-'+String(item.invoiceNumber)+'.pdf'}</div>
                <div style={{float: 'right'}}><InvoicesOpenDocument id={doc.id} docName={''+doc.typeTextToFile+'-'+String(item.invoiceNumber)+'.pdf'} showAs='button' invoiceId={item.id} /></div>
                <div style={{clear: 'both'}}></div>
                {item.publishedDocumentsCount > (index+1) &&<hr style={{margin: '5px'}} />}
              </div>
            ))}
            </Card.Header></Card>}

          
          </td>}
        {rightsI &&<td>{item.isTransferredToAccounting ? 'Ano':'Ne'}</td>}
      
      {rightsI &&<td>
        <ButtonGroup>
          <InvoicesDetail invoiceId={item.id} fromSubMenu={false} invoiceType={item.invoiceType} />
      
          {rightsI && item.editingLevel > 0 &&<>
        
          <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
            {item.editingLevel > 0 && <>
              {rightsI && <Dropdown.Item style={{}} onClick={() => { handleOnClickCloseItem(item.id,String(item.invoiceNumber!), item.isClosed, true) } }>{item.isClosed ? 'Otevřít':'Uzavřít a publikovat'}</Dropdown.Item>} 

              {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPublishItem(item.id,String(item.invoiceNumber!), true) } }>Zrušit publikování</Dropdown.Item>} 
              {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPdfItem(item.id,String(item.invoiceNumber!), true) } }>Odstranit dokumenty</Dropdown.Item>} 
              {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickCloseItem(item.id,String(item.invoiceNumber!), item.isClosed, false) } }>{item.isClosed ? 'Otevřít':'Uzavřít'}</Dropdown.Item>} 
              
              {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPdfItem(item.id,String(item.invoiceNumber!), false) } }>Vytvořit dokumenty</Dropdown.Item>} 
              {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPublishItem(item.id,String(item.invoiceNumber!), false) } }>Publikovat</Dropdown.Item>} 
              
              {rightsI && !item.isClosed &&<Dropdown.Divider />}
              {rightsI && !item.isClosed &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,String(item.invoiceNumber!)) } }>Smazat</Dropdown.Item>} 
            </>}
            
          </DropdownButton>
          </>}
        </ButtonGroup>
      </td>}
    </tr>
  ))
  // Seznam pozvánek do karet
  const linesItemsCard = items.map((item) => (
    <div key={item.id}>
    <Card>
      <Card.Body>
        
        <div style={{minWidth: '150px', float: 'left'}}>
          <strong>{moment(item.dateOfIssue).format("DD.MM.YYYY")}</strong><br />
          <Badge variant="secondary">{item.invoiceType === 'T' ? 'Faktura':'Záloha'} č. {item.invoiceNumber}</Badge><br />
          {props.fromCustomer !== true && <><strong>{rootStore.customerListAll.find(c => c.id === item.customerId)?.businessName}</strong><br /></>}
          <span style={{fontSize: '150%'}}><Badge variant='secondary'>Celkem <strong><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={item.total} /> Kč</strong></Badge>  </span><br />
          {item.dueDate && <>Splatnost do {moment(item.dueDate).format("DD.MM.YYYY")}<br /></>}
          {item.helpState !== '' 
          ? 
            <Badge variant="warning">{item.helpState}</Badge>
          : 
            <>
            {item.isPaid === 1 ? <Badge variant='success' style={{marginRight: '5px'}}  title={'Zaplaceno '+moment(item.dateOfPayment).format("DD.MM.YYYY")}>Zaplaceno</Badge> : <Badge style={{marginRight: '5px'}} variant={item.isPaid === 0 ? 'warning' : 'danger'}>Nezaplaceno</Badge>}
            
            {rightsI && <>

            {item.isPublished  
              ? 
              <Badge variant='success' style={{marginRight: '5px'}}>Publikováno</Badge> 
              : 
              <>
              {item.isClosed  ? <Badge variant='info' style={{marginRight: '5px'}}>Uzavřeno</Badge> : <></>}  
              </>}

              </>}
            </>
        }
          <br />
          {rightsI &&<><span style={{fontStyle: 'italic'}}>{item.isTransferredToAccounting ? 'Přeneseno do účetnictví':'Nepřeneseno do účetníctví'}</span><br /></>}
        </div>
        <div style={{float: 'right'}}>
        {rightsI && <>
          <ButtonGroup>
            
            <InvoicesDetail invoiceId={item.id} fromSubMenu={false} invoiceType={item.invoiceType} />
            {rightsI && item.editingLevel > 0 &&<>
        
            <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="outline-secondary" title="••• " size="sm">
              {item.editingLevel > 0 && <>
                {rightsI && <Dropdown.Item style={{}} onClick={() => { handleOnClickCloseItem(item.id,String(item.invoiceNumber!), item.isClosed, true) } }>{item.isClosed ? 'Otevřít':'Uzavřít a publikovat'}</Dropdown.Item>} 

                {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPublishItem(item.id,String(item.invoiceNumber!), true) } }>Zrušit publikování</Dropdown.Item>} 
                {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPdfItem(item.id,String(item.invoiceNumber!), true) } }>Odstranit dokumenty</Dropdown.Item>} 
                {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickCloseItem(item.id,String(item.invoiceNumber!), item.isClosed, false) } }>{item.isClosed ? 'Otevřít':'Uzavřít'}</Dropdown.Item>} 
                
                {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPdfItem(item.id,String(item.invoiceNumber!), false) } }>Vytvořit dokumenty</Dropdown.Item>} 
                {rightsI && <Dropdown.Item style={{display: 'none'}} onClick={() => { handleOnClickPublishItem(item.id,String(item.invoiceNumber!), false) } }>Publikovat</Dropdown.Item>} 
                
                {rightsI && !item.isClosed &&<Dropdown.Divider />}
                {rightsI && !item.isClosed &&<Dropdown.Item style={{color: 'red'}} onClick={() => { handleOnClickDeleteItem(item.id,String(item.invoiceNumber!)) } }>Smazat</Dropdown.Item>} 
              </>}
              
            </DropdownButton>
            </>}
          </ButtonGroup>
        </>}
        </div>
      </Card.Body>
    </Card>
    <br />
    </div>
  ))   



    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        sessionStorage.setItem('invoicesListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        await rootInvoicesStore.fetchInvoicesList(pageNumber, props.invoiceType, props.fromCustomer === true ? true:false)
        setLoading(false);  
      })()
    }

    // Funkce pro změnu strany
    const handleOrderChange = (order:string) => {
      (async () => { 
        setLoading(true);
        // Kontrola přihlášení  
        const aToken = await getToken()
        if ((aToken === null) || (aToken === undefined)) { 
          sessionStorage.setItem('automaticLogout', '1')
          props.history.push('/logout')
        }
        localStorage.setItem('invoicesListOrderBy', order)
        // Předat hledanou hodnotu action pro načtení zákazníků
        sessionStorage.setItem('invoicesListSearchPage', String(1))
        await rootInvoicesStore.fetchInvoicesList(1, props.invoiceType, props.fromCustomer === true ? true:false)
        setLoading(false);  
      })()
    }
  
  // Funkce pro založení nové pozvánky
  const handleNewItem = (emailData:string, reasonData:string, customerIdData:string, isVerifiedData:boolean) => {
    
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 

      let dataToSend:any = {
        email: emailData, reason: reasonData, customerId: customerIdData, isVerified: isVerifiedData
      }

      if (customerIdData === '') {
        dataToSend = {
          email: emailData, reason: reasonData, isVerified: isVerifiedData
        } 
      }

      // Zavolání API
      await axios.post(getUrl('URL_EMAILING_BLACKLIST'), dataToSend, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          sessionStorage.setItem('autoCloseModal','1');

          
            setShowInfo(true)


          rootInvoicesStore.fetchInvoicesList(Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')), props.invoiceType, props.fromCustomer === true ? true:false)
          
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            setShow(false)
            setError(null)
            sessionStorage.removeItem('autoCloseModal')

          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });


    })()

  }

    // Funkce po otevření firmy
    const handleOpen = async (id:string,isActive:boolean) => {
      setError(null)
      setOpening(true)
      setOpeningId(id)
      
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      if (isActive === true) {
        // Zavolání API
        await axios.get(getUrl('URL_CUSTOMER_OPEN')+id,{
          headers: {
            'Authorization': 'Bearer ' + aToken
          }}
        ).then(response => {
          (async () => {
          
          // Nastavení nových práv a předání tokenu doplňku pro jejich řízení
          setUserRoles(response.data.roles)
          login(response.data.token)
  
          // Nastavení id otevřené firmy do proměnné v localStore
          localStorage.setItem('openedCustomerId', id)
  
          // Nastavení informace, že firma byla otevřená z přehledu
          localStorage.setItem('openedFromOverviews', '1')
  
          // Načtení otevřené firmy do store
          await rootStore.fetchCustomerOpened()
          await rootStore.fetchCustomerOfferCount()
  
          setOpening(false)
  
          // Přesměrování na stránku s přehledem firmy
          await props.history.push('/customers-dashboard')
          })();
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setOpening(false)
        });
      }
      else {
        // Nastavení id otevřené firmy do proměnné v localStore
        localStorage.setItem('openedCustomerId', id)
  
        // Nastavení informace, že firma byla otevřená z přehledu
        localStorage.setItem('openedFromOverviews', '1')
  
        // Načtení otevřené firmy do store
        await rootStore.fetchCustomerOpened()
        //await rootStore.fetchCustomerOfferCount()
  
        setOpening(false)
  
        // Přesměrování na stránku s přehledem firmy
        await props.history.push('/customers-dashboard')
      }
    }

  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    email: Yup.string()
      .email('E-mailová adresa je ve špatném formátu!')
      .required('E-mail musí být vyplněn!')
    
  }) 
  

  //const datum = new Date().toISOString()
  return (
    <Container fluid>
      {rightsI &&
      <FilterPanel 
        isCustomer={props.fromCustomer === true ? true:false} 
        filterPage={'invoicesList'} 
        filterSearchArray='invoicesListSearchArray' 
        pageVariable='invoicesListSearchPage' 
        setPageLoading={setLoading} 
        filterItems={props.fromCustomer ? ['DateOfIssue','InvoiceNumber','Total','IsTransferredToAccounting','IsClosed','IsPayment','Note','VariableSymbol'] : ['CustomerId','DateOfIssue','InvoiceNumber','Total','BusinessName2','IsTransferredToAccounting','IsClosed','IsPayment','Note','VariableSymbol']}  
        title={props.invoiceType === 'T' ? "Fakturace - seznam daňových dokladů": "Fakturace - seznam zálohových faktur"}
        titleCount = {Number(localStorage.getItem('lastInvoicesListTotalCount')!)}
        stateNewFilter={stateNewFilter}
        setStateNewFilter={setStateNewFilter}
        otherId={props.invoiceType}
        /*
        secondaryLeftButtons={rightsI &&
          <span title="Přidat e-mail">
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant="success" onClick={handleShow}>
            <i className="fas fa-plus"></i> Přidat e-mail
          </Button>
          </span>}
          */
        //secondaryRightButtons={<EmailingSendEmails showAs='button' />}    
        />
      }
      {!rightsI &&<>
        <h2>{props.invoiceType === 'T' ? "Fakturace - seznam daňových dokladů": "Fakturace - seznam zálohových faktur"}</h2>
      </>}

        <br />
      
      {(loading === true) 
      ? 
      <><Spinner animation='border'  />&nbsp;<br /></>
      :
      <> 

      <div className={countData !== 0 ? 'nodisplay' : ''}>Seznam dokladů neobsahuje žádné záznamy.<br /><br /></div>
      <div className={countData === 0 ? 'nodisplay' : ''}>
        <div className="showMobile">
          {linesItemsCard}<br />
        </div>
        <div className="showWeb">
        <Table hover>
            <thead>
              <tr className="jsw-table-head">
                <th title='Datum vystavení dokladu' className="jsw-table-head" style={{cursor: 'pointer'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListOrderBy') === 'DateOfIssue' || localStorage.getItem('invoicesListOrderBy') === null || localStorage.getItem('invoicesListOrderBy') === undefined) ? 'DateOfIssueDesc' : 'DateOfIssue'  )}>
                  Datum vystavení
                  <div style={{float: 'right'}}>
                    {(localStorage.getItem('invoicesListOrderBy') === 'DateOfIssue' || localStorage.getItem('invoicesListOrderBy') === null || localStorage.getItem('invoicesListOrderBy') === undefined) &&<i className="fas fa-chevron-circle-up"></i>}
                    {localStorage.getItem('invoicesListOrderBy') === 'DateOfIssueDesc' && <i className="fas fa-chevron-circle-down"></i>}
                  </div>
                </th>
                <th className="jsw-table-head row-slim" title='Typ dokladu'>Typ</th>
                <th className="jsw-table-head" title='Číslo vystaveného dokladu'  style={{cursor: 'pointer', textAlign: 'right'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumber') ? 'InvoiceNumberDesc' : 'InvoiceNumber'  )}>Číslo faktury
                <div style={{float: 'right'}}>
                    {(localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumber') &&<>&nbsp;<i className="fas fa-chevron-circle-up"></i></>}
                    {localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumberDesc' && <>&nbsp;<i className="fas fa-chevron-circle-down"></i></>}
                  </div>
                </th>
                {props.fromCustomer !== true &&<th className="jsw-table-head row-slim" title='Číslo zákazníka'>Zákazník</th>}
                {props.fromCustomer !== true &&<th className="jsw-table-head" title='Název zákazníka'>Název</th>}

                <th className="jsw-table-head" title='Celková částka dokladu včetně daně a zaokrouhlení dle platebních podmínek'  style={{cursor: 'pointer', textAlign: 'right'}} onClick={() =>  handleOrderChange((localStorage.getItem('invoicesListOrderBy') === 'Total') ? 'TotalDesc' : 'Total'  )}>Celkem
                <div style={{float: 'right'}}>
                    {(localStorage.getItem('invoicesListOrderBy') === 'Total') && <>&nbsp;<i className="fas fa-chevron-circle-up"></i></>}
                    {localStorage.getItem('invoicesListOrderBy') === 'TotalDesc' && <>&nbsp;<i className="fas fa-chevron-circle-down"></i></>}
                  </div>
                </th>
                <th className="jsw-table-head" title='Datum splatnosti vystaveného dokladu'>Splatnost</th>
                <th className="jsw-table-head" title='Stav, ve kterém se doklad momentálně nachází'>Stav</th>
                {props.fromCustomer === true &&<th className="jsw-table-head" title='Dokumenty k dokladu'>Dokumenty</th>}
                {rightsI &&<th className="jsw-table-head row-slim" title='Příznak, zda byl doklad přenesen do účetnictví'>Přeneseno</th>}


                {rightsI &&<th className="jsw-table-head">Akce</th>}
              </tr>
            </thead>
            <tbody>
              {linesItems}
            </tbody>
          </Table>
        </div> 
        <br />  
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={Number(sessionStorage.getItem('invoicesListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('invoicesListSearchPage')) }
          itemsCountPerPage={10}
          totalItemsCount={Number(localStorage.getItem('lastInvoicesListTotalCount'))}
          pageRangeDisplayed={5}
          onChange={handlePageChange.bind(1)}
          />
      </div> 
      </>} 


      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Operace s dokladem faktury</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
          {errorPdf !== null && <><strong>Vytvoření/odstranění PDF dokumentů</strong><br />{errorPdf}</>}
          {errorPublish !== null && <><strong>Publikování dokladu</strong><br />{errorPublish}</>}
          {errorClose !== null && <><strong>Otevření/Uzavření dokladu</strong><br />{errorClose}</>}
        </Modal.Body>
      </Modal>
      
      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Interní Blacklist - přidání e-mailu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>E-mail byl úspěšně přidán na interní Blacklist!​</strong>
          </Alert>  
          <div className={showForm} >
          <Formik
              validationSchema={schemaNewItem}
              initialValues={{ 
                email: '', 
                reason: '',
                licenseNumber: '',
                customerId: '',
                customerName: '',
                isVerified: false
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.email!, values.reason!, values.customerId!, values.isVerified!)              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="licenseNumber">
                    <Form.Row>
                    <Form.Label column lg={3}>Zákazník</Form.Label>
                    <Col>

                    <FormControl 
                      type="text"
                      name="licenseNumber"
                      
                      value={values.licenseNumber!}
                      onChange={(e) => {handleChange(e);  state.displayItems = true;  state.activeItem = 0; localStorage.setItem('customerQuickSearchValue', e.target.value);    
                        //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                          filterCustomers(e.target.value) 
                        }}
                      //tabIndex={1}
                      autoComplete="off" 
                      autoFocus={true}
                      isValid={touched.licenseNumber && !errors.licenseNumber} 
                      isInvalid={!!errors.licenseNumber}
                      onKeyDown={(e:any) => handleKeyDownCustomer(e, setFieldValue, 2)}
                      onBlur={(e:any) => {setTimeout(() => {setState({activeItem: 0, filteredItems: state.filteredItems, displayItems: false, inputValue: state.inputValue}); },200 ) }}
                    />
                    {values.licenseNumber! !== '' && state.displayItems ?
                        <div id="scrollcustomer" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                            <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                            {
                                customers.map((dp,index) => {
                                    return (
                                        <li id={'cust'+String(index)} className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                            onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); await setFieldValue("customerId",dp.id); await setFieldValue("customerName",dp.businessName); state.displayItems = false; }}
                                            key = {dp.id}
                                            >
                                            {dp.accountNumber} - {dp.businessName}
                                        </li>
                                    )
                                }).slice(0, 50)
                            }
                            </ul>
                        </div>
                        : null
                    }
                      <strong>{values.customerName}</strong>
                      <Form.Control.Feedback type="invalid">
                      {errors.licenseNumber}
                    </Form.Control.Feedback>
                      
                      </Col>

                    </Form.Row>
                    </FormGroup>
                  <FormGroup controlId="reason">
                    <Form.Row>
                      <FormLabel column lg={3}>Důvod</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="reason"
                          value={values.reason}
                          onChange={handleChange}
                          isValid={touched.reason && !errors.reason} 
                          isInvalid={!!errors.reason}
                          autoFocus={true}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="isVerified">
                    <Form.Row>
                      <FormLabel column lg={3}>Ověřeno správcem Blacklistu</FormLabel>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="isVerified"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          checked={values.isVerified}
                          
                          isValid={touched.isVerified && !errors.isVerified} 
                          isInvalid={!!errors.isVerified}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.isVerified}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                                     
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Přidávám e-mail...' : 'Přidat e-mail'}</Button>
                  
                </Form>
              )}
            </Formik> 
          </div>
        </Modal.Body>

      </Modal>
      
    </Container>
  )  
  
}

export default withRouter(observer(InvoicesList))
