export const errorNames = [
  {
    'code': 1000,
    'description': 'Obecná chyba.'
  },{
    'code': 1001,
    'description': 'Nebyl zaslán token.'
  },{
    'code': 1002,
    'description': 'Nebyl zaslán RefreshToken.'
  },{
    'code': 1003,
    'description': 'Odkaz pro obnovu hesla již není platný. V případě potřeby zažádejte znovu o obnovu hesla.'
  },{
    'code': 1004,
    'description': 'Neplatný uživatel.'
  },{
    'code': 1005,
    'description': 'ID je vyžadováno.'
  },{
    'code': 1006,
    'description': 'Zaslán neplatný parametr pro stránkování.'
  },{
    'code': 1007,
    'description': 'Zaslán neplatný parametr pro filtrování.'
  },
  {
    'code': 1008,
    'description': 'Zadaná hodnota je příliš dlouhá.'
  },
  {
    'code': 1009,
    'description': 'Vlastnost je vyžadována.'
  },{
    'code': 1050,
    'description': 'Není vyplněn e-mail.'
  },{
    'code': 1051,
    'description': 'Není vyplněno heslo.'
  }, {
    'code': 1052,
    'description': 'Potvrzení hesla není správně.'
  }, {
    'code': 1053,
    'description': 'Heslo může změnit pouze přihlášený uživatel.'
  },{
    'code': 1054,
    'description': 'Není vyplněno křestní jméno.'
  },{
    'code': 1055,
    'description': 'Není vyplněno příjmení.'
  },{
    'code': 1056,
    'description': 'Není vyplněno původní heslo.'
  },{
    'code': 1057,
    'description': 'Není vyplněn klíč.'
  },{
    'code': 1058,
    'description': 'Není vyplněna hodnota.'
  },{
    'code': 1100,
    'description': 'Firma neexistuje.'
  },{
    'code': 1101,
    'description': 'Nepovolený typ kontaktu.'
  },{
    'code': 1102,
    'description': 'E-mail byl zadán ve špatném formátu.'
  },{
    'code': 1103,
    'description': 'Telefonní číslo obsahuje nepovolené znaky.'
  },{
    'code': 1104,
    'description': 'Nepovolený typ adresy.'
  },{
    'code': 1105,
    'description': 'Není vyplněn název firmy.'
  },{
    'code': 1106,
    'description': 'Není zadán typ kontaktu.'
  },{
    'code': 1107,
    'description': 'Není vyplněn kontakt.'
  },{
    'code': 1108,
    'description': 'Není zadán typ kontaktu.'
  },{
    'code': 1109,
    'description': 'Není vyplněna ulice.'
  },{
    'code': 1110,
    'description': 'Není vyplněno číslo popisné.'
  },{
    'code': 1111,
    'description': 'Není vyplněno PSČ.'
  },{
    'code': 1112,
    'description': 'Není vyplněno město.'
  },{
    'code': 1113,
    'description': 'Není vyplněno licenční číslo.'
  },{
    'code': 1114,
    'description': 'Není vyplněno IČ.'
  },{
    'code': 1115,
    'description': 'Není vyplněna fakturační adresa.'
  },{
    'code': 1116,
    'description': 'Není vyplněna poznámka u zákazníka.'
  },{
    'code': 1117,
    'description': 'Není dovolena duplicita v kategoriích zákazníka.'
  },

  {
    'code': 1153,
    'description': 'Není vyplněn status licence.'
  },{
    'code': 1156,
    'description': 'Číslo verze musí být větší než nula.'
  },{
    'code': 1157,
    'description': 'Počet licencí musí být větší než nula.'
  },{
    'code': 1158,
    'description': 'Nejsou vyplněny moduly licence.'
  },

  {
    'code': 1200,
    'description': 'Id zákazníka ve zdrojové licenci a v cílové licenci musí být rozdílné.'
  },{
    'code': 1201,
    'description': 'Není vyplněn zdrojový zákazník.'
  },
  {
    'code': 1202,
    'description': 'Není vyplněn cílový zákazník.'
  },{
    'code': 1203,
    'description': 'Není vyplněna zdrojová licence.'
  },

  {
    'code': 1250,
    'description': 'Není vyplněno jméno šablony.'
  },{
    'code': 1251,
    'description': 'Není vyplněn předmět šablony.'
  },{
    'code': 1252,
    'description': 'Není vyplněn HTML text nebo prostý text šablony.'
  },

  {
    'code': 1300,
    'description': 'Není vyplněn ceník modulů.'
  },{
    'code': 1301,
    'description': 'Není vyplněn upgradový scénář.'
  },{
    'code': 1302,
    'description': 'Není vyplněna aktivní licence.'
  },

  {
    'code': 1350,
    'description': 'Není vyplněno jméno.'
  },{
    'code': 1351,
    'description': 'Není vyplněn kód.'
  },

  {
    'code': 1400,
    'description': 'Způsob využití obsahuje nepovolenou hodnotu.'
  },{
    'code': 1401,
    'description': 'Způsob využití Default není povoleno por Update.'
  },{
    'code': 1402,
    'description': 'Číslo nabídky je mimo rozsah.'
  },

  {
    'code': 1450,
    'description': 'Není vyplněno jméno typu podpory.'
  },{
    'code': 1451,
    'description': 'Není vyplněno jméno tématu podpory.'
  },{
    'code': 1452,
    'description': 'Není vyplněno nové jméno zákazníka.'
  },{
    'code': 1453,
    'description': 'Není vyplněno staré jméno zákazníka.'
  },

  {
    'code': 1500,
    'description': 'Není vybrán soubor.'
  },{
    'code': 1501,
    'description': 'Nejsou vybrány soubory.'
  },

  {
    'code': 200000,
    'description': 'Obecná chyba.'
  },{
    'code': 200001,
    'description': 'Neznámá chyba.'
  },{
    'code': 200002,
    'description': 'Odkaz již není platný.'
  },{
    'code': 200003,
    'description': 'Hledání podle zadaného údaje není podporováno.'
  },{
    'code': 200204,
    'description': 'Nelze smazat posledního správce otevřené firmy.'
  },{
    'code': 200005,
    'description': 'Přímé smazání tohoto dokumentu není povoleno. Související dokument/y odstraníte, tak, že se v procesu fakturace upgrade vrátíte o krok zpět.'
  },{
    'code': 200100,
    'description': 'Účet není aktivován. Aktivaci provedete kliknutím na odkaz v e-mailu s potvrzením registrace.'
  },{
    'code': 200101,
    'description': 'Špatné uživatelské jméno nebo heslo.'
  },{
    'code': 200102,
    'description': 'Potvrzovací klíč neexistuje.'
  },{
    'code': 200103,
    'description': 'Registrace není možná pro již existujícího uživatele.'
  },{
    'code': 200104,
    'description': 'Platnost pozvánky vypršela.'
  },{
    'code': 200105,
    'description': 'Obnovovací klíč uživatele neexistuje. Přihlašte se znovu.'
  },{
    'code': 200106,
    'description': 'Platnost obnovovacího klíče vypršela. Přihlašte se znovu.'
  },{
    'code': 200107,
    'description': 'Použitou pozvánku nelze smazat.'
  },{
    'code': 200108,
    'description': 'Staré heslo není správné.'
  },{
    'code': 200109,
    'description': 'Odkaz pro obnovu hesla již není platný. V případě potřeby zažádejte znovu o obnovu hesla.'
  },{
    'code': 200110,
    'description': 'Opětovné odeslání potvrzovacího e-mailu není povoleno.'
  },{
    'code': 200111,
    'description': 'Tento účet již není platný. V případě potřeby kontaktujte naše obchodní oddělení!'
  },{
    'code': 200200,
    'description': 'Uživatel nemá přístupová práva ke správě firmy.'
  },{
    'code': 200201,
    'description': 'Uživatel nemůže odebrat sám sebe.'
  },{
    'code': 200202,
    'description': 'Kontakt není unikátní.'
  },{
    'code': 200203,
    'description': 'Poslední e-mail pro zasílání účetních dokladů nelze odebrat.'
  },{
    'code': 200204,
    'description': 'Posledního uživatele  na úrovni správce nelze odebrat.'
  },
  {
    'code': 200205,
    'description': 'Vytvoření pozvánky pro neaktivního zákazníka není dovoleno.'
  },
  {
    'code': 200206,
    'description': 'Otevření neaktivního zákazníka není dovoleno.'
  },

  {
    'code': 200300,
    'description': 'Datový filtr pro dané ID neexistuje.'
  },

  {
    'code': 200400,
    'description': 'Nepodporovaná hodnota Mode.'
  },
  {
    'code': 200401,
    'description': 'Nepodporovaná hodnota ReminderType.'
  },

  {
    'code': 200500,
    'description': 'Převod licence nemůže být proveden.'
  },
  {
    'code': 200501,
    'description': 'Změna stavu licence je povolena pouze pro aktivní licence.'
  },
  {
    'code': 200502,
    'description': 'Cílový zákazník má jinou aktivní licenci.'
  },
  {
    'code': 200503,
    'description': 'Zákazník nemůže mít více aktivních licencí.'
  },
  {
    'code': 200504,
    'description': 'Aktivace licence je dovolena pouze pro poslední verze programů.'
  },
  {
    'code': 200508,
    'description': 'Zákazník požadovanou licenci již obsahuje.'
  },
  {
    'code': 200509,
    'description': 'Licenci nelze smazat, již k ní existuje objednávka.'
  },
  {
    'code': 200510,
    'description': 'Licenci nelze smazat, je součástí převodu dat.'
  },

  {
    'code': 200600,
    'description': 'Změna příznaku aktivní není u systémové šablony povolena.'
  },
  {
    'code': 200601,
    'description': 'Smazání systémové šablony není povoleno.'
  },
  {
    'code': 200602,
    'description': 'Smazání použité šablony není povoleno.'
  },

  {
    'code': 200_702,
    'description': 'Modul Analýzy není možné zakoupit bez modulu Účetnictví (alespoň 1 PC).'
  },
  {
    'code': 200703,
    'description': 'U licence typu A nebo B je možné zadat pouze 1 PC.'
  },
  {
    'code': 200704,
    'description': 'U licence typu C nebo D je možné zadat minimálně 2 PC.'
  },
  {
    'code': 200706,
    'description': 'Modul Analýzy není možné zakoupit bez modulu Účetnictví (alespoň 1 PC).'
  },
  {
    'code': 200707,
    'description': 'U licence typu B je možné zadat pouze 1 PC.'
  },
  {
    'code': 200708,
    'description': 'U licence typu D je možné zadat minimálně 2 PC.'
  },
  {
    'code': 200709,
    'description': 'Modul Maloobchod není možné zakoupit bez modulu Sklad (alespoň 1 PC).'
  },
  {
    'code': 200710,
    'description': 'Modul E-shopy není možné zakoupit bez modulu Sklad (alespoň 1 PC).'
  },

  {
    'code': 200800,
    'description': 'Objednávka pro daného zákazníka a scénář již existuje.'
  },
  {
    'code': 200803,
    'description': 'Požadovanou akci nelze dokončit, celková cena objednávky nesmí být záporná!'
  },
  {
    'code': 200816,
    'description': 'Individuální položku s procentní slevou je možné zadat pouze jednou!'
  },
  {
    'code': 200901,
    'description': 'Požadovaná čísla dokladů nejsou dostupná.'
  },
  {
    'code': 200801,
    'description': 'Zákazník nemá licenci pro daný scénář.'
  },
  {
    'code': 200802,
    'description': 'Zákazník má licenci pro daný scénář.'
  },
  {
    'code': 200803,
    'description': 'Cena položky je mimo povolený rozsah.'
  },
  {
    'code': 200804,
    'description': 'Povolený stav je pouze Nabídnuto.'
  },
  {
    'code': 200805,
    'description': 'Změna stavu Vytvořeno není povolena.'
  },
  {
    'code': 200808,
    'description': 'Zákazník nemá aktuální licenci programu.'
  },
  {
    'code': 200809,
    'description': 'Obnovení objednávky do původního stavu není pro zákazníka dovoleno.'
  },
  {
    'code': 200810,
    'description': 'Obnovení objednávky do původního stavu není povoleno pro původní objednávku.'
  },
  {
    'code': 200811,
    'description': 'Obnovení objednávky do původní podoby není pro daný stav povoleno.'
  },
  {
    'code': 200818,
    'description': 'Pro zákazníka nelze vytvořit objednávku, protože jeho licence programu není aktivní.'
  },
  {
    'code': 200917,
    'description': 'Doklad má vytvořené dokumenty. Operace není dovolena.'
  },
  {
    'code': 200924,
    'description': 'Šablonu nelze smazat, již k ní existují faktury.'
  },
  {
    'code': 201200,
    'description': 'Dokument, který se pokoušéte otevřít  není v tuto chvíli dostupný. Zkuste to prosím později nebo kontektujte naše obchodní oddělení.'
  },
  {
    'code': 201300,
    'description': 'Snažíte se přidat duplicitní verzi programu.'
  },
  {
    'code': 201403,
    'description': 'Požadovaná čísla zásilek nejsou k dispozici.'
  },
  {
    'code': 201405,
    'description': 'Zásilky v této distribuci nelze editovat, již byl exportován TXT soubor. Pokud je přesto chcete editovat, stornujte export pomocí stejnojmenné operace.'
  },
  {
    'code': 201406,
    'description': 'Zásilky již byly předány dopravci, do této distribuce již nelze zasahovat!'
  },
  {
    'code': 201450,
    'description': 'U zákazníka není evidována zadaná e-mailová adresa!'
  },
  {
    'code': 201451,
    'description': 'Odkaz pro odhlášení odběru informačních e-mailů je neplatný! Odkaz jste do prohlížeče vkládali pravděpodobně ručně. Zkuste odkaz zkopírovat ještě jednou a zkontrolujte, prosím, že je celý!'
  },
  {
    'code': 200004,
    'description': 'Duplicita není povolena!'
  },
  {
    'code': 200009,
    'description': 'Tomuto uživateli není možné tuto roli přiřadit!'
  },
  {
    'code': 200010,
    'description': 'Tomuto uživateli není možné tuto roli odebrat!'
  }
  
]